// @flow

export const staticErrorBoundaryDefaults = {
	children: 'An Error occured, please contact support or try again later.',
	title: 'Something went wrong.',
	subTitle: '',
}

export const staticErrorPageDefaults = {
	message: 'Error',
}
