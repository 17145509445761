// @flow
import enums from 'src/configs/enums'
import AccountingCategory from 'src/apollo/_entities/accountingCategory'

const AccountingMappable = (accountingMappableData) => {
	if (!accountingMappableData) return null

	const accountingCategory = !!accountingMappableData?.accounting_category_id
		? AccountingCategory(accountingMappableData?.accounting_category)
		: AccountingCategory({ id: 0, nominal_code: null, name: 'N/A' })

	const mappableType = () => {
		const stringData = accountingMappableData?.mappable_type?.split('\\')
		const type = stringData?.pop()

		switch (type) {
			case 'AccountingCode':
				return accountingMappableData?.accounting_category?.type === 1
					? enums.ACCOUNTING_MAPPABLE_MAPPABLE_TYPES.CHART_OF_ACCOUNT
					: enums.ACCOUNTING_MAPPABLE_MAPPABLE_TYPES.LINE_OF_BUSINESS
			case 'Location':
				return enums.ACCOUNTING_MAPPABLE_MAPPABLE_TYPES.LOCATION
			case 'Vendor':
				return enums.ACCOUNTING_MAPPABLE_MAPPABLE_TYPES.VENDOR
			default:
				return null
		}
	}

	return {
		...accountingMappableData,
		accounting_category_id:
			accountingMappableData?.accounting_category_id ?? 0,
		accounting_category: accountingCategory,
		mappableType: mappableType(),
	}
}

export default AccountingMappable
