// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import DateFormatter from 'src/library/DateFormatter'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrderDatesPills = ({ filters, setFilters }: Props) => {
	const handleFilterRemove = () => {
		setFilters({ ...filters, orderDates: [] })
	}

	let datePills = () => {
		if (filters?.orderDates?.length === 2) {
			const start = DateFormatter.humanReadableDate(
				parseInt(filters?.orderDates[0])
			)
			const end = DateFormatter.humanReadableDate(
				parseInt(filters?.orderDates[1])
			)

			const label = '' + start === '' + end ? start : start + '-' + end

			return (
				<FilterPill
					filter={label}
					filterType={'DATES'}
					onRemove={() => handleFilterRemove()}
				/>
			)
		} else {
			return null
		}
	}

	return datePills()
}

export default RenderOrderDatesPills
