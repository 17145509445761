import { gql } from 'graphql-request'
import { OrganizationDataFragment } from 'src/api/organizations/_fragments/_organization_data_fragment'
import Organization from 'src/apollo/_entities/organization'

export const GetManyOrganizationsQuery = {
	identifier: 'get_many_organizations',
	gql: gql`
        query getManyOrganizations($ids: [ID]!) {
            many_organizations(ids: $ids) {
                ${OrganizationDataFragment}
            }
        }
    `,
	formatResults: (data) => {
		return (
			data?.many_organizations?.map((organization) =>
				Organization(organization)
			) ?? []
		)
	},
}
