// @flow
import React, { createContext, useContext } from 'react'
import { useQueryModifiers } from 'src/components/Search/useQueryModifiers'

const GlobalSearchContext = createContext()

export function useGlobalSearch() {
	const queryModifiers = useContext(GlobalSearchContext)

	return {
		...queryModifiers,
	}
}

const ReRenderProtection = React.memo(({ children }) => <>{children}</>)

export const GlobalSearchProvider = ({ children }) => {
	const queryModifiers = useQueryModifiers('use-global-search', null, 12)

	return (
		<ReRenderProtection>
			<GlobalSearchContext.Provider value={queryModifiers}>
				{children}
			</GlobalSearchContext.Provider>
		</ReRenderProtection>
	)
}
