import styled, { css } from 'styled-components'
import { ComponentType } from 'react'

export const LeftDropdownLabelText = styled.label`
	font-size: 14px;
	font-weight: 600;
`

export const DangerText = styled.p`
	color: var(--danger);
`

export const BlackText = styled.p`
	color: #000000;
`
type HeaderStyleProps = {
	color?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
	size?:
		| 'extraSmall'
		| 'xs'
		| 'sm'
		| 'small'
		| 'md'
		| 'medium'
		| 'lg'
		| 'large',
	withEllipses?: boolean,
	lineCount?: number, // if withEllipses is true, this determines how many lines to show
}
export const H2: ComponentType<HeaderStyleProps> = styled.h2`
	color: ${(props) =>
		!props?.color ? '#000000' : css`var(--${props?.color})`};
	font-size: 36px;
	font-weight: 600;
`

export const H2Small = styled(H2)`
	font-size: 22px;
`

export const H3: ComponentType<HeaderStyleProps> = styled.div`
	color: ${(props) =>
		!props?.color ? '#000000' : css`var(--${props?.color})`};

	${(props) => {
		switch (props?.size) {
			case 'extraSmall':
			case 'xs':
				return css`
					font-size: 12px;
					font-weight: 400;
				`
			case 'sm':
			case 'small':
				return css`
					font-size: 18px;
					font-weight: 400;
				`
			case 'md':
			case 'medium':
				return css`
					font-size: 20px;
					font-weight: 400;
				`
			case 'xl':
			case 'extraLarge':
				return css`
					font-size: 28px;
					font-weight: 600;
				`
			case 'lg':
			case 'large':
			default:
				return css`
					font-size: 24px;
					font-weight: 500;
				`
		}
	}}

	${(props) => {
		if (props?.withEllipses) {
			return css`
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: ${props?.lineCount || 1};
				-webkit-box-orient: vertical;
			`
		}
	}}
`

export const SpanEllipses = styled.span`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

export const StrongText = styled.strong`
	font-weight: 600;
`

export const CardHeaderTitle = styled.h2`
	font-size: 28px;
	font-weight: 600;
	color: #000000;
`

export const EmptySectionTitle = styled(H3)`
	line-height: 30px;
	text-align: center;
	margin-bottom: -8px;
`

export const EmptySectionMessage = styled.p`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	text-align: center;
	white-space: normal;
`

type InputDescriptionProps = {
	hasErrors?: boolean,
}

export const InputDescription: ComponentType<InputDescriptionProps> = styled.p`
	font-size: 12px;
	padding-top: 4px;
	margin-top: ${(props) => (!!props?.hasErrors ? -8 : -4)}px;
`

export const FormErrorMessage = styled.div.attrs({
	classname: 'form-control-feedback',
})`
	font-size: 14px;
	color: var(--danger);
`

export const HighlightedText = styled.span`
	background-color: #d8eeff;
`

export const StyledLabel = styled.label`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
`
