// @flow
import { usePersistentSearchPaginationFilterManager } from 'src/components/Search/usePersistentSearchPaginationFilterManager'
import { useId } from 'react-id-generator'

export const useQueryModifiers = (
	storageKey = null,
	filterTypes = [],
	resultsPerPage = 10,
	page = 1,
	searchTarget = '',
	sorts = [],
	shouldPersist = false
) => {
	const [generatedStorageKey] = useId(1, 'search-query-key-')
	const {
		modifiers,
		renderSearchAndFilters,
		renderSearch,
		renderFilters,
		renderPagination,
		setFilters,
		setAddOns,
		toggleAddOn,
		addAddOn,
		removeAddOn,
		toggleFiltersByType,
		addFiltersByType,
		removeFiltersByType,
		setPage,
		setSorts,
		setSearchTarget,
		setQueryModifiers,
		setSelects,
		// eslint-disable-next-line react-hooks/rules-of-hooks
	} = usePersistentSearchPaginationFilterManager(
		storageKey ?? generatedStorageKey,
		searchTarget,
		page,
		filterTypes,
		resultsPerPage,
		sorts,
		shouldPersist
	)

	const filterCount = (type: string = null) => {
		const filters = {
			...modifiers.filters,
			intersect: undefined,
			favorites: undefined,
			recent_purchases: undefined,
			most_purchased: undefined,
		}

		if (type) {
			return filters[type]?.flat().filter((value) => !!value)?.length ?? 0
		} else {
			return (
				Object.values(filters)
					?.flat()
					.filter((value) => value && value !== 'favorites')
					?.length ?? 0
			)
		}
	}

	return {
		setters: {
			setFilters,
			toggleFiltersByType,
			addFiltersByType,
			removeFiltersByType,
			setAddOns,
			toggleAddOn,
			addAddOn,
			removeAddOn,
			setPage,
			setSorts,
			setSelects,
			nextPage: () => setPage(modifiers?.page + 1),
			setSearchTarget,
			setQueryModifiers,
		},
		renders: {
			renderSearchAndFilters,
			renderFilters,
			renderSearch,
			renderPagination,
		},
		helpers: {
			filterCount,
		},
		modifiers,
	}
}
