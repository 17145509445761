// @flow
import User from 'src/apollo/_entities/user'
import Organization from 'src/apollo/_entities/organization'
import Location from 'src/apollo/_entities/location'
import axios from 'axios'
import AppConfigs from 'src/configs/app'

const AuthUser = (userData) => {
	if (!userData) return null

	let user = User(userData)
	let organization = Organization(userData?.organization)
	let current_location = Location(userData?.current_location)
	return {
		...user,
		organization,
		current_location,
		organization_id: user?.organization_id ?? organization?.id,
		signOut: async () => {
			axios.defaults.withCredentials = true
			await axios.post(`${AppConfigs.serverUrl}/logout`)
		},
	}
}

export default AuthUser
