// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import {
	ButtonIcon,
	StyledButton,
} from 'src/components/_generic/buttons/button.styles'
import ColorTypes from 'src/configs/Enums/ColorTypes'

export type LinkButtonProps = {
	title: string,
	onClick: () => any,
	icon?: Node,
	type?: string,
	className?: string,
	disabled?: boolean,
	id?: string,
	style?: Object,
	noPadding?: boolean,
	color?: string,
}

const LinkButton = ({
	title,
	icon,
	type = 'button',
	onClick,
	className = '',
	disabled = false,
	id = undefined,
	style = {},
	noPadding = false,
	color = ColorTypes.PRIMARY,
}: LinkButtonProps) => {
	return (
		<StyledButton
			style={{
				...style,
				backgroundColor: 'transparent',
				border: 'none',
				color: 'var(--cm-blue-default)',
			}}
			className={`btn btn-link cursor-pointer border-none border-transparent ${className}`}
			color={color}
			type={type}
			onClick={onClick}
			disabled={disabled}
			id={id}
			noPadding={noPadding}
		>
			<ConditionalRender condition={!!icon}>
				<ButtonIcon>{icon}</ButtonIcon>
			</ConditionalRender>
			<ConditionalRender condition={!!title}>{title}</ConditionalRender>
		</StyledButton>
	)
}

export default LinkButton
