// @flow

export default {
	ORGANIZATION: 6,
	LOCATION: 7,
	VENDOR: 8,
	ORDER_DATE: 9,
	ORDER_STATUS: 10,
	TIME_IN_STATUS: 11,
	OrderFilterType: 6 | 7 | 8 | 9 | 10 | 11,
}
