// @flow
import type { Node } from 'react'
import React from 'react'

type Props = {
	children?: Node,
	style?: Object,
	isExpanded?: boolean,
	customPostfix?: string,
	parentRef?: any,
	className?: string,
}

const SuperSlideyBar = ({
	children,
	isExpanded = true,
	style = {},
	customPostfix = '',
	parentRef = null,
	className = '',
}: Props) => {
	return (
		<div
			className={`cm-super-slidey-bar${customPostfix} ${
				isExpanded
					? `cm-super-slidey-bar-visible${customPostfix}`
					: `cm-super-slidey-bar-hidden${customPostfix}`
			} ${className}`}
			style={style}
			ref={parentRef}
		>
			<div className={`cm-super-slidey-bar-children${customPostfix}`}>
				<div style={{ height: '100%' }}>{children}</div>
			</div>
		</div>
	)
}

export default SuperSlideyBar
