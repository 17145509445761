// @flow

const AccountingCategory = (accountingCategoryData) => {
	if (!accountingCategoryData) return null

	const subtypeData = accountingCategoryData?.subtype
	const subtype = !!subtypeData
		? subtypeData.charAt(0).toUpperCase() +
		  subtypeData.slice(1).toLowerCase()
		: null

	return {
		...accountingCategoryData,
		value: accountingCategoryData?.id,
		label: !!accountingCategoryData?.nominal_code
			? `${accountingCategoryData?.nominal_code} ${
					accountingCategoryData?.fully_qualified_name ??
					accountingCategoryData?.name
			  }`
			: accountingCategoryData?.fully_qualified_name ??
			  accountingCategoryData?.name,
		subtype: subtype,
	}
}

export default AccountingCategory
