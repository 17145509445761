// @flow
import type { Node } from 'react'
import React from 'react'
import TooltipWrapper from 'src/components/_generic/tooltips/tooltip-wrapper'

type Props = {
	children?: Node,
	disabled?: boolean,
	tooltipText?: string,
	tooltipPlacement?: string,
}

const IconWithTooltip = ({
	children,
	tooltipText = '',
	tooltipPlacement = 'auto',
}: Props) => {
	const renderTooltipWrapperContent = (content) => {
		return (
			<TooltipWrapper
				tooltipContent={tooltipText}
				placement={tooltipPlacement}
				CustomElement={'span'}
			>
				{content}
			</TooltipWrapper>
		)
	}

	return <>{renderTooltipWrapperContent(children)}</>
}

export default IconWithTooltip
