// @flow
import { Query, useQuery } from 'src/api/index'
import { GetOrganizationQuery } from 'src/api/organizations/get-organization-query'
import { useQueryClient } from 'react-query'

export const useGetOrganization = (id, requestedKeys = []) => {
	const queryClient = useQueryClient()

	const { data: organization, isLoading } = useQuery({
		key: ['organization', { id: '' + id, requestedKeys: requestedKeys }],
		request: GetOrganizationQuery,
		options: {
			enabled: !!id,
			staleTime: 1000 * 60 * 10,
		},
	})

	const getOrganization = async (id, requestedKeys = []) => {
		return await queryClient.fetchQuery(
			[
				'organization',
				{
					id: '' + id,
					requestedKeys: requestedKeys,
				},
			],
			Query(GetOrganizationQuery, queryClient)
		)
	}

	return { organization, isLoading, getOrganization }
}
