// @flow
import React, { createContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EmptyFunc } from 'src/library/index'
import AccountingSoftwareIntegrationPages from 'src/configs/Enums/AccountingSoftware/AccountingSoftwareIntegrationPages'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

export const ConnectAccountingSoftwareFlowContext = createContext()

export const ConnectAccountingSoftwareFlowProvider = ({
	organizationEntityId,
	providerIdentifier,
	children,
}) => {
	const history = useHistory()
	const [step, setStep] = useState(0)
	const [submit, setSubmit] = useState(() => () => EmptyFunc())
	const [disableButtons, setDisableButtons] = useState(false)

	const integrationContent =
		AccountingSoftwareIntegrationPages.findByValue(providerIdentifier)
	const flowSteps = integrationContent
		?.getConnectFlow()
		?.where('value', '!=', null)
	const stepCount = !isNullOrUndefined(flowSteps) ? flowSteps.length - 1 : 0

	const previousStep = () => {
		if (step === 0) {
			history.goBack()
		} else {
			setStep(step - 1)
		}
	}

	const nextStep = () => {
		if (step === stepCount) {
			history.push(
				`/admin/accounting_software/details/${organizationEntityId}`
			)
		} else {
			setStep(step + 1)
		}
	}

	const state = useMemo(
		() => ({
			organizationEntityId,
			providerIdentifier,
			step,
			setStep,
			submit,
			setSubmit,
			disableButtons,
			setDisableButtons,
			previousStep,
			nextStep,
		}),
		[step, submit, disableButtons]
	)

	return (
		<ConnectAccountingSoftwareFlowContext.Provider value={state}>
			{children}
		</ConnectAccountingSoftwareFlowContext.Provider>
	)
}
