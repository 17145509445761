// @flow
import React from 'react'
import AsyncMultiSelect from 'src/components/_generic/forms/async-select/async-multi-select'
import { useGetLocationGroupList } from 'src/api/location_groups/useGetLocationGroupList'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import type { QueryModifierSetterType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierSetterType'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import type { LocationGroupType } from 'src/flowtypes/Entities/LocationGroupType'
import useCumulativeList from 'src/hooks/useCumulativeList'
import LocationGroup from 'src/apollo/_entities/locationGroup'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	filterFn?: (locationGroup: LocationGroupType) => any,
	organization_id?: number,
	includeAllOption?: boolean,
	modifiers?: QueryModifierType,
	setters?: QueryModifierSetterType,
}

const MultiSelectLocationGroupsDropdown = ({
	organization_id = undefined,
	filterFn = () => true,
	includeAllOption = true,
	modifiers,
	setters,
	...props
}: Props) => {
	const {
		location_groups,
		isLoading: loading,
		getLocationGroupList,
		paginatorInfo,
	} = useGetLocationGroupList(modifiers, organization_id)

	const { cumulativeList } = useCumulativeList(location_groups)
	let options = cumulativeList
	if (includeAllOption) {
		options = [
			{ value: 0, label: 'All Location Groups' },
			...cumulativeList,
		]
	}

	const handleLoadOptions = async (inputValue) => {
		let fetched = await getLocationGroupList(
			{
				...modifiers,
				page: 1,
				resultsPerPage: 30,
				searchTarget: inputValue,
			},
			organization_id
		)
		let queryData =
			(await fetched.location_groups?.map((group) =>
				LocationGroup(group)
			)) || []
		let filtered = queryData?.filter((location_group) =>
			location_group.label
				?.toLowerCase()
				?.includes(inputValue?.toLowerCase())
		)
		return Promise.resolve(filtered)
	}

	const onMenuScrollToBottom = () =>
		paginatorInfo.lastPage > modifiers?.page ? setters?.nextPage() : {}

	return (
		<AsyncMultiSelect
			options={options.filter(filterFn)}
			loading={loading}
			closeMenuOnSelect={location_groups?.length === 1}
			onLoadMore={handleLoadOptions}
			searchTarget={modifiers?.searchTarget}
			onInputChange={setters?.setSearchTarget}
			onMenuScrollToBottom={onMenuScrollToBottom}
			required
			{...props}
		/>
	)
}

export default withQueryModifiers(MultiSelectLocationGroupsDropdown)
