// @flow
import React from 'react'
import PriceForm from 'src/components/Products/Prices/PriceForm'
import ProductStockStatusSection from 'src/components/Products/ProductStatus/ProductStockStatusSection'
import QuickshopQuantity from 'src/components/Store/MarketplaceProductList/Quickshop/AddToCart/quickshop-quantity'
import AddToCartButton from 'src/components/Store/MarketplaceProductList/Quickshop/AddToCart/add-to-cart-button'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'

type Props = {
	product: ProductType,
}

const AddToCartFormUI = ({ product }: Props) => (
	<div style={Styles.pricesAndActions}>
		<div style={Styles.prices}>
			<h6>Stock Status</h6>
			<ProductStockStatusSection
				target={`quickshop-${product?.sku}`}
				product={product}
			/>
			<div className={'cm-mt-double'}>
				<PriceForm product={product} />
			</div>
		</div>
		<div style={Styles.actions}>
			<div className={'cm-mr-normal'} style={Styles.quantityContainer}>
				<QuickshopQuantity height={40} />
			</div>
			<AddToCartButton className={'btn-block'} product={product} />
		</div>
	</div>
)

export default AddToCartFormUI

const Styles = {
	pricesAndActions: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1.2,
		margin: '20px 20px 20px 20px',
		justifyContent: 'space-between',
	},
	prices: {
		flex: 1,
	},
	actions: {
		display: 'flex',
		height: 40,
		marginTop: 20,
	},
	quantityContainer: {
		width: 140,
	},
}
