// @flow
import BudgetAllotment from 'src/apollo/_entities/Budgets/budgetAllotment'
import Location from 'src/apollo/_entities/location'

const Budget = (budgetData) => {
	if (!budgetData) return null

	return {
		...budgetData,
		location: Location(budgetData?.location),
		current_budget_allotment: BudgetAllotment(
			budgetData?.current_budget_allotment
		),
		budget_allotments: budgetData?.budget_allotments?.map((allotment) =>
			BudgetAllotment(allotment)
		),
	}
}

export default Budget
