export const ProductListDataFragment = `
	id
	sku
	name
	packaging_details
	image_path
	is_favorite
	in_full_catalog
	vendor {
		id
		image_path
		name
	}
	base_product {
		id
		manufacturer_sku
		name
		description
		packaging_details
		image_path
		manufacturer {
			id
			image_path
			name
		}
		category {
			id
			name
		}
	}
	sibling_skus
`
