// @flow
import type { Node } from 'react'
import React, { createElement, Fragment } from 'react'
import { DEV_MODE } from 'src/configs/app'

type ErrorInfo = {
	componentStack: string,
}

type Props = {
	children: Node,
	title?: string,
	subTitle?: string,
	isPage?: boolean,
}

type State = {
	error?: Error | null,
	errorInfo?: ErrorInfo | null,
}

class ErrorBoundary extends React.Component<Props, State> {
	static defaultProps = require('./static').staticErrorBoundaryDefaults

	state = { error: null, errorInfo: null }

	static getDerivedStateFromError(error: Error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return { error }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({
			error,
			errorInfo,
		})
	}

	generateComponentStack(componentStack: string) {
		if (!componentStack) {
			return <></>
		}

		const splitStack = componentStack
			?.split('\n')
			?.map((error) => error.trim())

		return (
			<>
				{splitStack.map((error, index) => {
					return <p key={index}>{error}</p>
				})}
			</>
		)
	}

	render() {
		const { subTitle, children, title, isPage } = this.props
		const { error, errorInfo } = this.state

		if (error) {
			return (
				<Fragment>
					{title || subTitle ? (
						<header
							style={{
								paddingTop: isPage ? 40 : 0,
								marginBottom: 4,
							}}
						>
							{title
								? createElement(
										isPage ? 'h1' : 'h4',
										null,
										title
								  )
								: null}
							{subTitle
								? createElement(
										isPage ? 'h2' : 'h5',
										null,
										subTitle
								  )
								: null}
						</header>
					) : null}
					{DEV_MODE ? (
						<section>
							<div>{String(error)}</div>
							{this.generateComponentStack(
								errorInfo?.componentStack
							)}
						</section>
					) : (
						isPage && (
							<div>
								If the issue persist please contact support
							</div>
						)
					)}
				</Fragment>
			)
		}

		return children
	}
}

export default ErrorBoundary
