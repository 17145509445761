// @flow
import { createElement as e, isValidElement } from 'react'
import Footer from 'src/components/_generic/new-modal/footers/cancel-submit-button-footer'
import type { Props } from 'src/components/_generic/new-modal/footers/types'

export function render({ footer, ...rest }: Props) {
	if (typeof footer === 'object' && !isValidElement(footer)) {
		return e(Footer, { ...footer, ...rest })
	}
	return footer
}
