// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetManyOrganizations } from 'src/api/organizations/useGetManyOrganizations'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrganizationPills = ({ filters, setFilters }: Props) => {
	const { organizations } = useGetManyOrganizations(filters?.organizations)

	const handleFilterRemove = (filter) => {
		let activeOrganizationFilters = filters?.organizations?.filter(
			(organization) => '' + organization !== '' + filter
		)
		setFilters({ ...filters, organizations: activeOrganizationFilters })
	}

	let organizationPills = organizations?.filter((organization) =>
		filters?.organizations?.includes(organization?.id)
	)

	return (
		organizationPills?.map((organization) => {
			return (
				<FilterPill
					filter={organization?.name}
					filterType={'ORGANIZATION'}
					onRemove={() => handleFilterRemove(organization?.id)}
					key={`organization-badge-${organization?.id}`}
				/>
			)
		}) ?? null
	)
}

export default RenderOrganizationPills
