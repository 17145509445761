import { gql } from 'graphql-request'

export const FetchStockStatusQuery = {
	identifier: 'fetch_stock_status',
	gql: gql`
		query fetchStockStatus($product_id: ID!) {
			fetchStockStatus(product_id: $product_id)
		}
	`,
	variables: ({ product_id }) => ({ product_id }),
	formatResults: (data) => {
		return data?.fetchStockStatus
	},
}
