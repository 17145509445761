// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	Unknown: {
		value: 0,
		description: 'Unknown return reason',
	},
	InsufficientFunds: {
		value: 1,
		description: 'Insufficient funds',
	},
	AccountClosed: {
		value: 2,
		description: 'The account no longer exists',
	},
	NoAccount: {
		value: 3,
		description: 'The account does not exist',
	},
	InvalidAccountNumberStructure: {
		value: 4,
		description: 'Invalid account number',
	},
	UnauthorizedDebitToConsumer: {
		value: 5,
		description:
			'The account does not accept debit requests. Please contact the account owner.',
	},
	ReturnedPerOdfiRequest: {
		value: 6,
		description: 'Returned by request',
	},
	AuthorizationRevokedByCustomer: {
		value: 7,
		description: 'Payment authorization revoked',
	},
	PaymentStopped: {
		value: 8,
		description: 'Recurring payments returned by recipient request',
	},
	UncollectedFunds: {
		value: 9,
		description: 'Insufficient available funds',
	},
	CustomerAdvisesNotAuthorized: {
		value: 10,
		description: 'Returned by recipient request',
	},
	CheckTruncationEntryReturned: {
		value: 11,
		description: 'Check truncation early return',
	},
	BranchSoldToAnotherDfi: {
		value: 12,
		description: 'Branch sold',
	},
	RdfiNotQualifiedToParticipate: {
		value: 13,
		description: 'RDFI not qualified to participate',
	},
	RepresentativePayeeDeceasedOrUnableToContinue: {
		value: 14,
		description:
			'Representative Payee Deceased or Unable to Continue in that Capacity',
	},
	BeneficiaryOrBankAccountHolderDeceased: {
		value: 15,
		description: 'Account holder unavailable',
	},
	AccountFrozen: {
		value: 16,
		description: 'Account frozen',
	},
	FileRecordEditCriteria: {
		value: 17,
		description: 'File Record Edit Criteria',
	},
	ImproperEffectiveEntryDate: {
		value: 18,
		description: 'Improper effective entry date',
	},
	AmountFieldError: {
		value: 19,
		description: 'Invalid amount',
	},
	NonTransactionAccount: {
		value: 20,
		description: 'The account does not accept transactions',
	},
	InvalidCompanyIdentification: {
		value: 21,
		description: 'Invalid company ID',
	},
	InvalidIndividualIdNumber: {
		value: 22,
		description: 'Invalid individual ID',
	},
	CreditEntryRefusedByReceiver: {
		value: 23,
		description: 'Credit entry refused by receiver',
	},
	DuplicateEntry: {
		value: 24,
		description: 'Duplicate entry',
	},
	AddendaError: {
		value: 25,
		description: 'Addenda error',
	},
	MandatoryFieldError: {
		value: 26,
		description: 'Mandatory field error',
	},
	TraceNumberError: {
		value: 27,
		description: 'Trace number error',
	},
	RoutingNumberCheckDigitError: {
		value: 28,
		description: 'Routing number check digit error',
	},
	CorporateCustomerAdvisesNotAuthorized: {
		value: 29,
		description: 'Corporate customer advises not authorized',
	},
	RdfiNotParticipantInCheckTruncationProgram: {
		value: 30,
		description: 'RDFI does not participate in check truncation program',
	},
	PermissibleReturnEntry: {
		value: 31,
		description: 'Permissible return entry',
	},
	RdfiNonSettlement: {
		value: 32,
		description: 'RDFI non-settlement',
	},
	ReturnOfXckEntry: {
		value: 33,
		description: 'Return of XCK entry',
	},
	LimitedParticipationDfi: {
		value: 34,
		description: 'Limited participation DFI',
	},
	ReturnOfImproperDebitEntry: {
		value: 35,
		description: 'Return of improper debit entry',
	},
	ReturnOfImproperCreditEntry: {
		value: 36,
		description: 'Return of improper credit entry',
	},
	SourceDocumentPresentedForPayment: {
		value: 37,
		description: 'Source document presented for payment',
	},
	StopPaymentOnSourceDocument: {
		value: 38,
		description: 'Stop payment on source document',
	},
	ImproperSourceDocument: {
		value: 39,
		description: 'Improper source document',
	},
})
