// @flow
import React, { useContext } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import NumberedList from 'src/components/_generic/lists/NumberedList'
import s3Image from 'src/library/s3Image'
import { StrongText } from 'src/pages/_styles/text.styles'
import CaptionedImage from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/CaptionedImage'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'

const SageIntacctFlowUserStep = () => {
	const { nextStep, setSubmit } = useContext(
		ConnectAccountingSoftwareFlowContext
	)

	useComponentDidMount(() => {
		setSubmit(() => () => nextStep())
	})

	const listItems = [
		{
			value: (
				<p>
					Navigate to <StrongText>Company > Admin</StrongText>
				</p>
			),
		},
		{
			value: (
				<p>
					Click the <StrongText>+</StrongText> next to{' '}
					<StrongText>Web Services users</StrongText>
				</p>
			),
		},
		{
			value: (
				<p>
					Enter <StrongText>CureMint Integration</StrongText> as the
					User ID
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<p style={Styles.note}>
					<StrongText>Note:</StrongText> This user will only be used
					for integration purposes. The user ID cannot be changed
					after creation.
				</p>
			),
		},
		{
			value: (
				<p>
					Enter a valid <StrongText>Account email address</StrongText>
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<p style={Styles.note}>
					<StrongText>Note:</StrongText> The email is used solely for
					verification purposes whenever a password reset is needed.
					Access to this email will be needed in the next step to
					receive login information. We recommend using a generic
					email admins have access to.
				</p>
			),
		},
		{
			value: (
				<p>
					Under <StrongText>User type</StrongText> select{' '}
					<StrongText>Business</StrongText>
				</p>
			),
		},
		{
			value: (
				<p>
					Under <StrongText>Contact name</StrongText> click on the{' '}
					<StrongText>dropdown button</StrongText>
				</p>
			),
		},
		{
			value: (
				<p>
					Click <StrongText>Add</StrongText> at the top of the
					dropdown list
				</p>
			),
		},
		{
			value: (
				<p>
					Enter <StrongText>Integration</StrongText> as the Last Name
				</p>
			),
		},
		{
			value: (
				<p>
					Enter <StrongText>CureMint</StrongText> as the First Name
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<CaptionedImage
					caption={'Your contact should look like this'}
					alt={'Sage Intacct new contact'}
					src={s3Image(
						'sage_intacct_auth/sage_intacct_new_contact.png'
					)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
					imageStyle={Styles.image}
				/>
			),
		},
		{
			value: (
				<p>
					Click <StrongText>Save</StrongText> in the Contact
					Information modal
				</p>
			),
		},
		{
			value: (
				<p>
					Under <StrongText>Admin privileges</StrongText> select{' '}
					<StrongText>Full</StrongText>
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<CaptionedImage
					caption={'Your web services user should look like this'}
					alt={'Sage Intacct web services user information'}
					src={s3Image(
						'sage_intacct_auth/sage_intacct_web_services_user_information.png'
					)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
					imageStyle={Styles.image}
				/>
			),
		},
		{
			value: (
				<p>
					Click the <StrongText>Roles information</StrongText> tab
				</p>
			),
		},
		{
			value: (
				<p>
					Click next to the <StrongText>1</StrongText> in the table, a
					dropdown should appear
				</p>
			),
		},
		{
			value: (
				<p>
					Select the <StrongText>Admin</StrongText> role in the
					dropdown
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<p style={Styles.note}>
					<StrongText>Note:</StrongText> If the admin role doesn’t
					exist, you’ll need to create one and then return to this
					step.{' '}
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<CaptionedImage
					caption={'Your web services user should look like this'}
					alt={'Sage Intacct web services user roles'}
					src={s3Image(
						'sage_intacct_auth/sage_intacct_web_services_user_roles.png'
					)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
					imageStyle={Styles.image}
				/>
			),
		},
		{
			value: (
				<p>
					Click <StrongText>Save</StrongText> in the top right of the
					page
				</p>
			),
		},
	]

	return (
		<ConnectAccountingSoftwareStepLayout
			title={'Create a Web Services User'}
		>
			<p>
				The web services user is used to connect CureMint and Sage
				Intacct. This Web Services user is used solely for integration
				purposes and will be the user who appears on bills sent into
				Sage Intacct by CureMint.
			</p>
			<NumberedList listItems={listItems} />
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctFlowUserStep

const Styles = {
	note: {
		backgroundColor: '#F7F8F9',
		padding: '10px 15px',
		maxWidth: '620px',
		border: '1px solid #DADEE3',
		borderRadius: '8px',
	},
	image: {
		width: '720px',
	},
}
