import styled, { css } from 'styled-components'
import { Button } from 'reactstrap'

export const ButtonIcon = styled.span`
	margin-right: ${(props) => (props?.noMargin ? '0px' : '8px')};
	font-size: inherit;
`

export const StyledButton = styled(Button)`
	font-size: ${(props) => (props?.size === 'lg' ? '16px' : '14px')};
	white-space: nowrap;
	min-width: max-content;
	display: flex;
	justify-content: center;

	&.btn {
		text-transform: none;
	}

	&.btn-rounded {
		border-radius: 8px;
	}

	&.btn-left-rounded {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	&.btn-right-rounded {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	&.btn-hide-right-border {
		border-right: unset;
	}

	&.btn-no-rounded {
		border-radius: 0;
	}

	${(props) =>
		props.noPadding
			? css`
					padding: 0;
			  `
			: null}
`
