// @flow
import { useMutation } from 'src/api/index'
import { UpsertCartItemMutation } from 'src/api/cart_items/upsert-cart-item-mutation'
import { useQueryClient } from 'react-query'

export const useUpsertCartItem = (options = {}) => {
	const queryClient = useQueryClient()

	return useMutation({
		key: ['upsert_cart_item'],
		request: UpsertCartItemMutation,
		options: {
			onSuccess: async (data, variables) => {
				await UpsertCartItemMutation?.onSuccess(
					['upsert_cart_item'],
					queryClient,
					data,
					variables
				)
			},
			...options,
		},
	})
}
