// @flow
import React from 'react'
import { Badge } from 'reactstrap'
import ConditionalRender from 'src/components/_generic/conditional-render'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import { H3 } from 'src/pages/_styles/text.styles'

type Props = {
	product: ProductType,
	isSibling?: boolean,
}

const ProductInfo = ({ product, isSibling = false }: Props) => {
	return (
		<div className='card-description'>
			<p className='mt-0 mb-0'>{product?.sku}</p>
			<H3 size={'md'} withEllipses={true} lineCount={3}>
				{product?.name}
			</H3>
			<div className='cm-mb-normal'>
				<p style={Styles.upper}>
					By {product?.vendor_name ?? product?.vendor?.name}
				</p>
			</div>
			<ConditionalRender condition={isSibling}>
				<Badge className='cm-mr-half cm-badge-medium-gray'>
					{' '}
					Similar Product
				</Badge>
			</ConditionalRender>
			<ConditionalRender condition={!product?.in_full_catalog}>
				<span style={Styles.unverifiedText}>
					*Product information is unverified
				</span>
			</ConditionalRender>
		</div>
	)
}

export default ProductInfo

const Styles = {
	unverifiedText: {
		fontSize: '10px',
		color: '#d00011',
		fontStyle: 'italic',
	},
	upper: {
		fontSize: 12,
		textTransform: 'uppercase',
	},
}
