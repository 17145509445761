// @flow
import React, { Children } from 'react'
import {
	PageAction,
	PageActionsSection,
} from 'src/components/_generic/pages/page.styles'
import ConditionalRender from 'src/components/_generic/conditional-render'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

const PageActions = ({ children }) => {
	const hasGrandchildren = !!children?.props?.children

	return (
		<PageActionsSection>
			<ConditionalRender
				condition={hasGrandchildren}
				failedRender={<PageAction>{children}</PageAction>}
			>
				{Children.map(children?.props?.children, (pageAction) => {
					if (isNullOrUndefined(pageAction)) return

					return (
						<PageAction>
							<>{pageAction}</>
						</PageAction>
					)
				})}
			</ConditionalRender>
		</PageActionsSection>
	)
}

export default PageActions
