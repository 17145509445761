// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	live: {
		value: 1,
		name: 'Live Price',
		label: 'Live Price First',
		description:
			'Live price will always be selected by default.  If a live price is not available, the lowest price will be selected instead.',
	},
	check_one: {
		value: 2,
		name: 'Lowest Price',
		label: 'Lowest Price First',
		description: 'The lowest price will be selected by default.',
	},
})
