// @flow
import React from 'react'
import type { Node } from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { cvpx } from 'src/components/_generic/utils'

type Props = {
	height?: number | string,
	width?: number | string,
	space?: number | string,
	isLoading: boolean,
	children?: Node,
	style?: Object,
	circle?: boolean,
	className?: string,
}

const SkeletonText = ({
	height: ht = 12,
	width: wd = 100,
	space = 0,
	isLoading = false,
	style = {},
	children,
	circle = false,
	className = '',
}: Props) => {
	const [width, height] = ((circle
		? [cvpx(wd), cvpx(ht)]
		: ([wd, ht]: [any, any])): [number, number])

	return (
		<ConditionalRender condition={isLoading} failedRender={children}>
			<div
				style={{
					width,
					height,
					marginTop: space,
					marginBottom: space,
					...style,
					...(circle
						? {
								height: height < width ? width : height,
								width: width < height ? height : width,
								borderRadius: width / 2,
						  }
						: {}),
				}}
				className={`skeleton-pulse ${className}`}
			/>
		</ConditionalRender>
	)
}

export default SkeletonText
