// @flow
import React, { forwardRef } from 'react'
import { useField, useFormikContext } from 'formik'
import ConditionalRender from 'src/components/_generic/conditional-render'
import type {
	AsyncInputProps,
	CreatableInputProps,
} from 'src/pages/_components/Inputs/_types/FormFieldProps'
import { CreatableSelect } from 'src/pages/_styles/form.styles'
import { FormErrorMessage } from 'src/pages/_styles/text.styles'
import InputLabel from 'src/components/_generic/forms/input-label'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

export type AsyncCreatableInputProps = AsyncInputProps & CreatableInputProps

const AsyncCreatableInput = forwardRef(
	(
		{
			isDisabled,
			name,
			onBlur,
			onChange,
			hideDropdownIndicator = false,
			searchTarget = '',
			onInputChange,
			containerClassName,
			required = false,
			label,
			formInputClass = '',
			className = '',
			minMenuWidth = undefined,
			...rest
		}: AsyncCreatableInputProps,
		ref
	) => {
		const [field, meta] = useField(name)
		const { setFieldValue, setFieldTouched } = useFormikContext()

		const { touched, error } = meta

		const handleBlur = (event) => {
			setFieldTouched(field.name, true)

			if (
				!isNullOrUndefined(event) &&
				(!isNullOrUndefined(event?.value) ||
					!isNullOrUndefined(event?.target?.value))
			) {
				setFieldValue(field?.name, event?.value ?? event?.target?.value)
			}

			if (onBlur) {
				onBlur(event, meta?.error)
			}
		}

		const handleChange = (event) => {
			setFieldValue(field?.name, event?.value ?? event?.target?.value)
			if (onChange) {
				onChange(event, meta?.error)
			}
		}

		const customStyles = {
			menu: (provided) => {
				return {
					...provided,
					minWidth: minMenuWidth,
				}
			},
			menuPortal: (provided) => {
				return {
					...provided,
					minWidth: minMenuWidth,
				}
			},
		}

		return (
			<div
				className={
					typeof containerClassName !== 'undefined'
						? String(containerClassName).toString() +
						  ' ' +
						  className
						: `${formInputClass} ${className}`
				}
			>
				<ConditionalRender condition={label}>
					<InputLabel required={required}>{label}</InputLabel>
				</ConditionalRender>
				<CreatableSelect
					cacheOptions={rest?.cacheOptions ?? true}
					className={`react-select react-select-default ${
						touched && error ? 'cm-select-has-danger' : ''
					}`}
					classNamePrefix={'cm-select'}
					isDisabled={isDisabled}
					name={name}
					onBlur={handleBlur}
					onChange={handleChange}
					hideDropdownIndicator={hideDropdownIndicator}
					inputValue={searchTarget}
					onInputChange={onInputChange}
					aria-errormessage={`${name}-errors`}
					styles={customStyles}
					{...rest}
					ref={ref}
				/>
				<ConditionalRender condition={touched && error}>
					<FormErrorMessage id={`${name}-errors`}>
						{error}
					</FormErrorMessage>
				</ConditionalRender>
			</div>
		)
	}
)

export default AsyncCreatableInput
