// @flow
import React from 'react'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import PriceSelector from 'src/components/Products/Prices/_components/PriceSelector'
import NoStaticPriceMessage from 'src/components/Products/Prices/_components/Labels/no-static-price-message'
import {
	PriceOptionContext,
	PriceOptionProvider,
} from 'src/components/Products/Prices/_contexts/PriceOptionContext'

type Props = {
	product: ProductType,
}

const PriceForm = ({ product }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const isOrgAdmin = authUser?.isOrganizationAdmin()

	return (
		<>
			<h6>Prices</h6>
			<PriceOptionProvider product={product}>
				<PriceOptionContext.Consumer>
					{({ options, computed }) => (
						<>
							<PriceSelector
								options={options}
								computed={computed}
							/>

							<NoStaticPriceMessage
								shouldShow={
									computed.has_only_static_option &&
									!!computed.static_option_has_no_price
								}
								isOrgAdmin={isOrgAdmin}
							/>
						</>
					)}
				</PriceOptionContext.Consumer>
			</PriceOptionProvider>
		</>
	)
}

export default PriceForm
