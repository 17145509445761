// @flow
import React from 'react'
import { default as RAsyncSelect } from 'react-select/async'
import { useField, useFormikContext } from 'formik'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { FormErrorMessage } from 'src/pages/_styles/text.styles'
import InputLabel from 'src/components/_generic/forms/input-label'

type Props = {
	options: any,
	label: string,
	required?: boolean,
	disabled?: boolean,
	loading?: boolean,
	onChange?: () => void,
	onBlur?: () => any,
	closeMenuOnSelect?: boolean,
	onLoadMore?: (any) => any,
	placeholder?: string,
	noOptionsMessage?: (Object) => string,
	searchTarget?: string,
	onInputChange?: (any) => any,
	onMenuScrollToBottom?: (any) => any,
	components?: any,
}

const AsyncMultiSelect = (props: Props) => {
	const [field, meta] = useField(props)
	const { setFieldValue, setFieldTouched } = useFormikContext()
	const {
		options,
		label,
		required = false,
		disabled = false,
		loading = false,
		onChange = () => {},
		closeMenuOnSelect = true,
		onLoadMore = () => {},
		placeholder = 'Select',
		noOptionsMessage = ({ inputValue }) =>
			`No matches found for ${inputValue}`,
		searchTarget = '',
		onInputChange = () => {},
		onMenuScrollToBottom = () => {},
		components = {},
		...rest
	} = props

	const { error, touched } = meta

	const handleBlur = (event) => {
		if (props?.onBlur) {
			props.onBlur(event)
		}
		field.onBlur(event)
		setFieldTouched(field?.name, true)
	}

	return (
		<div style={{ flex: 1 }}>
			<ConditionalRender condition={label}>
				<InputLabel required={required}>{label}</InputLabel>
			</ConditionalRender>
			<RAsyncSelect
				cacheOptions
				defaultOptions={options}
				loadOptions={onLoadMore}
				className={`flex-wrap: 'wrap',react-select react-select-default cm-legacy-text-input ${
					!!(touched && error) ? `cm-select-has-danger` : ''
				}`}
				classNamePrefix='react-select'
				inputProps={{ 'aria-label': label }}
				name={field.name}
				label={label || ''}
				isSearchable
				value={field?.value}
				onChange={(selectedOptions) => {
					setFieldValue && setFieldValue(field.name, selectedOptions)
					onChange && onChange(selectedOptions)
				}}
				onBlur={handleBlur}
				noOptionsMessage={noOptionsMessage}
				onInputChange={onInputChange}
				onMenuScrollToBottom={onMenuScrollToBottom}
				inputValue={searchTarget}
				onFocus={props.onFocus || null}
				isDisabled={disabled}
				isLoading={loading}
				{...rest}
				isMulti={true}
				closeMenuOnSelect={closeMenuOnSelect}
				placeholder={placeholder}
				components={components}
				styles={{
					multiValue: (base) => ({
						...base,
						border: `2px solid var(--color-brand-blue) !important`,
						backgroundColor: 'var(--color-brand-blue) !important',
					}),
					multiValueLabel: (base) => ({
						...base,
						color: '#fff !important',
					}),
					multiValueRemove: (base) => ({
						...base,
						color: '#fff !important',
						borderLeft: '1px solid #fff !important',
					}),
				}}
			/>

			<ConditionalRender condition={touched && error}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</ConditionalRender>
		</div>
	)
}

export default AsyncMultiSelect
