import { gql } from 'graphql-request'
import { PermissionDataFragment } from 'src/api/permissions/_fragments/_permission_data_fragment'
import Permission from 'src/api/permissions/_entities/Permission'

export const GetPermissionsQuery = {
	identifier: 'get_permissions',
	gql: gql`
        query permissions($searchTarget: String) {
            permissions(searchTarget: $searchTarget) {
                ${PermissionDataFragment}
            }
        }
    `,
	formatResults: (data) => {
		return data?.permissions?.map((permission) => Permission(permission))
	},
}
