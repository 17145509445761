// @flow
import type { Node } from 'react'
import React from 'react'
import CardHeader from 'src/components/_generic/cards/card-header'
import {
	CardContainer,
	CardContent,
} from 'src/pages/_components/Cards/_styles/card.styles'

type Props = {
	title?: String | Node,
	description?: String | Node,
	buttons?: Node,
	children?: Node,
	cardProps?: Object,
	className?: String,
	hasShadow?: Boolean,
	noPadding?: Boolean,
	noBodyPadding?: Boolean,
	size?: 'sm' | 'md',
}

const CardSection = ({
	title = null,
	description = null,
	buttons = null,
	children,
	cardProps,
	className = '',
	hasShadow = true,
	noPadding = false,
	noBodyPadding = false,
	size = 'md',
	...rest
}: Props) => {
	const cardStyle = hasShadow
		? cardProps
		: { ...cardProps, ...Styles.noShadow }

	return (
		<CardContainer className={className} style={cardStyle} {...rest}>
			{buttons || title ? (
				<CardHeader
					title={title}
					description={description}
					rightContent={buttons}
					noPadding={noPadding}
					size={size}
				/>
			) : null}
			<CardContent nopadding={noPadding || noBodyPadding ? 1 : 0}>
				{children}
			</CardContent>
		</CardContainer>
	)
}

export default CardSection

const Styles = {
	noShadow: {
		boxShadow: 'none',
	},
}
