// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { TimeInStatus } from 'src/configs/Enums/TimeInStatus'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderTimeInStatusPills = ({ filters, setFilters }: Props) => {
	const handleFilterRemove = (filter) => {
		let statusPills = filters?.timeInStatus?.filter((range) => {
			const remove = TimeInStatus.find((time) => time.label === filter)
			return range.start !== remove?.range.start
		})

		setFilters({ ...filters, timeInStatus: statusPills })
	}

	let statusPills = filters?.timeInStatus?.map((range) => {
		return TimeInStatus.find((time) => time.range.start === range?.start)
	})

	return statusPills?.map((time) => {
		return (
			<FilterPill
				filter={time?.label}
				filterType={'TIME IN STATUS'}
				onRemove={() => handleFilterRemove(time?.label)}
				key={`status-badge-${time?.label}`}
			/>
		)
	})
}

export default RenderTimeInStatusPills
