// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import {
	BadgeIcon,
	StyledBadge,
} from 'src/components/_generic/badges/badge.styles'

type Props = {
	icon?: Node,
	color?: string,
	title?: string,
	type?: 'status' | 'counter' | 'icon' | 'dot',
	variant?: 'bold' | 'subtle',
}

const Badge = ({
	icon,
	color = 'primary',
	title = '',
	type = 'status',
	variant = 'bold',
}: Props) => {
	const renderBadge = () => {
		switch (type) {
			case 'status':
				return (
					<StyledBadge color={color} variant={variant}>
						<ConditionalRender condition={!!icon}>
							<BadgeIcon>{icon}</BadgeIcon>
						</ConditionalRender>
						{title}
					</StyledBadge>
				)
			case 'counter':
			case 'icon':
			case 'dot':
			default:
				break
		}
	}

	return <>{renderBadge()}</>
}

export default Badge
