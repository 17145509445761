// @flow
import React, { useState } from 'react'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import MultiSelectUsersDropdown from 'src/components/Users/SelectUser/MultiSelectUsersDropdown'
import PermissionsDropdown from 'src/components/Permissions/PermissionsDropdown'
import Spacer from 'src/components/_generic/spacer'
import { useFormikContext } from 'formik'

const ActionFollowOnNotify = ({ permissionLabel = '' }) => {
	const [permission, setPermission] = useState(null)
	const { setFieldValue } = useFormikContext()
	const { authUser } = useGetAuthenticatedUser()

	return (
		<>
			<PermissionsDropdown
				name={'permissions'}
				filterFn={(permission) =>
					permission?.name?.split('-').includes('admin')
				}
				showDetailedDisplayName={true}
				onValueChanged={(e) => {
					setPermission(e?.name)
					setFieldValue('approvers', [])
				}}
				label={permissionLabel}
				valueIsId={true}
				menuPortalTarget={document.getElementById(
					'react-select-portal-modals'
				)}
			/>
			<Spacer />
			<MultiSelectUsersDropdown
				name={'approvers'}
				label={'Choose admin(s)'}
				organization_id={authUser?.organization?.id}
				includeAllOption={true}
				extraFilters={
					!!permission
						? {
								permissions: [permission],
						  }
						: null
				}
				filterFn={(user) => !!user}
			/>
		</>
	)
}

export default ActionFollowOnNotify
