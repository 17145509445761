// @flow
import React from 'react'
import MoneyFormatter from 'src/library/MoneyFormatter'
import PaddedLayout from 'src/components/_generic/padded-layout'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import NumberFormat from 'react-number-format'
import useAdminSavesPrice from 'src/components/Products/Prices/_actions/useAdminSavesPrice'
import type { PriceType } from 'src/flowtypes/Entities/PriceType'

type Props = {
	option: Object,
	selected?: boolean,
	onSavePrice?: (PriceType) => any,
}

const EnterPriceOption = ({
	option,
	selected = false,
	onSavePrice,
	...rest
}: Props) => {
	const { newPrice, handlePriceChanged } = useAdminSavesPrice(
		option,
		onSavePrice
	)
	const nullCheckedPrice = newPrice ?? ''
	return (
		<div
			className={`cm-frame cm-frame-option ${
				selected ? 'cm-frame-selected' : ''
			} cm-mb-half`}
			{...rest}
		>
			<PaddedLayout>
				<div className='d-flex flex-row justify-content-between align-content-center'>
					<p
						className='align-self-center text-overflow-ellipsis'
						style={{ flex: 1, maxWidth: 200 }}
					>
						{option?.label}
					</p>
					<div
						style={{ width: 100, marginRight: -10, marginLeft: 20 }}
					>
						<InputGroup>
							<InputGroupAddon addonType='prepend'>
								<InputGroupText>
									<i className='cm-icon cm-icon-dollar-bold' />
								</InputGroupText>
							</InputGroupAddon>
							<NumberFormat
								name={`price`}
								thousandSeparator={false}
								decimalScale={2}
								value={MoneyFormatter.toDollars(
									nullCheckedPrice
								)}
								onValueChange={handlePriceChanged}
								className={'form-control text-right'}
							/>
						</InputGroup>
					</div>
				</div>
			</PaddedLayout>
		</div>
	)
}

export default EnterPriceOption
