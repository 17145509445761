// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	className?: string,
	style?: Object,
	loading?: boolean,
}

const LoadingView = ({ style = {}, loading = true, className = '' }: Props) => {
	return (
		<ConditionalRender condition={loading}>
			<div
				className={className}
				style={{ paddingTop: 20, textAlign: 'center', ...style }}
			>
				<i
					className={
						'cm-icon cm-icon-circle-02 cm-icon-spin cm-icon-xl'
					}
				/>
			</div>
		</ConditionalRender>
	)
}

export default LoadingView
