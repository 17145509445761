// @flow
import React from 'react'
import EmptyFunc from 'src/library/emptyFunc'
import { withFavorites } from 'src/api/products/favorites/withFavorites'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	targetId?: string, // for tooltips
	containerStyle?: {},
	iconStyle?: {},
	isFavorite: boolean,
	onClick: () => any,
	isTableAction?: boolean,
	containerIsHovered?: boolean,
}

const FavoriteIcon = ({
	containerStyle = {},
	iconStyle = {},
	onClick = EmptyFunc,
	targetId = '',
	isFavorite = false,
	isTableAction = false,
	containerIsHovered = null,
}: Props) => {
	const renderFavoriteIcon = () => {
		const iconClassName = isFavorite
			? `cm-icon cm-icon-favorite-2 cm-icon-visible`
			: `cm-icon cm-icon-favorite ${
					containerIsHovered !== null
						? !containerIsHovered
							? 'cm-icon-hidden'
							: 'cm-icon-visible'
						: isFavorite
						? 'cm-icon-hidden'
						: 'cm-icon-visible'
			  }`

		return (
			<div style={{ ...Styles.iconDiv, ...iconStyle }}>
				<i className={iconClassName} onClick={onClick} id={targetId} />
			</div>
		)
	}

	return (
		<ConditionalRender
			condition={
				isFavorite ||
				(containerIsHovered !== null ? containerIsHovered : true)
			}
		>
			<div
				className={`cm-favorite-icon cm-icon-flutter ${
					isTableAction
						? 'cm-favorite-icon-table'
						: 'cm-favorite-icon-image'
				}`}
				style={{ ...Styles.iconContainer, ...containerStyle }}
			>
				{renderFavoriteIcon()}
			</div>
		</ConditionalRender>
	)
}

export default withFavorites(FavoriteIcon)

const Styles = {
	iconContainer: {
		left: 'unset',
		right: '5px',
	},
	iconDiv: {
		cursor: 'pointer',
		position: 'absolute',
	},
}
