// @flow
import React from 'react'
import PermissionRender from 'src/components/_generic/permission-render'
import ErrorPage from 'src/components/_generic/errors/error-page'
import DevComponent from 'src/library/DevFixture/DevComponent'

type Props = {}

const DevFixture = (props: Props) => {
	return (
		<PermissionRender
			permissions={['curemint-developer', 'curemint-admin']}
			failedRender={<ErrorPage type={'Unauthorized'} />}
		>
			<DevComponent {...props} />
		</PermissionRender>
	)
}

export default DevFixture
