import { gql } from 'graphql-request'
import { ShoppingListDataFragment } from 'src/api/shopping_lists/_fragments/_shopping_list_data_fragment'

type UpsertShoppingListType = {
	id: number,
	input: Object,
}

export const UpsertShoppingListMutation = {
	identifier: 'upsert_shopping_list',
	gql: gql`
        mutation upsertShoppingList($id: ID, $input: UpsertShoppingListInput!) {
            upsertShoppingList(id: $id, input: $input) {
                ${ShoppingListDataFragment}
            }
        }
    `,
	variables: (data: UpsertShoppingListType) => data,
	formatResults: (data) => {
		return data?.upsertShoppingList
	},
	onSuccess: (key, queryClient, list) => {
		queryClient.invalidateQueries(['shopping-lists'])
		queryClient.invalidateQueries(['shopping-list', list?.id])
	},
}
