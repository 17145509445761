// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

type Props = {
	children?: Node,
	failedRender?: Node,
	modules: Array<string>,
}

const ModuleRender = ({ children, modules, failedRender }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const hasModule = (modules) => {
		if (isNullOrUndefined(authUser)) {
			return null
		}

		if (!modules || modules.length === 0) {
			return true
		}

		for (let i = 0; i < modules.length; i++) {
			if (authUser?.organization?.hasTags([`module:${modules}`])) {
				return true
			}
		}

		return false
	}

	return (
		<ConditionalRender
			condition={() => hasModule(modules)}
			failedRender={failedRender}
		>
			{children}
		</ConditionalRender>
	)
}

export default ModuleRender
