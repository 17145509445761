// @flow
import enums from 'src/configs/enums'
import type { UpsertOrderRuleType } from 'src/flowtypes/Entities/OrderRuleType'
import MoneyFormatter from 'src/library/MoneyFormatter'

export default {
	// form field: budget_percentage
	forBudgetPercentage: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_BUDGET:
				return (
					!isNaN(+form?.budget_percentage) &&
					+form?.budget_percentage > 0
				)
			default:
				return true
		}
	},
	// form field: categories
	forCategories: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY:
				return form?.categories?.length > 0
			default:
				return true
		}
	},
	// form field: comparison_type
	forComparisonType: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_VENDOR_ORDER:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_MANUFACTURER:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_LINE_OF_BUSINESS:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_LINE_OF_BUSINESS:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_CHART_OF_ACCOUNT:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_CHART_OF_ACCOUNT:
				return (
					!!form?.comparison_type &&
					enums.COMPARISON_TYPE.getComparisonTypes().includes(
						+form?.comparison_type
					)
				)
			default:
				return true
		}
	},
	// form field: formularies
	forFormularies: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_FORMULARY:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY:
				return form?.formularies?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: manufacturers
	forManufacturers: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_MANUFACTURER:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_MANUFACTURER:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER:
				return form?.manufacturers?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: locations
	forLocations: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_LOCATION:
			case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_LOCATION:
			case enums.ORDER_RULE_COMPONENT_TYPE.WHO_LOCATION:
				return form?.locations?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: location_groups
	forLocationGroups: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_GROUP:
			case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_GROUP:
			case enums.ORDER_RULE_COMPONENT_TYPE.WHO_GROUP:
				return form?.location_groups?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: permissions
	forPermissions: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL:
			case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY:
				return !isNaN(+form?.permissions)
			default: {
				return true
			}
		}
	},
	// form field: price
	forPrice: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT:
				let reformatted = MoneyFormatter.cleanMoney(form?.price)
				return !isNaN(reformatted) && reformatted > 0
			default: {
				return true
			}
		}
	},
	// form field: products
	forProducts: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_PRODUCT:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT:
				return form?.products?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: quantity
	forQuantity: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_VENDOR_ORDER:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_MANUFACTURER:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_LINE_OF_BUSINESS:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_CHART_OF_ACCOUNT:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG:
				return Number.isInteger(+form?.quantity) && +form?.quantity > 0
			default:
				return true
		}
	},
	// form field: shopping_lists
	forShoppingLists: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_LIST:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST:
				return form?.shopping_lists?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: spend
	forSpend: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_LINE_OF_BUSINESS:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_CHART_OF_ACCOUNT:
				let reformatted = MoneyFormatter.cleanMoney(form?.spend)
				return !isNaN(reformatted) && reformatted > 0
			default:
				return true
		}
	},
	// form field: users
	forUsers: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.WHO_USER:
				return form?.users?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: vendors
	forVendors: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_VENDOR:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_VENDOR_ORDER:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR:
				return form?.vendors?.length > 0
			default: {
				return true
			}
		}
	},
	// form field: lines_of_business
	forLinesOfBusiness: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_LINE_OF_BUSINESS:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_LINE_OF_BUSINESS:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_LINE_OF_BUSINESS:
				return form?.lines_of_business?.length > 0
			default:
				return true
		}
	},
	// form field: chart_of_accounts
	forChartOfAccounts: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_CHART_OF_ACCOUNT:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_CHART_OF_ACCOUNT:
			case enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_CHART_OF_ACCOUNT:
				return form?.chart_of_accounts?.length > 0
			default:
				return true
		}
	},
	// form field: catalogs
	forCatalogs: (componentType: number, form: UpsertOrderRuleType) => {
		switch (componentType) {
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_CATALOG:
			case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG:
				return form?.catalogs?.length > 0
			default:
				return true
		}
	},
}
