// @flow
import React from 'react'
import MoneyFormatter from 'src/library/MoneyFormatter'
import PaddedLayout from 'src/components/_generic/padded-layout'
import { BadgeDot } from 'src/components/_generic/badge-dot'
import { SkeletonText } from 'src/components/_generic/skeletons/index'

type Props = {
	option: Object,
	selected?: boolean,
}

const LivePriceOption = ({ option, selected, ...rest }: Props) => (
	<div
		className={`cm-frame cm-frame-option ${
			selected ? 'cm-frame-selected' : ''
		} cm-mb-half ${!option?.price_id ? 'cm-frame-disabled' : ''}`}
		{...rest}
	>
		<PaddedLayout>
			<div className='d-flex flex-row justify-content-between align-content-center'>
				<p>
					<BadgeDot
						type={option?.price_id ? 'success' : 'default'}
						className={'mr-3'}
					/>
					Live Price
				</p>
				<p>
					<SkeletonText
						isLoading={option?.is_loading}
						width={80}
						height={24}
					>
						{option?.price
							? MoneyFormatter.toDollars(option?.price)
							: 'Price Unavailable'}
					</SkeletonText>
				</p>
			</div>
		</PaddedLayout>
	</div>
)

export default LivePriceOption
