// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import {
	ButtonIcon,
	StyledButton,
} from 'src/components/_generic/buttons/button.styles'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

type Props = {
	onClick: () => any,
	title?: string,
	icon?: Node,
	id?: string,
	type?: string,
	color?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'info'
		| 'warning'
		| 'danger'
		| 'link',
	size?: 'sm' | 'md' | 'lg',
	className?: string,
	invert?: boolean,
	rounded?: boolean,
	style?: Object,
	disabled?: boolean,
	noMargin?: boolean,
}

const PrimaryButton = ({
	title = null,
	onClick,
	icon,
	size = 'sm',
	id = undefined,
	type = 'button',
	color = 'primary',
	style = {},
	className = '',
	rounded = true,
	invert = false,
	disabled = false,
	noMargin = false,
	...rest
}: Props) => (
	<StyledButton
		{...rest}
		id={id}
		onClick={onClick}
		size={size}
		type={type}
		color={color}
		style={style}
		className={`${noMargin ? '' : 'mr-1'} ${
			rounded ? 'btn-round' : 'btn-rounded'
		} ${
			disabled ? `disabled-checkout-next-btn` : `cm-legacy-btn-${color}`
		} ${className}`}
		disabled={disabled}
		outline={invert}
	>
		<ConditionalRender condition={!!icon}>
			<ButtonIcon noMargin={isNullOrUndefined(title)}>{icon}</ButtonIcon>
		</ConditionalRender>
		{title}
	</StyledButton>
)

export default PrimaryButton
