// @flow
import React from 'react'
import {
	FlexColumn,
	FlexItem,
	FlexRow,
} from 'src/pages/_styles/container.styles'
import { PageTitle } from 'src/components/_generic/pages/page.styles'
import Spacer from 'src/components/_generic/spacer'

type Props = {
	title: string,
	children: Node,
	styles?: Object,
}

const ConnectAccountingSoftwareStepLayout = ({
	title,
	children,
	styles = {},
}: Props) => {
	return (
		<div style={{ ...Styles.container, ...styles }}>
			<Spacer size={50} />
			<FlexRow justifyContent={'center'}>
				<FlexItem flexGrow={0} maxWidth={'800px'} minWidth={'720px'}>
					<FlexColumn alignItems={'flex-start'}>
						<PageTitle>{title}</PageTitle>
						{children}
						<Spacer size={20} />
					</FlexColumn>
				</FlexItem>
			</FlexRow>
		</div>
	)
}

export default ConnectAccountingSoftwareStepLayout

const Styles = {
	container: {
		overflowX: 'hidden',
		overflowY: 'auto',
		height: 'calc(100vh - 245px)',
		margin: 'auto',
	},
}
