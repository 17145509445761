import { gql } from 'graphql-request'
import type { PriceType } from 'src/flowtypes/Entities/PriceType'
import { ProductDataFragment } from 'src/api/products/_fragments/_product_data_fragment'
import Product from 'src/apollo/_entities/product'

type PriceUpsert = {
	id: number,
	input: PriceType,
}

export const UpsertPriceMutation = {
	identifier: 'upsert_price',
	gql: gql`
		mutation upsertPrice($id: ID, $input: UpsertPriceInput!) {
			upsertPrice(id: $id, input: $input) {
				id
				price
				product_id
				formulary {
					id
					name
					is_managed
					owner_id
				}
				product {
                    ${ProductDataFragment}
				}
				unit_of_measure
			}
		}
	`,
	variables: ({ id, input }: PriceUpsert) => ({
		id: id ?? undefined,
		input,
	}),
	formatResults: (data) => {
		return data?.upsertPrice
	},
	onSuccess: (key, queryClient, data) => {
		const productKey = ['product', { id: '' + data?.product?.id }]
		queryClient.setQueryData(productKey, Product(data?.product))
		queryClient.invalidateQueries([
			'product-prices',
			{ product_id: '' + data?.product?.id },
		])
		queryClient.invalidateQueries(['many-product-prices'])
	},
}
