// @flow
import { useCallback, useState } from 'react'
import Papa from 'papaparse'
import filesize from 'src/library/filesize'

export default function useCSVParse() {
	const [parseResults, setParseResults] = useState()
	const [file, setFile] = useState()
	const parse = useCallback((acceptedFiles) => {
		if (acceptedFiles.length > 0) {
			const afile = acceptedFiles[0]
			setFile({
				name: afile.name,
				type: afile.type,
				size: filesize(afile.size, {
					fixed: 2,
					spacer: ' ',
				}).human('si'),
			})

			Papa.parse(afile, {
				header: true,
				dynamicTyping: (header) => {
					return !/^(?=.*?\bSKU\b).*$/.test(header)
				},
				transformHeader: (header) => {
					return header.trim()
				},
				skipEmptyLines: true,
				complete: (results) => {
					setParseResults(results)
				},
			})
		}
	}, [])

	const parseString = useCallback((csvString) => {
		setFile({
			name: 'Pasted CSV Data',
			type: 'text/csv',
			size: 'tbd',
		})

		Papa.parse(csvString, {
			header: true,
			dynamicTyping: (header) => {
				return !/^(?=.*?\bSKU\b).*$/.test(header)
			},
			transformHeader: (header) => {
				return header.trim()
			},
			skipEmptyLines: true,
			complete: (results) => {
				setParseResults(results)
			},
		})
	}, [])

	return { parseResults, file, setFile, parse, parseString }
}

export type ParseResults = {
	data?: Array<string>,
}

export type ParseFile = {
	name: string,
	type: string,
	size: string,
}
