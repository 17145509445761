import styled from 'styled-components'
import CardSection from 'src/components/_generic/cards/card-section'

export const ErrorCard = styled(CardSection)`
	height: 80vh;
	align-items: center;
	padding: 30vh 0;
`

export const ErrorCardBody = styled.div`
	display: flex;
	flex-direction: column;
`

export const BaseErrorTitle = styled.h2`
	font-weight: 600;
	font-size: 44px;
	line-height: 56px;
	align-self: center;
`

export const BaseErrorText = styled.p`
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	align-self: center;
`
