// @flow
import React, { createContext, useContext } from 'react'

const GlobalStateContext = createContext()
export const useGlobalStore = () => useContext(GlobalStateContext)

class GlobalStateProvider extends React.Component {
	state = {
		get: (key) => {
			return this.state[key]
		},
		set: (key, value) => {
			this.setState({ [key]: value })
		},
		remove: (targetKey) =>
			this.setState(
				Object.keys(this.state).reduce((object, key) => {
					if (key !== targetKey) {
						object[key] = this.state[key]
					}
					return object
				}, {})
			),
	}
	render() {
		return (
			<GlobalStateContext.Provider value={this.state}>
				{this.props.children}
			</GlobalStateContext.Provider>
		)
	}
}

export default GlobalStateProvider
