export const OrganizationDataFragment = `
    id
    name
    contact_phone
    contact_email
    image_path

    cs_agents {
        id
        name
        email
        phone
    }
`
