// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	shouldShow: boolean,
	isOrgAdmin: boolean,
}
const NoStaticPriceMessage = ({ shouldShow, isOrgAdmin }: Props) => (
	<ConditionalRender condition={shouldShow && isOrgAdmin}>
		<p>
			Your expected price has not been configured yet. The price you set
			here will be used for your organization until changed.
		</p>
	</ConditionalRender>
)

export default NoStaticPriceMessage
