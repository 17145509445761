// @flow

export default {
	SUBMITTED: {
		value: 0,
		label: 'Submitted',
	},
	MISSING_INFORMATION: {
		value: 1,
		label: 'Missing Information',
	},
	COMPLETE: {
		value: 2,
		label: 'Complete',
	},
}
