// @flow
import { useQuery } from 'src/api/index'
import { GetOrgCatalogsByProductQuery } from 'src/api/catalogs/get-org-catalogs-by-product'

export const useGetOrgCatalogsByProduct = (organization_id, product_id) => {
	const {
		data: catalogs,
		isLoading,
		...rest
	} = useQuery({
		key: [
			'get-org-catalogs-by-product',
			{
				organization_id: `${organization_id}`,
				product_id: `${product_id}`,
			},
		],
		request: GetOrgCatalogsByProductQuery,
		options: {
			enabled: !!organization_id && !!product_id,
			staleTime: 1000 * 60 * 10,
		},
	})

	return { catalogs, isLoading, ...rest }
}
