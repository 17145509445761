// @flow
import React from 'react'
import { Alert } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import ColorTypes from 'src/configs/Enums/ColorTypes'

type Props = {
	isOpen: boolean,
	onClick?: (any) => any,
}

const MissingPhoneNumberNotice = ({ isOpen, onClick }: Props) => {
	const history = useHistory()

	const handleClick = () => {
		if (!!onClick) {
			onClick()
		} else {
			history.push({
				pathname: '/my/account',
				state: { editProfileOpen: true },
			})
		}
	}

	return (
		<>
			<Alert color={ColorTypes.DANGER} isOpen={!!isOpen}>
				You have been granted access to CureMint® Banking, but do not
				have a phone number. Click{' '}
				<span
					className={'alert-link cm-cursor-pointer'}
					onClick={handleClick}
				>
					here
				</span>{' '}
				to resolve this issue now.{' '}
			</Alert>
		</>
	)
}

export default MissingPhoneNumberNotice
