// @flow
import { useState } from 'react'
import { useGlobalStore } from 'src/library/useGlobalState/GlobalStateProvider'
import Cookies from 'universal-cookie'
import isEmpty from 'src/library/isEmpty'
import { useDebouncedCallback } from 'use-debounce'
const cookies = new Cookies()

const useGlobalState = (
	key,
	initialValue,
	persistSessions = false,
	maxAge = undefined
) => {
	const store = useGlobalStore()

	const { callback: saveToStorage } = useDebouncedCallback((value) => {
		if (persistSessions) {
			cookies.set(key, value, { path: '/', maxAge })
		} else {
			store.set(key, value)
		}
	}, 600)

	let savedState = persistSessions ? cookies.get(key) : store.get(key)
	if (savedState === 'false') {
		savedState = false
	} else if (savedState === 'true') {
		savedState = true
	}

	// if no key provided we will just ignore the whole saved state thing.
	const [val, setVal] = useState(
		key && !isEmpty(savedState) ? savedState : initialValue
	)

	const persistVal = (value) => {
		if (key) saveToStorage(value)

		setVal(value)
	}

	return [val, persistVal]
}

export default useGlobalState
