// @flow
import { useMutation } from 'src/api/index'
import { UpsertShoppingListMutation } from 'src/api/shopping_lists/upsert-shopping-list-mutation'

export const useUpsertShoppingList = () => {
	return useMutation({
		key: ['shopping-lists'],
		request: UpsertShoppingListMutation,
	})
}
