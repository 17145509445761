import styled from 'styled-components'
import { Badge } from 'reactstrap'

export const BadgeIcon = styled.span`
	margin-right: 8px;
`

export const StyledBadge = styled(Badge)`
	font-size: 14px;
	text-transform: unset;
	margin-bottom: 0;
`
