// @flow
import React from 'react'
import InputLabel from 'src/components/_generic/forms/input-label'
import { useField, useFormikContext } from 'formik'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { InputGroup } from 'reactstrap'
import EmptyFunc from 'src/library/emptyFunc'
import { FormErrorMessage } from 'src/pages/_styles/text.styles'
import { FlexItem, FlexRow } from 'src/pages/_styles/container.styles'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

type Props = {
	placeholder?: String | null,
	labelAddition?: React.ReactNode,
	isInheritable?: boolean,
	renderCharacterCounter?: (any) => any,
	containerclass?: string,
	isHoverInput?: boolean,
	onKeyDown?: () => void,
	onKeyPress?: (e: any) => void,
	max?: number,
	containerStyle?: Object,
	disableResize?: boolean,
	autocomplete?: String,
	type?: String,
}

const Input = (props: Props) => {
	const [field, meta] = useField(props)
	const { setFieldValue, setFieldTouched } = useFormikContext()
	const {
		label,
		placeholder = null,
		labelAddition,
		required,
		rows,
		cols = undefined,
		addOn,
		isInheritable,
		renderCharacterCounter = undefined,
		containerclass = '',
		formInputClass = '',
		onBlur = null,
		isHoverInput = false,
		onKeyDown = EmptyFunc,
		onKeyPress = undefined,
		max = null,
		containerStyle,
		disableResize = false,
		autoComplete = '',
		type,
		...other
	} = props
	const { touched, error, value, initialValue } = meta

	const isTextArea = !!rows
	const formTypeClass = isTextArea
		? `textarea-limited ${formInputClass}`
		: formInputClass
	const hoverClass = isHoverInput ? 'cm-standard-input-hover' : ''

	const renderInheritanceLabelIfApplicable = () => {
		if (isInheritable && labelAddition) {
			const valueHasChanged = value !== initialValue
			const valueIsEmpty = value === ''
			return valueHasChanged || valueIsEmpty ? null : labelAddition
		}
		return null
	}

	const handleBlur = (e) => {
		setFieldTouched(field.name, true)
		if (onBlur) {
			onBlur(e, meta?.error)
		}
	}

	const handleChange = (e) => {
		setFieldValue(field.name, e.target.value)
		if (props.onChange) {
			props.onChange(e, meta?.error)
		}
	}

	return (
		<div
			className={`${formTypeClass} ${hoverClass} ${containerclass}`}
			style={containerStyle}
		>
			<ConditionalRender
				condition={label || !isNullOrUndefined(renderCharacterCounter)}
			>
				<FlexRow>
					<ConditionalRender condition={label}>
						<FlexItem>
							<InputLabel required={required}>
								{label}
								{renderInheritanceLabelIfApplicable()}
							</InputLabel>
						</FlexItem>
					</ConditionalRender>
					<ConditionalRender
						condition={!isNullOrUndefined(renderCharacterCounter)}
					>
						<FlexItem>
							{renderCharacterCounter &&
								renderCharacterCounter(
									field?.value?.length || 0
								)}
						</FlexItem>
					</ConditionalRender>
				</FlexRow>
			</ConditionalRender>
			<ConditionalRender
				condition={isTextArea}
				failedRender={
					<InputGroup
						className={!!(touched && error) ? `cm-has-danger` : ``}
					>
						<ConditionalRender condition={!!addOn}>
							{addOn}
						</ConditionalRender>
						<input
							{...field}
							{...other}
							autoComplete={autoComplete}
							type={type}
							onBlur={handleBlur}
							onChange={handleChange}
							className='form-control cm-legacy-text-input'
							aria-label={label}
							onKeyDown={onKeyDown}
							onKeyPress={onKeyPress}
							placeholder={placeholder}
						/>
					</InputGroup>
				}
			>
				<textarea
					{...field}
					{...other}
					onBlur={handleBlur}
					onChange={handleChange}
					rows={rows}
					className='form-control'
					aria-label={label}
					onKeyDown={onKeyDown}
					cols={cols}
					maxLength={max}
					placeholder={placeholder}
					style={
						disableResize
							? {
									...styles.disableResize,
									...styles.textareaInputMatch,
							  }
							: { ...styles.textareaInputMatch }
					}
				/>
			</ConditionalRender>

			<ConditionalRender condition={touched && error}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</ConditionalRender>
		</div>
	)
}

export default Input

const styles = {
	disableResize: {
		resize: 'none',
	},
	textareaInputMatch: {
		padding: '7px 12px',
		minHeight: '40px',
	},
}
