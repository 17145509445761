// @flow
import React from 'react'
import AsyncMultiSelect from 'src/components/_generic/forms/async-select/async-multi-select'
import formatProduct from 'src/components/Products/SelectProduct/formatProduct'
import SelectProductDropdownItemSlim from 'src/components/Products/SelectProduct/select-product-dropdown-item-slim'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { useGetProductList } from 'src/api/products/useGetProductList'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	organization_id?: number,
	includeAllOption?: boolean,
	modifiers: QueryModifierType,
}

const MultiSelectProductDropdown = ({
	setters,
	modifiers,
	loading,
	includeAllOption,
	...rest
}: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const { products, getProductList } = useGetProductList({
		...modifiers,
		filters: {
			...modifiers?.filters,
			catalogs: authUser?.catalogs?.map((catalog) => catalog?.id) ?? [],
		},
	})

	const formattedList = products?.map(formatProduct)

	const options = includeAllOption
		? [{ value: 0, label: 'All Products' }, ...formattedList]
		: formattedList

	const loadOptions = async () => {
		const { products: results } = await getProductList({
			...modifiers,
			filters: {
				...modifiers?.filters,
				catalogs: authUser?.catalogs,
			},
		})
		return results?.map(formatProduct) ?? []
	}

	const handleInputChange = (searchVal) => {
		setters.setSearchTarget(searchVal)
	}

	const onMenuScrollToBottom = () => setters?.nextPage()

	return (
		<AsyncMultiSelect
			options={options}
			loading={loading}
			closeMenuOnSelect={products?.length === 1}
			onLoadMore={loadOptions}
			searchTarget={modifiers?.searchTarget}
			components={{ Option: SelectProductDropdownItemSlim }}
			onInputChange={handleInputChange}
			onMenuScrollToBottom={onMenuScrollToBottom}
			menuPortalTarget={document.getElementById('react-select-portal')}
			{...rest}
		/>
	)
}

export default withQueryModifiers(MultiSelectProductDropdown)
