// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	CHART_OF_ACCOUNT: {
		name: 'chart-of-account',
		label: 'Chart of Accounts',
		labelPlural: 'Charts of Accounts',
		filterValue: 1,
	},
	LINE_OF_BUSINESS: {
		name: 'line-of-business',
		label: 'Line of Business',
		labelPlural: 'Lines of Business',
		filterValue: 2,
	},
	LOCATION: {
		name: 'location',
		label: 'Location',
		labelPlural: 'Locations',
		filterValue: 2,
	},
	VENDOR: {
		name: 'vendor',
		label: 'Vendor',
		labelPlural: 'Vendors',
		filterValue: 3,
	},
})
