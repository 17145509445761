// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	unknown: {
		value: 0,
		name: 'Unknown',
		label: 'Unknown',
		color: '#697888',
	},
	unpaid: {
		value: 10,
		name: 'Unpaid',
		label: 'Unpaid',
		color: '#697888',
	},
	draft: {
		value: 20,
		name: 'Draft',
		label: 'Draft',
		color: '#697888',
	},
	approving: {
		value: 30,
		name: 'Approving',
		label: 'Approving',
		color: '#E7AE1B',
	},
	scheduled: {
		value: 40,
		name: 'Scheduled',
		label: 'Scheduled',
		color: '#0071B8',
	},
	rejected: {
		value: 50,
		name: 'Rejected',
		label: 'Rejected',
		unitNames: ['Rejected'],
		color: '#D23F29',
	},
	pending: {
		value: 60,
		name: 'Pending',
		label: 'Pending',
		unitNames: ['Pending', 'PendingReview'],
		color: '#E7AE1B',
	},
	canceled: {
		value: 70,
		name: 'Canceled',
		label: 'Canceled',
		unitNames: ['Canceled'],
		color: '#D23F29',
	},
	clearing: {
		value: 80,
		name: 'Clearing',
		label: 'Clearing',
		unitNames: ['Clearing'],
		color: '#E7AE1B',
	},
	returned: {
		value: 90,
		name: 'Returned',
		label: 'Returned',
		unitNames: ['Returned'],
		color: '#D23F29',
	},
	partially_completed: {
		value: 100,
		name: 'Partially Complete',
		label: 'Partially Complete',
		color: '#2A8D4B',
	},
	complete: {
		value: 110,
		name: 'Complete',
		label: 'Complete',
		unitNames: ['Sent'],
		color: '#2A8D4B',
	},
	getProcessingPaymentStatuses: function () {
		return [this.pending, this.clearing]
	},
	getCanceledPaymentStatuses: function () {
		return [this.canceled, this.rejected]
	},
	getManuallySettableStatuses: function () {
		return [this.unpaid, this.complete]
	},
	getEditableStatusValues: function () {
		return [this.unpaid.value, this.draft.value, this.scheduled.value]
	},
	getDeletableStatusValues: function () {
		return [this.unpaid.value, this.draft.value, this.scheduled.value]
	},
	getPayableStatusesForBill: function () {
		return [
			this.unpaid,
			this.canceled,
			this.returned,
			this.rejected,
		]
	},
})
