import styled from 'styled-components'

export const ToastCard = styled.div`
	background-color: #fff;
	border-radius: 12px;
	overflow: hidden;
	display: flex;
	flex-wrap: nowrap;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`

export const ToastTitle = styled.h3`
	font-size: 14px;
	line-height: 21px;
	font-weight: 600;
`

export const ToastLeftBorder = styled.div`
	background-color: ${(props) => props?.color};
	width: 8px;
	flex-shrink: 0;
`

export const ToastSection = styled.div`
	display: flex;
	flex-wrap: nowrap;
	padding: 12px 12px 8px 12px;
	flex-grow: 1;
`

export const ToastTextSection = styled.div`
	margin: 0px 8px;
`

export const ToastMessageText = styled.p`
	font-size: 12px;
`

export const ToastIconSection = styled.div`
	font-size: 16px;
`

export const ToastCloseButton = styled.button`
	flex-grow: 1;
	align-self: flex-start;
	text-align: right;
	margin-right: 4px;
	font-weight: 600;
	font-size: 16px;

	&.close:focus {
		outline: 0;
	}
`
