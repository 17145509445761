// @flow
import type { Node } from 'react'
import React from 'react'

type Props = {
	children?: Node,
	failedRender?: Node,
	condition: boolean | (() => boolean),
	isNullRender?: boolean, // instead of just rendering one or other render both but set display to none inherit depending on flag
}

const ConditionalRender = ({
	children,
	condition,
	failedRender = null,
	isNullRender = true,
}: Props) => {
	const renderHiddenContent = (content) => {
		return <div style={{ display: 'none' }}>{content}</div>
	}

	const renderShowContent = (content) => {
		return <>{content}</>
	}

	let showChildren = false
	if (typeof condition === 'function') {
		showChildren = condition()
	} else {
		showChildren = condition
	}

	if (isNullRender) {
		return <>{showChildren ? children : failedRender}</>
	} else {
		if (showChildren) {
			return (
				<>
					{renderShowContent(children)}{' '}
					{renderHiddenContent(failedRender)}
				</>
			)
		} else {
			return (
				<>
					{renderShowContent(failedRender)}{' '}
					{renderHiddenContent(children)}
				</>
			)
		}
	}
}

export default ConditionalRender
