// @flow
import React from 'react'
import AsyncSelect from 'src/components/_generic/forms/async-select/async-select'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import type { QueryModifierSetterType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierSetterType'
import SelectVendorDropdownItem from 'src/components/Vendors/SelectVendor/SelectVendorDropdown/select-vendor-dropdown-item'
import VendorNotFoundCreateView from 'src/components/Vendors/CreateVendor/VendorNotFoundCreateView'
import Vendor from 'src/api/vendors/_entities/Vendor'
import { Query, useQuery } from 'src/api/index'
import { useField } from 'formik'
import { gql } from 'graphql-request'
import VendorsApi from 'src/pages/vendors/_apis/vendors-api'
import { useQueryClient } from 'react-query'
import useCumulativeList from 'src/hooks/useCumulativeList'

type Props = {
	onChange: (any) => any,
	styles?: Object,
	filters?: Object,
	hideCreate?: boolean,
	modifiers?: QueryModifierType,
	setters?: QueryModifierSetterType,
}

const SelectVendorDropdown = ({
	onChange,
	styles = {},
	filters = {},
	hideCreate = false,
	modifiers,
	setters,
	...rest
}: Props) => {
	const queryClient = useQueryClient()
	const [field] = useField(rest.name)

	if (!!filters) {
		modifiers = {
			...modifiers,
			filters: { ...modifiers?.filters, ...filters },
		}
	}

	const { data, isLoading } = useQuery({
		key: ['get_vendor_list_dropdown', { modifiers, id: field?.value }],
		request: !!field?.value
			? VendorsApi.getVendorsForDropdown([
					SelectVendorDropdown.fragments.DropdownData,
			  ])
			: VendorsApi.getVendors([
					SelectVendorDropdown.fragments.DropdownData,
			  ]),
		options: {
			enabled: !!modifiers,
			staleTime: 1000 * 60 * 10,
		},
	})

	const onCreateNewVendor = (vendor) => {
		typeof onChange === 'function'
			? onChange(vendor)
			: new Error('Required: prop onChange not passed to Component')
	}

	const { cumulativeList } = useCumulativeList(data?.vendors ?? [])

	const options = () => {
		return !!rest?.manualOptions?.length
			? rest?.manualOptions
			: cumulativeList
	}

	const onMenuScrollToBottom = () => {
		return data?.paginatorInfo?.lastPage > data?.paginatorInfo?.currentPage
			? setters.setPage(data?.paginatorInfo?.currentPage + 1)
			: {}
	}

	const filterResultsBySearchTerm = async (inputValue) => {
		const getVendors = async (modifiers: QueryModifierType) => {
			return await queryClient.fetchQuery(
				['get_vendors_list_dropdown', { modifiers }],
				Query(
					VendorsApi.getVendors([
						SelectVendorDropdown.fragments.DropdownData,
					])
				)
			)
		}

		let fetched = await getVendors({
			...modifiers,
			searchTarget: inputValue,
		})
		let queryData =
			(await fetched?.vendors?.map((vendor) => Vendor(vendor))) ?? []

		let filtered = queryData.filter((vendor) =>
			vendor?.label.toLowerCase().includes(inputValue.toLowerCase())
		)

		return Promise.resolve(filtered)
	}

	return (
		<AsyncSelect
			options={options()?.filter((vendor) =>
				vendor?.label
					?.toLowerCase()
					.includes(modifiers?.searchTarget?.toLowerCase())
			)}
			loading={isLoading}
			searchTarget={modifiers?.searchTarget}
			onLoadMore={filterResultsBySearchTerm}
			components={{ Option: SelectVendorDropdownItem }}
			onInputChange={setters.setSearchTarget}
			onMenuScrollToBottom={onMenuScrollToBottom}
			noOptionsMessage={({ inputValue }) => {
				if (!hideCreate) {
					return (
						<VendorNotFoundCreateView
							vendorName={inputValue}
							shouldAddToOrg={true}
							onSubmit={onCreateNewVendor}
							organizationId={
								modifiers?.filters?.organizations?.[0]
							}
						/>
					)
				} else {
					return `Vendor ${inputValue} not found`
				}
			}}
			minMenuWidth={300}
			onChange={onChange}
			styles={styles}
			cacheOptions={false}
			{...rest}
		/>
	)
}

export default withQueryModifiers(SelectVendorDropdown, null, [], 10)

SelectVendorDropdown.fragments = {
	DropdownData: gql`
		fragment DropdownData on Vendor {
			id
			name
			image_path
			units_of_measure_count
			units_of_measure {
				id
				name
			}
			default_order_email
			is_vetted
			is_catalogable
			is_transcription_skippable
			organization_vendor {
				id
				order_email
				account_number
				connection_status
				can_place_order
			}
		}
	`,
}
