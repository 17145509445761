// @flow
import { Query, useQuery } from 'src/api/index'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { GetLocationListQuery } from 'src/api/locations/get-location-list-query'
import Location from 'src/apollo/_entities/location'
import { useQueryClient } from 'react-query'

export const useGetLocationList = (
	modifiers: QueryModifierType,
	showLocationCode = false
) => {
	const queryClient = useQueryClient()

	const { data, isLoading } = useQuery({
		key: ['get_location_list', { modifiers }],
		request: GetLocationListQuery,
		options: {
			enabled: !!modifiers,
			staleTime: 1000 * 60 * 10,
		},
	})

	const locations =
		data?.data?.map((location) => Location(location, showLocationCode)) ??
		[]
	const paginatorInfo = data?.paginatorInfo ?? {
		lastPage: 1,
	}

	const getLocationList = async (modifiers: QueryModifierType) => {
		return await queryClient.fetchQuery(
			['get_location_list', { modifiers }],
			Query(GetLocationListQuery)
		)
	}

	return {
		locations,
		location_count: paginatorInfo?.total,
		isLoading,
		paginatorInfo,
		getLocationList,
	}
}
