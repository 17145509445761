import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useFlags } from 'launchdarkly-react-client-sdk'

type Props = {
	children?: Node, // optional, the children to render if the check succeeds
	failedRender?: Node, // optional, the children to render if the check fails
	flagNames: Array<string>, // required, the array of flag names to check, in camelCase.
}

const LDRender = ({ children, failedRender, flagNames }: Props) => {
	// this gets all the flags that are client-visible from LaunchDarkly. They get converted from hyphen-case to
	// camelCase during this process.
	const flags = useFlags()

	const flagsArray = flagNames.map((flagName) => {
		return flags[`${flagName}`]
	})

	return (
		<ConditionalRender
			condition={flagsArray.every(Boolean)}
			failedRender={failedRender}
		>
			{children}
		</ConditionalRender>
	)
}

export default LDRender
