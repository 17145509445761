// @flow

const ShoppingList = (listData) => {
	if (!listData) return null

	const ownableType = () => {
		const stringData = listData?.ownable_type?.split('\\')
		const owner = stringData.pop()

		if (owner === 'LocationGroup') {
			return 'location-group'
		} else {
			return owner.toLowerCase()
		}
	}

	return {
		...listData,
		value: listData?.id,
		label: listData?.name,
		ownable_type: ownableType(),
	}
}

export default ShoppingList
