// @flow
import { useQuery } from 'src/api/index'
import { GetManyLocationsQuery } from 'src/api/locations/get-many-locations-query'

export const useGetManyLocations = (ids = []) => {
	const {
		data: locations,
		isLoading,
		...rest
	} = useQuery({
		key: ['get_many_locations', { ids: ids.map((id) => '' + id) }],
		request: GetManyLocationsQuery,
		options: {
			enabled: ids?.length > 0,
			staleTime: 1000 * 60 * 10,
		},
	})

	return { locations, isLoading, ...rest }
}
