// @flow
import React, { useEffect, useState } from 'react'
import { useMutation } from 'src/api/index'
import { AddFavoriteMutation } from 'src/api/products/favorites/add-favorite-mutation'
import { RemoveFavoriteMutation } from 'src/api/products/favorites/remove-favorite-mutation'
import { useGetFavoriteIds } from 'src/api/products/favorites/useGetFavoriteIds'

export const withFavorites =
	(Component) =>
	({ product, ...rest }) => {
		const { favorite_ids } = useGetFavoriteIds()
		const [isFavorite, setIsFavorite] = useState(
			favorite_ids?.includes('' + product?.id)
		)

		const { mutate: addFavoriteProduct } = useMutation({
			key: 'favorite_products',
			request: AddFavoriteMutation,
		})
		const { mutate: removeFavoriteProduct } = useMutation({
			key: 'favorite_products',
			request: RemoveFavoriteMutation,
		})

		useEffect(() => {
			if (favorite_ids) {
				setIsFavorite(favorite_ids?.includes('' + product?.id))
			}
		}, [favorite_ids])

		const handleFavoriteClick = () => {
			if (isFavorite) {
				removeFavoriteProduct(product?.id)
				setIsFavorite(false)
			} else {
				addFavoriteProduct(product?.id)
				setIsFavorite(true)
			}
		}

		return (
			<Component
				isFavorite={isFavorite}
				onClick={handleFavoriteClick}
				product={product}
				{...rest}
			/>
		)
	}
