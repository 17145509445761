// @flow
import { useEffect, useState } from 'react'
import useComponentDidMount from 'src/hooks/useComponentDidMount'
import { EmptyFunc } from 'src/library/index'

// Hook that helps determine if a ref has a scroll bar or is at scroll bottom
export default function useTargetScrolling(
	contentRef,
	onScrollToBottom = EmptyFunc
) {
	const [atBottom, setAtBottom] = useState(false)
	const [prevAtBottom, setPrevAtBottom] = useState(false)
	const [hasScroll, setHasScroll] = useState(false)

	const isAtBottom = (target) => {
		if (!target) {
			return false
		}

		return target.scrollHeight - target.scrollTop - 1 <= target.clientHeight
	}

	useComponentDidMount(() => {
		if (contentRef) {
			setHasScroll(!isAtBottom(contentRef.current))
			setPrevAtBottom(isAtBottom(contentRef.current))
			setAtBottom(isAtBottom(contentRef.current))
		}
	}, null)

	const handleScroll = ({ target }) => {
		if (target === contentRef?.current) {
			setPrevAtBottom(atBottom)
			setAtBottom(isAtBottom(target))
		}
	}

	const isNewAtBottom =
		atBottom && atBottom !== prevAtBottom && prevAtBottom === false

	useEffect(() => {
		if (isNewAtBottom) {
			onScrollToBottom()
		}
	}, [isNewAtBottom])

	return { atBottom, isNewAtBottom, hasScroll, handleScroll }
}
