// @flow
import React, { useState } from 'react'
import { useField, useFormikContext } from 'formik'
import ConditionalRender from 'src/components/_generic/conditional-render'
import InfoIcon from 'src/components/_generic/icons/info-icon'
import TooltipWrapper from 'src/components/_generic/tooltips/tooltip-wrapper'
import { FormErrorMessage } from 'src/pages/_styles/text.styles'
import InputLabel from 'src/components/_generic/forms/input-label'
import PaddedLayout from 'src/components/_generic/padded-layout'
import Spacer from 'src/components/_generic/spacer'
import DisabledOverlayWithTooltip from 'src/components/_generic/disabled-overlay-with-tooltip'

type Props = {
	label?: string,
	onChange?: (any) => void,
	name: string,
	options: Array<{
		label: string,
		value: any,
		renderComponentAfter?: any,
		subtitleText?: string,
		tooltipText?: string,
		disabled?: boolean,
		disabledTooltip?: string,
		styles?: Object,
	}>,
	displayOptionsOnNewLine?: boolean,
	hideRadioInput?: boolean,
	required?: boolean,
	isBlockLabel?: boolean,
	isBordered?: boolean,
	isBorderedFill?: boolean,
}

const RadioGroup = (props: Props) => {
	const [activeOption, setActive] = useState('')
	const [field, meta] = useField(props)
	const { setFieldValue } = useFormikContext()
	const {
		label = '',
		onChange = () => {},
		options,
		displayOptionsOnNewLine = false,
		hideRadioInput = false,
		required = true,
		isBlockLabel = false,
		isBordered = false,
		isBorderedFill = false,
		...other
	} = props
	const { touched, error } = meta

	const isBlock = isBlockLabel || isBordered || isBorderedFill

	const handleChange = (e) => {
		setFieldValue(field.name, e.target.value)
		if (isBlockLabel) {
			setActive(e.target.value)
		}
		onChange(e.target.value)
	}

	const activeClasses = (value) => {
		if (isBlockLabel) {
			if (activeOption === value.toString() || field.value === value) {
				return 'cm-block-radio-label-active'
			} else {
				return 'cm-block-radio-label'
			}
		} else if (isBordered) {
			if (activeOption === value.toString() || field.value === value) {
				return 'cm-radio-bordered-active'
			} else {
				return 'cm-radio-bordered'
			}
		} else if (isBorderedFill) {
			if (activeOption === value.toString() || field.value === value) {
				return 'cm-radio-bordered-fill-active'
			} else {
				return 'cm-radio-bordered'
			}
		} else {
			return ''
		}
	}

	return (
		<>
			<ConditionalRender condition={label}>
				<InputLabel required={required}>{label}</InputLabel>
			</ConditionalRender>
			<div
				style={{
					display: 'flex',
					flexDirection: displayOptionsOnNewLine ? 'column' : 'row',
				}}
			>
				{options.map((option) => (
					<>
						<div key={`radio-group-option-${option?.value}`}>
							<DisabledOverlayWithTooltip
								disabled={option.disabled}
								tooltipText={option.disabledTooltip}
								tooltipPlacement={'top'}
							>
								<label
									className={`
                                    cm-form-check-label
                                    ${activeClasses(option.value)}
                                `}
									key={option.value}
								>
									<input
										{...field}
										{...other}
										onChange={handleChange}
										type='radio'
										aria-label={option.label}
										value={option.value}
										checked={
											option.value.toString() ===
											field.value
										}
										className={
											hideRadioInput ? 'cm-hidden' : ''
										}
										disabled={option.disabled}
									/>
									<ConditionalRender
										condition={isBlock}
										failedRender={
											<>
												<span
													style={Styles.optionLabel}
												>
													{option.label}
												</span>
												{!!option.subtitleText && (
													<>
														<br />
														<p
															className={
																'cm-subtitle'
															}
															style={
																Styles.optionSubtitle
															}
														>
															{
																option.subtitleText
															}
														</p>
													</>
												)}
											</>
										}
									>
										<PaddedLayout>
											<p style={Styles.optionLabelBlock}>
												{option.label}
											</p>
											{!!option.subtitleText && (
												<p className={'cm-subtitle'}>
													{option.subtitleText}
												</p>
											)}
										</PaddedLayout>
									</ConditionalRender>

									<ConditionalRender
										condition={!!option?.tooltipText}
									>
										<span
											className={'align-middle'}
											style={Styles.tooltipContainer}
										>
											<TooltipWrapper
												targetId={`radio-group-option-${option?.value}`}
												tooltipContent={
													<span>
														{option?.tooltipText}
													</span>
												}
												placement='right'
											>
												<InfoIcon
													style={Styles.tooltipIcon}
												/>
											</TooltipWrapper>
										</span>
									</ConditionalRender>
								</label>
							</DisabledOverlayWithTooltip>
							{displayOptionsOnNewLine ? <br /> : null}
							<ConditionalRender
								condition={!!option?.renderComponentAfter}
							>
								{option?.renderComponentAfter}
							</ConditionalRender>
						</div>
						<Spacer
							row={!displayOptionsOnNewLine}
							size={isBlock ? 20 : 0}
						/>
					</>
				))}
			</div>
			<ConditionalRender condition={touched && error}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</ConditionalRender>
		</>
	)
}

export default RadioGroup

const Styles = {
	optionLabel: {
		marginLeft: '10px',
		marginTop: '-4px',
	},
	optionSubtitle: {
		marginLeft: '25px',
	},
	optionLabelBlock: {
		textAlign: 'center',
		fontWeight: 600,
	},
	tooltipContainer: {
		marginLeft: '5px',
	},
	tooltipIcon: {
		marginTop: '5px',
	},
}
