// @flow
import React, { useState } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
	BannerBox,
	BannerIcon,
	BannerTitle,
} from 'src/components/_generic/banners/banner.styles'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { FlexItem, FlexRow } from 'src/pages/_styles/container.styles'
import Spacer from 'src/components/_generic/spacer'
import CloseIconButton from 'src/components/_generic/buttons/close-icon-button'

type Props = {
	bannerType: string,
	children?: Node,
	title?: string,
	showIcon?: boolean,
	noMargin?: boolean,
	clearable?: boolean,
}

const PageBanner = ({
	title,
	bannerType,
	showIcon = true,
	noMargin = false,
	clearable = false,
	children,
}: Props) => {
	const [isOpen, setIsOpen] = useState(true)

	let backgroundColor
	let color
	let icon
	switch (bannerType) {
		case 'warning':
			backgroundColor = '#F8E9C1'
			color = '#E7AE1B'
			icon = solid('triangle-exclamation')
			break
		case 'danger':
			backgroundColor = '#F9E5E2'
			color = '#D23F29'
			icon = solid('diamond-exclamation')
			break
		case 'info':
		default:
			backgroundColor = '#E1F3FF'
			color = '#0071B8'
			icon = solid('info-circle')
			break
	}

	return (
		<ConditionalRender condition={isOpen}>
			<BannerBox
				role='banner'
				bgColor={backgroundColor}
				bordColor={color}
				noMargin={noMargin}
			>
				<FlexRow alignItems={'center'}>
					<ConditionalRender
						condition={showIcon}
						failedRender={<Spacer row size={0} />}
					>
						<FlexItem flexGrow={0}>
							<BannerIcon
								icon={icon}
								iconColor={color}
								noMargin={noMargin}
							/>
						</FlexItem>
					</ConditionalRender>
					<ConditionalRender condition={title?.length > 0}>
						<FlexItem flexGrow={0} flexShrink={0}>
							<BannerTitle>{title}</BannerTitle>
						</FlexItem>
					</ConditionalRender>
					<FlexItem>{children}</FlexItem>
					<ConditionalRender condition={clearable}>
						<FlexItem alignSelf={'flex-end'}>
							<CloseIconButton onClick={() => setIsOpen(false)} />
						</FlexItem>
					</ConditionalRender>
				</FlexRow>
			</BannerBox>
		</ConditionalRender>
	)
}

export default PageBanner
