// @flow
export default {
	UNKNOWN: 1,
	IN_STOCK: 10,
	OUT_OF_STOCK: 20,
	BACKORDERED: 30,
	DISCONTINUED: 40,

	UNKNOWN_HUMAN_READABLE: 'Stock status not provided by vendor',
	SHORTHAND_UNKNOWN_HUMAN_READABLE: 'Unknown',
	IN_STOCK_HUMAN_READABLE: 'In Stock',
	OUT_OF_STOCK_HUMAN_READABLE: 'Out of Stock',
	BACKORDERED_HUMAN_READABLE: 'Backordered',
	DISCONTINUED_HUMAN_READABLE: 'Discontinued',

	getHumanReadable: function (status: number): number {
		switch (status) {
			case this.IN_STOCK:
				return this.IN_STOCK_HUMAN_READABLE
			case this.OUT_OF_STOCK:
				return this.OUT_OF_STOCK_HUMAN_READABLE
			case this.BACKORDERED:
				return this.BACKORDERED_HUMAN_READABLE
			case this.DISCONTINUED:
				return this.DISCONTINUED_HUMAN_READABLE
			default:
				return this.UNKNOWN_HUMAN_READABLE
		}
	},

	getShorthandHumanReadable: function (status: number): number {
		switch (status) {
			case undefined:
			case this.UNKNOWN:
				return this.SHORTHAND_UNKNOWN_HUMAN_READABLE
			default:
				return this.getHumanReadable(status)
		}
	},
}
