// @flow
import React from 'react'
import MoneyFormatter from 'src/library/MoneyFormatter'
import PaddedLayout from 'src/components/_generic/padded-layout'

type Props = {
	option: Object,
	selected?: boolean,
}

const ManagedPriceOption = ({ option, selected, ...rest }: Props) => {
	return (
		<div
			className={`cm-frame cm-frame-option ${
				selected ? 'cm-frame-selected' : ''
			} cm-mb-half`}
			{...rest}
		>
			<PaddedLayout>
				<div className='d-flex flex-row justify-content-between align-content-center'>
					<p>{option?.label}</p>
					<p>${MoneyFormatter.toDollars(option?.price)}</p>
				</div>
			</PaddedLayout>
		</div>
	)
}

export default ManagedPriceOption
