// @flow

const VendorAlias = (aliasData) => {
	if (!aliasData) return null

	return {
		...aliasData,
	}
}

export default VendorAlias
