// @flow
import enums from 'src/configs/enums'

export const componentTypeRequiresFollowOnQuestions = (
	componentType: number
): boolean => {
	switch (componentType) {
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ORG:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ALL:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_ALL:
			return false
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_LOCATION:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_GROUP:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_LOCATION:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_GROUP:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_LOCATION:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_GROUP:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_USER:
			return true

		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_PRODUCT:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_FORMULARY:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_LIST:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_VENDOR:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_MANUFACTURER:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_CATALOG:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_BUDGET:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR_ORDER:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_MANUFACTURER:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT:
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_PURCHASED_LINE_OF_BUSINESS:
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_QUANTITY_LINE_OF_BUSINESS:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_LINE_OF_BUSINESS:
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_PURCHASED_CHART_OF_ACCOUNT:
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_QUANTITY_CHART_OF_ACCOUNT:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_CHART_OF_ACCOUNT:
			return true
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_OFF_CATALOG:
			return false

		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY:
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL:
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY_USER:
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL_USER:
			return true

		default:
			return false
	}
}
