// @flow
import { getOrderRuleComponentFollowOnQuestions } from 'src/configs/Enums/OrderRules/OrderRuleComponentFollowOnQuestions'
import enums from 'src/configs/enums'
import OrderRuleFormValidators from 'src/configs/Enums/OrderRules/OrderRuleFormValidators'
import { getHumanReadableComponentType } from 'src/configs/Enums/OrderRules/GetHumanReadableComponentType'
import { getComponentTypeFromHumanReadable } from 'src/configs/Enums/OrderRules/GetComponentTypeFromHumanReadable'
import { componentTypeRequiresFollowOnQuestions } from 'src/configs/Enums/OrderRules/ComponentTypeRequiresFollowOnQuestions'
import getComponentTags from 'src/configs/Enums/OrderRules/GetOrderRuleTags'

export default {
	WHO_PURCHASER_LOCATION: 1,
	WHO_PURCHASER_GROUP: 2,
	WHO_PURCHASER_ORG: 3,
	WHO_PURCHASER_ALL: 4,
	WHO_ADMIN_LOCATION: 5,
	WHO_ADMIN_GROUP: 6,
	WHO_ADMIN_ALL: 7,
	WHO_LOCATION: 8,
	WHO_GROUP: 9,
	WHO_USER: 10,

	CONDITION_PURCHASED_PRODUCT: 100,
	CONDITION_PURCHASED_FORMULARY: 101,
	CONDITION_PURCHASED_LIST: 102,
	CONDITION_PURCHASED_VENDOR: 103,
	CONDITION_PURCHASED_MANUFACTURER: 104,
	CONDITION_BUDGET: 105,
	CONDITION_QUANTITY_PRODUCT: 106,
	CONDITION_QUANTITY_FORMULARY: 107,
	CONDITION_QUANTITY_CATEGORY: 108,
	CONDITION_QUANTITY_LIST: 109,
	CONDITION_QUANTITY_VENDOR: 110,
	CONDITION_QUANTITY_MANUFACTURER: 111,
	CONDITION_SPEND_ORDER: 112,
	CONDITION_SPEND_VENDOR: 113,
	CONDITION_SPEND_MANUFACTURER: 114,
	CONDITION_SPEND_PRODUCT: 115,
	CONDITION_PRICE_PRODUCT: 116,
	CONDITION_QUANTITY_VENDOR_ORDER: 117,
	CONDITION_PURCHASED_LINE_OF_BUSINESS: 118,
	CONDITION_QUANTITY_LINE_OF_BUSINESS: 119,
	CONDITION_SPEND_LINE_OF_BUSINESS: 120,
	CONDITION_PURCHASED_CHART_OF_ACCOUNT: 121,
	CONDITION_QUANTITY_CHART_OF_ACCOUNT: 122,
	CONDITION_SPEND_CHART_OF_ACCOUNT: 123,
	CONDITION_PURCHASED_OFF_CATALOG: 124,
	CONDITION_PURCHASED_CATALOG: 125,
	CONDITION_QUANTITY_CATALOG: 126,
	CONDITION_TYPE_ATF: 127,

	ACTION_NOTIFY: 200,
	ACTION_NEEDS_APPROVAL: 201,
	ACTION_NOTIFY_USER: 202,
	ACTION_NEEDS_APPROVAL_USER: 203,

	getWhoComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_LOCATION,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_GROUP,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ORG,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ALL,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_LOCATION,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_GROUP,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_ALL,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_LOCATION,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_GROUP,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_USER,
		]
	},

	getPurchaserWhoComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_LOCATION,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_GROUP,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ORG,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ALL,
		]
	},

	getAdminWhoComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_LOCATION,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_GROUP,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_ALL,
		]
	},

	getOtherWhoComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_LOCATION,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_GROUP,
			enums.ORDER_RULE_COMPONENT_TYPE.WHO_USER,
		]
	},

	getConditionComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_PRODUCT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_FORMULARY,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_LIST,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_VENDOR,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_MANUFACTURER,
			enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_CHART_OF_ACCOUNT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_OFF_CATALOG,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_CATALOG,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_BUDGET,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR_ORDER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_MANUFACTURER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CHART_OF_ACCOUNT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_CHART_OF_ACCOUNT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_TYPE_ATF,
		]
	},

	getPurchasedConditionComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_PRODUCT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_FORMULARY,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_LIST,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_VENDOR,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_MANUFACTURER,
			enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_CHART_OF_ACCOUNT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_OFF_CATALOG,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_CATALOG,
		]
	},

	getBudgetConditionComponentTypes: (): number[] => {
		return [enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_BUDGET]
	},

	getQuantityConditionComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR_ORDER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_MANUFACTURER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CHART_OF_ACCOUNT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG,
		]
	},

	getSpendConditionComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_CHART_OF_ACCOUNT,
		]
	},

	getPriceConditionComponentTypes: (): number[] => {
		return [enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT]
	},

	getActionComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY,
			enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL,
			enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY_USER,
			enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL_USER,
		]
	},

	getVisibleActionComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY,
			enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL,
		]
	},

	getAccountingCodeComponentTypes: (): number[] => {
		return [
			enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_CHART_OF_ACCOUNT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CHART_OF_ACCOUNT,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_LINE_OF_BUSINESS,
			enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_CHART_OF_ACCOUNT,
		]
	},

	getTypeConditionComponentTypes: (): number[] => {
		return [enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_TYPE_ATF]
	},

	getComponentTypeCategory: (value) => {
		const actionTypes =
			enums.ORDER_RULE_COMPONENT_TYPE.getActionComponentTypes()
		const conditionTypes =
			enums.ORDER_RULE_COMPONENT_TYPE.getConditionComponentTypes()
		const whoComponentTypes =
			enums.ORDER_RULE_COMPONENT_TYPE.getWhoComponentTypes()

		if (actionTypes.includes(value)) {
			return 'order-rule-action'
		}
		if (conditionTypes.includes(value)) {
			return 'order-rule-condition'
		}
		if (whoComponentTypes.includes(value)) {
			return 'order-rule-who'
		}
	},

	getHumanReadableComponentType: getHumanReadableComponentType,
	getComponentTypeFromHumanReadable: getComponentTypeFromHumanReadable,
	componentTypeRequiresFollowOnQuestions:
		componentTypeRequiresFollowOnQuestions,
	getFollowOnQuestionsForComponentType:
		getOrderRuleComponentFollowOnQuestions,
	formValidation: {
		...OrderRuleFormValidators,
	},
	getTags: getComponentTags,
}
