// @flow
import { useQuery } from 'src/api/index'
import Price from 'src/apollo/_entities/price'
import { GetLivePriceForProductQuery } from 'src/api/prices/get-live-price-for-product'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

export const useGetLivePrice = (product_id) => {
	const queryClient = useQueryClient()
	const { data: priceData, ...rest } = useQuery({
		key: ['live-price', { product_id }],
		request: GetLivePriceForProductQuery,
		options: {
			enabled: !!product_id,
			staleTime: 1000 * 60 * 10,
			retries: 6,
			retryDelay: (attemptIndex) =>
				Math.min(2000 * 2 ** attemptIndex, 30000),
		},
	})

	useEffect(() => {
		if (priceData?.id) {
			queryClient.invalidateQueries(['many-product-prices'])
		}
	}, [priceData?.id])

	const livePrice = priceData ? Price(priceData) : null

	return { livePrice, ...rest }
}
