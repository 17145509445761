// @flow
import { useCallback, useState } from 'react'

const useSetState = (defaultState: {}) => {
	const [state, setState] = useState(defaultState)
	const handleChange = useCallback(
		(props) => {
			setState({
				...defaultState,
				...props,
			})
		},
		[state]
	)

	return [state, handleChange]
}

export { useSetState }
