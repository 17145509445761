import { gql } from 'graphql-request'
import { UserDataFragment } from 'src/api/users/_fragments/_user_data_fragment'
import { LocationGroupUserDataFragment } from 'src/api/location_groups/_fragments/_location_group_user_data_fragment'
import { PermissionUserDataFragment } from 'src/api/permissions/_fragments/_permission_user_data_fragment'

export const GetUserListQuery = {
	identifier: 'get_user_list',
	gql: gql`
        query users($modifiers: QueryModifierInput, $organization_id: ID) {
            users(
                modifiers: $modifiers
                organization_id: $organization_id
            ) {
                data {
                    ... on User {
                        ${UserDataFragment}

                        permissions {
                            ${PermissionUserDataFragment}
                        }

                        location_groups {
                            ${LocationGroupUserDataFragment}
                        }

                        locations {
                            id
                            location {
                                id
                                name
                            }
                            permission {
                                id
                                name
                                display_name
                            }
                        }
                    }
                }
                paginatorInfo {
                    currentPage
                    lastPage
                    perPage
                    total
                }
            }
        }
    `,
	formatResults: (data) => {
		return data?.users
	},
}
