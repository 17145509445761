// @flow
import { Query, useQuery } from 'src/api/index'
import { useQueryClient } from 'react-query'
import { GetUserListQuery } from 'src/api/users/get-user-list-query'
import User from 'src/apollo/_entities/user'

export const useGetUserList = (modifiers, organization_id = undefined) => {
	const queryClient = useQueryClient()

	const { data, isLoading } = useQuery({
		key: ['users', { modifiers, organization_id }],
		request: GetUserListQuery,
		options: {
			staleTime: 1000 * 60 * 5,
		},
	})

	const getUserList = async (modifiers, organization_id = undefined) => {
		return await queryClient.fetchQuery(
			['users', { modifiers, organization_id }],
			Query(GetUserListQuery, queryClient)
		)
	}

	return {
		users: data?.data?.map((user) => User(user)) ?? [],
		paginatorInfo: data?.paginatorInfo ?? { lastPage: 1 },
		getUserList,
		isLoading,
	}
}
