// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	debit: {
		value: 1,
		name: 'Debit',
		label: 'Withdraw',
		color: '#262C32',
	},
	credit: {
		value: 2,
		name: 'Credit',
		label: 'Deposit',
		color: '#2A8D4B',
	},
})
