// @flow
import React, { useState } from 'react'
import useMutateUpsertVendor from 'src/api/vendors/useMutateUpsertVendor'
import type { VendorType } from 'src/flowtypes/Entities/VendorType'
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import ConditionalRender from 'src/components/_generic/conditional-render'
import PaddedLayout from 'src/components/_generic/padded-layout'
import LoadingView from 'src/components/_generic/loading-view'
import useAddVendor from 'src/components/OrganizationVendors/AddVendor/useAddVendor'
import EmptyFunc from 'src/library/emptyFunc'
import EmptyTableState from 'src/pages/_components/Tables/EmptyTableState'

type Props = {
	onSubmit?: (VendorType) => any,
	vendorName?: string,
	shouldAddToOrg?: boolean,
}

const VendorNotFoundCreateView = ({
	onSubmit = EmptyFunc,
	vendorName = '',
	shouldAddToOrg = false,
	organizationId = null,
}: Props) => {
	const [loading, setLoading] = useState(false)
	const { mutateAsync: upsertVendor } = useMutateUpsertVendor()
	const { addVendor } = useAddVendor(EmptyFunc)
	const { reportError, getErrorMessage } = useErrorHandler()

	const handleSubmit = async () => {
		setLoading(true)
		await upsertVendor(
			{ id: undefined, input: { name: vendorName } },
			{
				onSuccess: (data) => {
					if (shouldAddToOrg) {
						addVendor(data, organizationId)
					}
					onSubmit(data)
				},
				onError: (err) => {
					reportError(
						err,
						getErrorMessage(err) ??
							`There was a problem creating the new vendor. Please try again.`
					)
					setLoading(false)
				},
			}
		)

		setLoading(false)
	}

	return (
		<ConditionalRender
			condition={!loading}
			failedRender={
				<PaddedLayout>
					<LoadingView loading={true} />
				</PaddedLayout>
			}
		>
			<EmptyTableState
				title={'No Results Found'}
				message={
					'We didn’t find any results for this vendor. Would you like to create it?'
				}
			>
				<PrimaryButton
					title={'Create Vendor'}
					onClick={handleSubmit}
					rounded={false}
				/>
			</EmptyTableState>
		</ConditionalRender>
	)
}

export default VendorNotFoundCreateView
