import { gql } from 'graphql-request'
import { getFragmentBodies } from 'src/api'
import OrganizationEntity from 'src/apollo/_entities/organizationEntity'
import EntitiesConnections from 'src/pages/organizations/_entities/EntitiesConnections'

export default {
	getConnectedAccountingSoftware: (fragments = [], body = '') => ({
		identifier: 'organization_entity',
		gql: gql`
            query organization_entity($id: ID!, $requestedKeys: [String]!){
                organization_entity(id: $id) {
                    ${getFragmentBodies(fragments)}
                    ${body}
                }
            }
            ${fragments?.join('\n')}
        `,
		formatResults: (data) => {
			return OrganizationEntity(data?.organization_entity)
		},
	}),
	getOrganizationEntity: (fragments = [], body = '') => ({
		identifier: 'organization_entity',
		gql: gql`
            query organization_entity($id: ID!){
                organization_entity(id: $id) {
                    ${getFragmentBodies(fragments)}
                    ${body}
                }
            }
            ${fragments?.join('\n')}
        `,
		formatResults: (data) => {
			return OrganizationEntity(data?.organization_entity)
		},
	}),
	getEntitiesConnections: (fragments = [], body = '') => ({
		identifier: 'entities_connections',
		gql: gql`
            query getEntitiesConnections {
                getEntitiesConnections {
                    ${getFragmentBodies(fragments)}
                    ${body}
                }
            }
            ${fragments.join('\n')}
        `,
		formatResults: (data) => {
			return EntitiesConnections(data?.getEntitiesConnections)
		},
	}),
	addLocationsToEntity: (fragments = [], body) => ({
		identifier: 'add_locations_to_entity',
		gql: gql`
            mutation addLocationsToEntity($entity_id: ID!, $bulk_selection: BulkSelectionInput!) {
                addLocationsToEntity(organization_entity_id: $entity_id, bulk_selection: $bulk_selection) {
                    ${getFragmentBodies(fragments)}
                    ${body}
                }
            }
            ${fragments.join('\n')}
        `,
		variables: ({ entity_id, bulk_selection }) => ({
			entity_id,
			bulk_selection,
		}),
		formatResults: (data) => data?.addLocationsToEntity,
		onSuccess: (key, queryClient) => {
			queryClient.invalidateQueries(['organization_entity'])
			queryClient.invalidateQueries(['organization_entities'])
			queryClient.invalidateQueries(['get_location_list'])
			queryClient.invalidateQueries(['location'])
			queryClient.invalidateQueries(['location_group'])
			queryClient.invalidateQueries(['location_groups'])
		},
	}),
	removeLocationsFromEntity: (fragments = [], body) => ({
		identifier: 'remove_locations_from_entity',
		gql: gql`
            mutation removeLocationsFromEntity($entity_id: ID!, $bulk_selection: BulkSelectionInput!) {
                removeLocationsFromEntity(organization_entity_id: $entity_id, bulk_selection: $bulk_selection) {
                    ${getFragmentBodies(fragments)}
                    ${body}
                }
            }
            ${fragments.join('\n')}
        `,
		variables: ({ entity_id, bulk_selection }) => ({
			entity_id,
			bulk_selection,
		}),
		formatResults: (data) => data?.removeLocationsFromEntity,
		onSuccess: (key, queryClient) => {
			queryClient.invalidateQueries(['organization_entity'])
			queryClient.invalidateQueries(['organization_entities'])
			queryClient.invalidateQueries(['get_location_list'])
			queryClient.invalidateQueries(['location'])
			queryClient.invalidateQueries(['location_group'])
			queryClient.invalidateQueries(['location_groups'])
		},
	}),
}
