// @flow
import type { Node } from 'react'
import React from 'react'
import { Vertical } from 'src/components/_layouts/_layouts.styles'

type Props = {
	children?: Node,
	className?: string,
	styles?: Object,
}

// TODO: add docs around shifting to using styled-components over injecting styles
const VerticalLayout = ({ children, className = '', styles = {} }: Props) => (
	<Vertical className={className} style={styles}>
		{children}
	</Vertical>
)

export default VerticalLayout
