// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import ProductStockStatus from 'src/components/Products/ProductStatus/ProductStockStatus'
import FetchProductStockStatus from 'src/components/Products/ProductStatus/FetchProductStockStatus'

type Props = {
	product: ProductType,
	target: string,
	shorthandLabel?: boolean,
}

const ProductStockStatusSection = ({
	product,
	target,
	shorthandLabel = false,
}: Props) => {
	return (
		<ConditionalRender
			condition={!!product?.current_stock_level?.status}
			failedRender={
				<FetchProductStockStatus
					product={product}
					target={target}
					renderShorthandLabel={shorthandLabel}
				/>
			}
		>
			<ProductStockStatus
				target={target}
				status={product?.current_stock_level?.status}
				shorthandLabel={shorthandLabel}
			/>
		</ConditionalRender>
	)
}

export default ProductStockStatusSection
