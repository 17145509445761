// @flow
import { useQuery } from 'src/api/index'
import { GetMarketplaceFormulariesQuery } from 'src/api/marketplace/get-marketplace-formularies'

export const useGetMarketplaceFormularies = (id) => {
	const { data: formularies, isLoading: loading } = useQuery({
		key: ['marketplace-formularies', { id: '' + id }],
		request: GetMarketplaceFormulariesQuery,
		options: {
			enabled: !!id,
			staleTime: 1000 * 60 * 5,
		},
	})
	return { formularies, loading }
}
