// @flow

class StringFormatter {
	toLowerCaseWithUnderscores(inputString) {
		return inputString.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_')
	}

	clean(inputString = '') {
		return inputString.toLowerCase()?.trim()
	}

	replaceMany(inputString, regexes: string[], replacement = '') {
		let output = inputString
		for (let i = 0; i < regexes?.length; i++) {
			output = output.replace(new RegExp(regexes[i], 'g'), replacement)
		}
		return output
	}

	pluralizer(inputArray: Array, word, withCount = false) {
		const numItems = inputArray?.length ?? 0
		return !!withCount
			? `${numItems} ${word}${numItems === 1 ? '' : 's'}`
			: `${word}${numItems === 1 ? '' : 's'}`
	}
}

export default new StringFormatter()
