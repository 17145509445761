import type { PriceOptionType } from 'src/flowtypes/Objects/PriceOptionType'
import type { PriceType } from 'src/flowtypes/Entities/PriceType'

export default function (price: PriceType): PriceOptionType {
	return {
		key: `price-option-${price?.id}-${price?.formulary?.id}`,
		label: price?.formulary?.name,
		value: `p-${price?.product_id}${price?.id}`,
		price_id: price?.id,
		product_id: price?.product_id,
		formulary_id: price?.formulary?.id,
		owner_id: price?.formulary?.owner_id,
		price: price?.price,
		is_managed: price?.is_managed,
		is_static: price?.formulary?.is_static,
		is_live: price?.formulary?.is_live,
		is_expired: price?.is_expired,
	}
}
