// @flow
import ConfigTools from 'src/configs/ConfigTools'
import enums from 'src/configs/enums'

export default ConfigTools({
	draft: {
		value: 1,
		name: 'Draft',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
		is_unprocessed: true,
		is_manually_settable: false,
		is_visible_in_list_view: false,
	},
	unprocessed: {
		value: 2,
		name: 'Unprocessed',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
		is_unprocessed: true,
		is_manually_settable: false,
		is_visible_in_list_view: true,
	},
	new: {
		value: 3,
		label: 'New',
		name: 'New',
		color: '#535E6B',
		backgroundColor: '#ECEEF0',
		is_unprocessed: false,
		is_manually_settable: true,
		is_visible_in_list_view: true,
	},
	approved: {
		value: 4,
		label: 'Approved',
		name: 'Approved',
		color: '#005285',
		backgroundColor: '#E1F3FF',
		is_unprocessed: false,
		is_manually_settable: true,
		is_visible_in_list_view: true,
	},
	completed: {
		value: 5,
		label: 'Completed',
		name: 'Completed',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
		is_unprocessed: false,
		is_manually_settable: true,
		is_visible_in_list_view: true,
	},
	processing: {
		value: 6,
		name: 'Processing',
		color: '#005285',
		backgroundColor: '#E1F3FF',
		is_unprocessed: true,
		is_manually_settable: false,
		is_visible_in_list_view: false,
	},
	validating: {
		value: 7,
		name: 'Validating',
		color: '#005285',
		backgroundColor: '#E1F3FF',
		is_unprocessed: true,
		is_manually_settable: false,
		is_visible_in_list_view: false,
	},
	awaiting_validation: {
		value: 8,
		name: 'Awaiting Validation',
		color: '#005285',
		backgroundColor: '#E1F3FF',
		is_unprocessed: true,
		is_manually_settable: false,
		is_visible_in_list_view: false,
	},
	awaiting_manager_review: {
		value: 9,
		name: 'Processing',
		color: '#005285',
		backgroundColor: '#E1F3FF',
		is_unprocessed: true,
		is_manually_settable: false,
		is_visible_in_list_view: false,
	},
	manager_review: {
		value: 10,
		name: 'Validating',
		color: '#005285',
		backgroundColor: '#E1F3FF',
		is_unprocessed: true,
		is_manually_settable: false,
		is_visible_in_list_view: false,
	},
	voided: {
		value: 11,
		label: 'Voided',
		name: 'Voided',
		color: '#5B56A2',
		backgroundColor: '#E6E4FE',
		is_unprocessed: false,
		is_manually_settable: true,
		is_visible_in_list_view: true,
	},
	review: {
		value: 12,
		label: 'Review',
		name: 'Review',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
		is_unprocessed: false,
		is_manually_settable: true,
		is_visible_in_list_view: true,
	},
	blocked: {
		value: 13,
		label: 'Blocked',
		name: 'Blocked',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
		is_unprocessed: false,
		is_manually_settable: true,
		is_visible_in_list_view: true,
	},
	getManuallySettableStages: function () {
		return [
			enums.BILL_STATUS_TYPES.new,
			enums.BILL_STATUS_TYPES.review,
			enums.BILL_STATUS_TYPES.blocked,
			enums.BILL_STATUS_TYPES.approved,
			enums.BILL_STATUS_TYPES.completed,
			enums.BILL_STATUS_TYPES.voided,
		]
	},
	getInboxStatuses: function () {
		return [
			enums.BILL_STATUS_TYPES.draft,
			enums.BILL_STATUS_TYPES.unprocessed,
			enums.BILL_STATUS_TYPES.processing,
			enums.BILL_STATUS_TYPES.validating,
			enums.BILL_STATUS_TYPES.awaiting_validation,
			enums.BILL_STATUS_TYPES.awaiting_manager_review,
			enums.BILL_STATUS_TYPES.manager_review,
		]
	},
	isSyncableStage: function (stage) {
		return [this.approved, this.completed, this.voided]
			.map((stage) => stage.value)
			.includes(stage)
	},
})
