// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetShoppingLists } from 'src/api/shopping_lists/useGetShoppingLists'
import { useQueryModifiers } from 'src/components/Search/useQueryModifiers'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderListPills = ({ filters, setFilters }: Props) => {
	const { modifiers } = useQueryModifiers('shopping-list-filters')
	const { lists } = useGetShoppingLists({
		...modifiers,
		filters: {
			flags: [
				'my-lists',
				'organization-lists',
				'location-lists',
				'location-group-lists',
			],
		},
	})

	const handleFilterRemove = (filter) => {
		let activeShoppingListFilters = filters?.shopping_lists?.filter(
			(list) => '' + list !== '' + filter
		)
		setFilters({ ...filters, shopping_lists: activeShoppingListFilters })
	}

	let listPills = lists?.filter((list) =>
		filters?.shopping_lists?.includes(list?.id)
	)

	return listPills?.map((list) => {
		return (
			<FilterPill
				filter={list?.name}
				filterType={'LIST'}
				onRemove={() => handleFilterRemove(list?.id)}
				key={`list-badge-${list?.id}`}
			/>
		)
	})
}

export default RenderListPills
