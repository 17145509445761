// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import enums from 'src/configs/enums'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrderRulesConditionPills = ({ filters, setFilters }: Props) => {
	const orderRuleConditions = enums.ORDER_RULE_COMPONENT_TYPE.getConditionComponentTypes()

	const handleFilterRemove = (filter) => {
		let activeConditionFilters = filters?.conditions?.filter(
			(condition) => '' + condition !== '' + filter
		)
		setFilters({ ...filters, conditions: activeConditionFilters })
	}

	let conditionPills = orderRuleConditions?.filter((condition) =>
		filters?.conditions?.includes(condition.toString())
	)

	return conditionPills?.map((condition) => {
		return (
			<FilterPill
				filter={enums.ORDER_RULE_COMPONENT_TYPE.getHumanReadableComponentType(
					condition
				)}
				filterType={'CONDITION'}
				onRemove={() => handleFilterRemove(condition)}
				key={`order-rules-condition-badge-${condition}`}
			/>
		)
	})
}

export default RenderOrderRulesConditionPills
