// @flow
import React, { useEffect, useState } from 'react'

type Props = {
	alt: string,
	src: string,
	fallbackSrc: string,
	className?: string,
	style?: Object,
	onClick?: () => any,
}

const Image = (props: Props) => {
	const {
		alt,
		src,
		fallbackSrc,
		className = '',
		style = {},
		onClick,
		...rest
	} = props
	const [hasError, setHasError] = useState(false)

	useEffect(() => {
		setHasError(false)
	}, [src, fallbackSrc])

	const handleError = () => {
		setHasError(true)
	}
	const getSrc = () => (hasError ? fallbackSrc : src)

	return (
		<img
			{...rest}
			alt={alt}
			src={getSrc()}
			style={style}
			onError={handleError}
			className={className}
			onClick={onClick}
		/>
	)
}

export default Image
