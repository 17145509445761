import { gql } from 'graphql-request'

export const GetMyFormularyListQuery = {
	identifier: 'get_my_formulary_list',
	gql: gql`
		query getMyFormularies {
			my_formularies {
				id
				owner_id
				is_managed
				name
				is_static
				is_live
				product_count
			}
		}
	`,
	formatResults: (data) => {
		return data?.my_formularies ?? []
	},
}
