// @flow
import React, { useEffect, useState } from 'react'
import AsyncMultiSelect from 'src/components/_generic/forms/async-select/async-multi-select'
import type { UserType } from 'src/flowtypes/Entities/UserType'
import User from 'src/apollo/_entities/user'
import { useGetUserList } from 'src/api/users/useGetUserList'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import type { QueryModifierSetterType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierSetterType'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	organization_id?: number,
	includeAllOption?: boolean,
	filterFn?: (user: UserType) => any,
	modifiers: QueryModifierType,
	setters: QueryModifierSetterType,
	extraFilters?: Object,
}

const MultiSelectUsersDropdown = ({
	filterFn = () => true,
	modifiers,
	setters,
	organization_id = undefined,
	includeAllOption,
	extraFilters = {},
	...rest
}: Props) => {
	const [selectOptions, setSelectOptions] = useState([])

	const {
		users,
		isLoading: loading,
		getUserList,
		paginatorInfo,
	} = useGetUserList(
		{
			...modifiers,
			filters: {
				...modifiers?.filters,
				...extraFilters,
				flags: [...(extraFilters?.flags ?? []), 'active'],
			},
		},
		organization_id
	)

	const formatUser = (user: Object) => {
		return {
			value: user.id,
			label: `${user.name}`,
			...user,
		}
	}

	useEffect(() => {
		const selectAllOption = { value: 0, label: 'All Users' }
		let optionsArr

		if (selectOptions.length !== 0 && paginatorInfo?.currentPage === 1) {
			optionsArr = [...users]
			if (includeAllOption) optionsArr.unshift(selectAllOption)
		} else {
			optionsArr = [...selectOptions, ...users]
			if (
				includeAllOption &&
				!selectOptions.find((item) => item.value === 0)
			)
				optionsArr.unshift(selectAllOption)
		}

		setSelectOptions(optionsArr.map(formatUser).filter(filterFn))
	}, [paginatorInfo?.currentPage, filterFn])

	const handleLoadOptions = async (inputValue) => {
		let fetched = await getUserList(modifiers, organization_id)
		let queryData = (await fetched?.data) ?? []

		let filtered = queryData
			.map(formatUser)
			.map((userData) => User(userData))
			.filter(filterFn)
			.filter((user) =>
				user.label.toLowerCase().includes(inputValue.toLowerCase())
			)
		return Promise.resolve(filtered)
	}

	const handleInputChange = (searchVal) => {
		setters.setPage(1)
		setters.setSearchTarget(searchVal)
	}

	const onMenuScrollToBottom = () =>
		paginatorInfo.lastPage > modifiers?.page
			? setters.setPage(modifiers?.page + 1)
			: {}

	return (
		<AsyncMultiSelect
			options={selectOptions}
			loading={loading}
			closeMenuOnSelect={users?.length === 1}
			onLoadMore={handleLoadOptions}
			searchTarget={modifiers?.searchTarget}
			onInputChange={handleInputChange}
			onMenuScrollToBottom={onMenuScrollToBottom}
			required
			{...rest}
		/>
	)
}

export default withQueryModifiers(
	MultiSelectUsersDropdown,
	'multiselect-active-users'
)
