// @flow
import OrderRuleComponentType from 'src/configs/Enums/OrderRules/OrderRuleComponentType'

const getComponentTags = (componentType: number): string[] => {
	switch (componentType) {
		case OrderRuleComponentType.WHO_PURCHASER_LOCATION:
			return ['PURCHASERS', 'LOCATIONS']
		case OrderRuleComponentType.WHO_ADMIN_LOCATION:
			return ['LOCATIONS', 'ADMINS']
		case OrderRuleComponentType.WHO_LOCATION:
			return ['LOCATIONS']
		case OrderRuleComponentType.WHO_PURCHASER_GROUP:
			return ['GROUPS', 'PURCHASERS']
		case OrderRuleComponentType.WHO_ADMIN_GROUP:
			return ['GROUPS', 'ADMINS']
		case OrderRuleComponentType.WHO_GROUP:
			return ['GROUPS']
		case OrderRuleComponentType.WHO_PURCHASER_ORG:
			return ['ORGANIZATION PURCHASERS']
		case OrderRuleComponentType.WHO_PURCHASER_ALL:
			return ['PURCHASERS']
		case OrderRuleComponentType.WHO_ADMIN_ALL:
			return ['ADMINS']
		case OrderRuleComponentType.WHO_USER:
			return ['USERS']
		case OrderRuleComponentType.CONDITION_PURCHASED_PRODUCT:
		case OrderRuleComponentType.CONDITION_QUANTITY_PRODUCT:
		case OrderRuleComponentType.CONDITION_SPEND_PRODUCT:
			return ['PRODUCTS']
		case OrderRuleComponentType.CONDITION_PURCHASED_FORMULARY:
		case OrderRuleComponentType.CONDITION_QUANTITY_FORMULARY:
			return ['PRICE BOOKS']
		case OrderRuleComponentType.CONDITION_PURCHASED_LIST:
		case OrderRuleComponentType.CONDITION_QUANTITY_LIST:
			return ['SHOPPING LISTS']
		case OrderRuleComponentType.CONDITION_PURCHASED_VENDOR:
		case OrderRuleComponentType.CONDITION_QUANTITY_VENDOR:
		case OrderRuleComponentType.CONDITION_QUANTITY_VENDOR_ORDER:
		case OrderRuleComponentType.CONDITION_SPEND_VENDOR:
			return ['VENDORS']
		case OrderRuleComponentType.CONDITION_PURCHASED_MANUFACTURER:
		case OrderRuleComponentType.CONDITION_QUANTITY_MANUFACTURER:
		case OrderRuleComponentType.CONDITION_SPEND_MANUFACTURER:
			return ['MANUFACTURERS']
		case OrderRuleComponentType.CONDITION_BUDGET:
			return ['BUDGETS']
		case OrderRuleComponentType.CONDITION_QUANTITY_CATEGORY:
			return ['CATEGORIES']
		case OrderRuleComponentType.CONDITION_SPEND_ORDER:
			return ['SPEND']
		case OrderRuleComponentType.CONDITION_PRICE_PRODUCT:
			return ['PRICE', 'PRODUCTS']
		case OrderRuleComponentType.CONDITION_PURCHASED_LINE_OF_BUSINESS:
		case OrderRuleComponentType.CONDITION_QUANTITY_LINE_OF_BUSINESS:
		case OrderRuleComponentType.CONDITION_SPEND_LINE_OF_BUSINESS:
			return ['LINES OF BUSINESS']
		case OrderRuleComponentType.CONDITION_PURCHASED_CHART_OF_ACCOUNT:
		case OrderRuleComponentType.CONDITION_QUANTITY_CHART_OF_ACCOUNT:
		case OrderRuleComponentType.CONDITION_SPEND_CHART_OF_ACCOUNT:
			return ['CHART OF ACCOUNTS']
		case OrderRuleComponentType.CONDITION_PURCHASED_OFF_CATALOG:
			return ['OFF CATALOG']
		case OrderRuleComponentType.CONDITION_PURCHASED_CATALOG:
		case OrderRuleComponentType.CONDITION_QUANTITY_CATALOG:
			return ['CATALOG']
		case OrderRuleComponentType.CONDITION_TYPE_ATF:
			return ['ATF Order']
		case OrderRuleComponentType.ACTION_NOTIFY:
			return ['NOTIFY']
		case OrderRuleComponentType.ACTION_NEEDS_APPROVAL:
			return ['APPROVAL']
		default: {
			return []
		}
	}
}

export default getComponentTags
