// @flow
import { useField } from 'formik'
import React, { useEffect } from 'react'

type Props = {
	name: any,
	value: string,
}

const VirtualHiddenInput = (props: Props) => {
	const [field, , { setValue }] = useField(props)
	const serializedValue = JSON.stringify(props?.value)

	useEffect(() => {
		setValue(props?.value)
	}, [serializedValue])

	return <input type={'hidden'} name={field.name} />
}

export default VirtualHiddenInput
