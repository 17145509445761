import MoneyFormatter from 'src/library/MoneyFormatter'

const ShippingRule = (ruleData) => {
	if (!ruleData) return null

	const formatted_shipping_costs =
		ruleData?.shipping_cost !== null
			? MoneyFormatter.toDollars(ruleData?.shipping_cost)
			: ''

	const formatted_target_total =
		ruleData?.target_total !== null
			? MoneyFormatter.toDollars(ruleData?.target_total)
			: ''

	return {
		...ruleData,
		formatted_shipping_costs,
		formatted_target_total,
	}
}

export default ShippingRule
