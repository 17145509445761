// @flow
import React from 'react'
import MultiSelect from 'src/components/_generic/forms/multi-select'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import enums from 'src/configs/enums'
import { useGetAccountingCodes } from 'src/api/accounting_codes/useGetAccountingCodes'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	includeAllOption?: boolean,
	modifiers?: QueryModifierType,
}

const MultiSelectLineOfBusinessDropdown = ({
	includeAllOption,
	modifiers,
	...rest
}: Props) => {
	const { accounting_codes } = useGetAccountingCodes({
		...modifiers,
		filters: {
			...modifiers?.filters,
			accountingCodeTypes: [enums.accountingCodeType.LINE_OF_BUSINESS],
		},
	})

	const optionLists = accounting_codes.sort((a, b) =>
		a.accounting_code?.name.toLowerCase() <
		b.accounting_code?.name.toLowerCase()
			? -1
			: 1
	)

	const selectAllOption = { value: 0, label: 'All Lines of Business' }
	const options = [...optionLists]
	if (includeAllOption) options.unshift(selectAllOption)

	return (
		<MultiSelect
			options={options}
			closeMenuOnSelect={options?.length === 1}
			{...rest}
		/>
	)
}

export default withQueryModifiers(
	MultiSelectLineOfBusinessDropdown,
	null,
	[],
	500
)
