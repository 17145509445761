export default {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	SUCCESS: 'success',
	WARNING: 'warning',
	DANGER: 'danger',
	INFO: 'info',
	DARK: 'dark',
	MEDIUM: 'medium',
	LIGHT: 'light',
}
