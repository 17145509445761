// @flow
import React, { createContext, useContext } from 'react'
import { Subject } from 'rxjs'

const ChatterContext = createContext()
export const useChatterContext = () => useContext(ChatterContext)

class ChatterProvider extends React.Component {
	chatterSubject = new Subject()
	state = {
		publish: (channel, payload) => {
			return this.chatterSubject.next({ channel, payload })
		},
		subscribe: (callback) => this.chatterSubject.subscribe(callback),
	}
	render() {
		return (
			<ChatterContext.Provider value={this.state}>
				{this.props.children}
			</ChatterContext.Provider>
		)
	}
}

export default ChatterProvider
