// @flow
import { gql } from 'graphql-request'

export const UpsertVendorMutation = {
	identifier: 'upsert_vendor',
	gql: gql`
		mutation upsertVendor($id: ID, $input: UpdateVendorInput!) {
			upsertVendor(id: $id, input: $input) {
				id
				name
				image_path
				vendor_external_identifier
				shared_secret
			}
		}
	`,
	variables: ({ id, input }) => ({
		id,
		input: {
			...input,
			settings: input?.settings
				? JSON.stringify(input?.settings)
				: undefined,
		},
	}),
	formatResults: (data) => {
		return data?.upsertVendor
	},
	onSuccess: (key, queryClient, data) => {
		queryClient.invalidateQueries(['vendors'])
		queryClient.invalidateQueries(['vendor', { id: '' + data?.id }])
		queryClient.invalidateQueries([
			'vendor_settings',
			{ id: '' + data?.id },
		])
	},
}
