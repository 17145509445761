// @flow
import { useField, useFormikContext } from 'formik'
import React from 'react'
import { default as RSelect } from 'react-select'
import InputLabel from 'src/components/_generic/forms/input-label'
import ConditionalRender from 'src/components/_generic/conditional-render'
import type { IdNameType } from 'src/flowtypes/Objects/IdNameType'
import { FormErrorMessage } from 'src/pages/_styles/text.styles'

type Props = {
	options: any,
	label: string,
	required?: boolean,
	disabled?: boolean,
	loading?: boolean,
	onChange?: () => void,
	noMargin?: boolean,
	defaultValue?: any,
	hideDropdownIndicator?: boolean,
	styles?: Object,
	className?: string,
}

export const formatSelectOptions = (options: IdNameType[]) =>
	options.map((option) => ({
		...option,
		value: option.id,
		label: option.name,
	}))

const Select = (props: Props) => {
	const [field, meta] = useField(props)
	const { setFieldValue, setFieldTouched } = useFormikContext()
	const {
		options,
		label,
		required = false,
		disabled = false,
		loading = false,
		formInputClass = '',
		containerClassName,
		onChange = () => {},
		defaultValue = null,
		hideDropdownIndicator = false,
		styles = {},
		className = '',
		...rest
	} = props
	const { touched, error } = meta
	const shouldRenderError = !!(touched && error && typeof error !== 'object')

	const handleBlur = (event) => {
		if (props.onBlur) {
			props.onBlur(event)
		}
		field.onBlur(event)
		setFieldTouched(field.name)
	}

	const customStyles = {
		container: (provided) => ({
			...provided,
			backgroundColor: '#fff',
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			...(hideDropdownIndicator ? Styles.noDisplay : {}),
		}),
		menu: (provided) => ({
			...provided,
			...Styles.menuStyle,
		}),
		...styles,
	}

	return (
		<div
			className={
				typeof containerClassName !== 'undefined'
					? String(containerClassName).toString() + ' ' + className
					: `${formInputClass} ${className}`
			}
		>
			{label ? (
				<InputLabel required={required}>{label}</InputLabel>
			) : null}

			<RSelect
				className={`react-select react-select-default ${
					!!(shouldRenderError && touched && error)
						? `cm-select-has-danger`
						: ''
				}`}
				classNamePrefix='react-select'
				inputProps={{ 'aria-label': label }}
				options={options}
				name={field.name}
				isSearchable
				value={
					defaultValue
						? defaultValue
						: options
						? options.find(
								(option) => option?.value === field.value
						  )
						: ''
				}
				onChange={(option) => {
					setFieldValue && setFieldValue(field.name, option?.value)
					onChange && onChange(option)
				}}
				onBlur={handleBlur}
				onFocus={props.onFocus || null}
				isDisabled={disabled || loading}
				styles={customStyles}
				{...rest}
			/>
			<ConditionalRender condition={shouldRenderError}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</ConditionalRender>
		</div>
	)
}

export default Select

const Styles = {
	noDisplay: {
		display: 'none',
	},
	menuStyle: {
		width: '100% !important',
		maxWidth: '100% !important',
	},
}
