// @flow
import { Query, useQuery } from 'src/api/index'
import { useQueryClient } from 'react-query'
import { GetAllCatalogNames } from 'src/api/catalogs/get-all-catalog-names'

export const useGetAllCatalogNames = (organization_id) => {
	const queryClient = useQueryClient()

	const { data: allCatalogNames, isLoading } = useQuery({
		key: [
			'get_all_catalog_names',
			{ organization_id: '' + organization_id },
		],
		request: GetAllCatalogNames,
		options: {
			enabled: !!organization_id,
			staleTime: 1000 * 60 * 5,
		},
	})

	const getAllCatalogNames = async (organization_id) => {
		return await queryClient.fetchQuery(
			[
				'get_all_catalog_names',
				{ organization_id: '' + organization_id },
			],
			Query(GetAllCatalogNames, queryClient)
		)
	}

	return {
		catalogs:
			allCatalogNames?.map((catalog) => {
				return { ...catalog, label: catalog.name, value: catalog.id }
			}) ?? [],
		isLoading,
		getAllCatalogNames,
	}
}
