// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useGetMarketplaceFormularies } from 'src/api/marketplace/useGetMarketplaceFormularies'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderFormularyPills = ({ filters, setFilters }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const { formularies } = useGetMarketplaceFormularies(
		authUser?.organization?.id
	)

	const handleFilterRemove = (filter) => {
		let activeFormularyFilters = filters?.formularies?.filter(
			(formulary) => '' + formulary !== '' + filter
		)
		setFilters({ ...filters, formularies: activeFormularyFilters })
	}

	let formularyPills = formularies?.filter((formulary) =>
		filters?.formularies?.includes(formulary?.formulary?.id)
	)

	return formularyPills?.map((formulary) => {
		return (
			<FilterPill
				filter={formulary?.formulary?.name}
				filterType={'PRICE BOOKS'}
				onRemove={() => handleFilterRemove(formulary?.formulary?.id)}
				key={`formulary-badge-${formulary?.id}`}
			/>
		)
	})
}

export default RenderFormularyPills
