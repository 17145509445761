// @flow
import { useReducer } from 'react'

export const closeModal = (dispatch) => () => dispatch({ type: 'close-modal' })
export const openModal = (dispatch) => () => dispatch({ type: 'open-modal' })
export const setEntity = (dispatch) => (entity) =>
	dispatch({ type: 'set-entity', payload: { entity } })

const useEntityModalReducer = (initialState) => {
	return useReducer((state, action) => {
		switch (action.type) {
			case 'set-entity':
				return {
					...state,
					entity:
						String(state?.entity?.id) ===
						String(action.payload.entity?.id)
							? {
									...state?.entity,
									...action.payload.entity,
							  }
							: action.payload.entity,
				}
			case 'open-modal':
				return {
					...state,
					isOpen: true,
				}
			case 'close-modal':
				return {
					...state,
					isOpen: false,
				}
			default:
				return {
					...state,
				}
		}
	}, initialState)
}

export default useEntityModalReducer
