import { gql } from 'graphql-request'
import { AddressDataFragment } from 'src/api/addresses/_fragments/_address_data_fragment'
import { OrganizationDataFragment } from 'src/api/organizations/_fragments/_organization_data_fragment'
import { OrganizationTagDataFragment } from 'src/api/organizations/_fragments/_organization_tag_data_fragment'
import Organization from 'src/apollo/_entities/organization'
import { OrganizationFeatureDataFragment } from 'src/api/organization_features/_fragments/_organization_feature_data_fragments'

export const GetOrganizationQuery = {
	identifier: 'get_organization',
	gql: gql`
        query organization($id: ID, $requestedKeys: [String]!) {
            organization(id: $id) {
                ${OrganizationDataFragment}
                order_emails_disabled
                transcriptions_on
                off_catalog_status
                chart_of_accounts_count
                require_chart_of_account
                lock_bill_editing
                is_strict_three_way_match
                estimated_taxes_on
                auto_track_inventory
                price_priority
                show_price_options
                is_curemint

                tags {
                    ${OrganizationTagDataFragment}
                }

                locations {
                    id
                    name
                    contact_email
                    contact_phone
                    shipping_address {
                        ${AddressDataFragment}
                    }
                }

                location_groups {
                    id
                    name
                }

                billing_address {
                    ${AddressDataFragment}
                }

                formularies {
                    id
                    formulary {
                        id
                        owner_id
                        name
                        product_count
                    }
                    is_active
                    order
                }

                onboarding {
                    id
                    status
                    completion_video_url
                }

                tier {
                    id
                    label
                }

                features {
                    ${OrganizationFeatureDataFragment}
                }

                organization_entities {
                    id
                    name
                    code
                }

                organization_settings(requestedKeys: $requestedKeys)
                bank_customer_id
                latest_banking_application_form {
                    id
                    unit_application_form_id
                    bank_application_id
                    bank_application_status
                    created_by
                }
            }
        }
	`,
	formatResults: (data) => {
		return Organization(data?.organization)
	},
}
