// @flow

export default {
	CATEGORY: 1,
	FORMULARY: 2,
	VENDOR: 3,
	SHOPPING_LIST: 4,
	MANUFACTURER: 5,
	ProductFilterType: 1 | 2 | 3 | 4 | 5,
}
