// @flow

export default {
	unlimited: {
		value: 1,
		name: 'Unlimited',
	},
	yearly: {
		value: 2,
		name: 'Yearly',
	},
	monthly: {
		value: 3,
		name: 'Monthly',
	},
	weekly: {
		value: 4,
		name: 'Weekly',
	},
	daily: {
		value: 5,
		name: 'Daily',
	},
	allTime: {
		value: 6,
		name: 'All Time',
	},
}
