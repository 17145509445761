// @flow
import { useQuery } from 'src/api/index'
import { GetManyVendorsQuery } from 'src/api/vendors/get-many-vendors-query'

export const useGetManyVendors = (ids = []) => {
	const { data: vendors, isLoading } = useQuery({
		key: ['many_vendors', { ids: ids }],
		request: GetManyVendorsQuery,
		options: {
			enabled: ids.length !== 0,
			staleTime: 1000 * 60 * 10,
		},
	})

	return { vendors, isLoading }
}
