// @flow
import React from 'react'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import MultiSelectLocationsDropdown from 'src/components/Locations/SelectLocation/MultiSelectLocationsDropdown'

const WhoFollowOnLocations = () => {
	const { authUser } = useGetAuthenticatedUser()

	return (
		<MultiSelectLocationsDropdown
			name={'locations'}
			label={'Choose location(s)'}
			organization_id={authUser?.organization_id}
			includeAllOption={true}
		/>
	)
}

export default WhoFollowOnLocations
