import styled, { css } from 'styled-components'
import { Card, CardBody } from 'reactstrap'
import { FlexItem, FlexRow } from 'src/pages/_styles/container.styles'

/***
 * @param {boolean} [nopadding]
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const CardHeaderSection = styled.div`
	display: grid;
	grid-template-columns: 3fr minmax(200px, 1fr);
	column-gap: 8px;
	padding: 16px 20px 16px 20px;
	min-height: 60px;
	align-content: end;

	${(props) =>
		props.nopadding
			? css`
					padding: 0;
			  `
			: null}

	${(props) =>
		!!props?.hasdescription
			? css`
					& div > div.left-content,
					& div > div.right-content {
						align-self: flex-start;
					}
			  `
			: null}

  ${(props) =>
		!props?.hasactions
			? css`
					grid-template-columns: 1fr;
			  `
			: null}

  ${(props) =>
		!props.hasdescriptor
			? css`
					margin-top: -4px;
					padding-top: 0;
					padding-bottom: 4px;
			  `
			: null}
  ${(props) => {
		switch (props?.size) {
			case 'sm':
				return css`
					grid-template-columns: auto 20px;
				`
			case 'md':
			default:
				return null
		}
	}}
`

export const CardContainer = styled(Card)`
	border-radius: 10px;
`

export const CardHeaderDivider = styled.hr`
	margin: 0;
`

/***
 * @param {boolean} [nopadding]
 * @type {StyledComponent<CardBody, AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const CardContent = styled(CardBody)`
	${(props) =>
		props.nopadding
			? css`
					&.card-body {
						padding: 0;
					}
			  `
			: null}
`

export const SearchAndActionCardHeaderSection = styled(FlexRow)`
	padding: 8px 20px 8px 0;
	justify-content: space-between;
`

export const CardHeaderDescriptor = styled(FlexItem)`
	align-self: center;
	max-width: 100%;

	${(props) =>
		!props.hasdescription
			? css`
					margin-bottom: 0;
			  `
			: null}
`

export const CardHeaderActions = styled(FlexItem)`
	align-self: start;
	justify-self: end;
	text-align: right;
	flex-shrink: 3;
	flex-wrap: nowrap;
	${(props) =>
		!props.hasdescription
			? css`
					padding-top: 0;
					align-self: center;
			  `
			: null}
`

export const CardTab = styled.div`
	min-height: 100%;
`
