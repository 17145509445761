//@flow
import styled, { css } from 'styled-components'
import { Breadcrumb } from 'reactstrap'
import { ComponentType } from 'react'

export const FlexSection = styled.section`
	display: flex;
	gap: 8px;
`

export const DropdownBackgroundWrapper = styled.span`
	background-color: var(--white);
	border-radius: 4px;
`

type FlexRowProps = {
	gap?: number,
	wrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
	width?: string,
	justifyContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'space-evenly',
	alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline',
	height?: string,
}

export const FlexRow: ComponentType<FlexRowProps> = styled.span`
	display: flex;
	gap: ${(props) => props?.gap ?? '8'}px;
	flex-wrap: ${(props) => props?.wrap ?? 'nowrap'};
	flex-direction: row;
	width: ${(props) => props?.width ?? '100%'};
	justify-content: ${(props) => props?.justifyContent ?? 'flex-start'};
	align-items: ${(props) => props?.alignItems ?? 'baseline'};
	height: ${(props) => props?.height ?? 'auto'};
`

export const PaddedFlexRow: ComponentType<FlexRowProps> = styled(FlexRow)`
	padding: 20px;
`

export const CenterFlexRow = styled(FlexRow)`
	justify-content: center;
`

type FlexItemProps = {
	minWidth?: String,
	width?: String,
	maxWidth?: String,
	flexGrow?: Number,
	flexBasis?: Number,
	alignSelf?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline',
	height?: String,
	preserveItemSize?: Boolean,
	order?: Number,
}

export const FlexItem: ComponentType<FlexItemProps> = styled.span`
	min-width: ${(props) => props?.minWidth ?? 'auto'};
	width: ${(props) => props?.width ?? 'auto'};
	max-width: ${(props) => props?.maxWidth ?? 'auto'};
	flex-grow: ${(props) => props?.flexGrow ?? 1};
	flex-shrink: ${(props) => props?.flexShrink ?? 1};
	flex-basis: ${(props) =>
		props?.flexBasis ? props.flexBasis + 'px' : 'auto'};
	line-height: 1;
	height: ${(props) => props?.height ?? 'auto'};

	${(props) =>
		props.alignSelf
			? css`
					align-self: ${props?.alignSelf};
			  `
			: null}
	${(props) =>
		props.preserveItemSize
			? css`
					flex-grow: 0;
					flex-shrink: 0;
			  `
			: null}
    ${(props) =>
		props.order
			? css`
					order: ${props.order};
			  `
			: null}
`

export const FlexRightItem = styled(FlexItem)`
	margin-left: auto;
	flex-grow: ${(props) => props.flexGrow ?? 1};
	text-align: right;
`

type FlexColumnProps = {
	gap?: Number,
	wrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
	alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline',
	justifyContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'space-evenly',
	width?: String,
}

export const FlexColumn: ComponentType<FlexColumnProps> = styled.span`
	display: flex;
	flex-direction: column;
	flex-wrap: ${(props) => props?.wrap ?? 'nowrap'}px;
	justify-content: ${(props) => props?.justifyContent ?? 'flex-start'};
	align-items: ${(props) => props?.alignItems ?? 'stretch'};
	gap: ${(props) => props?.gap ?? '8'}px;
	width: ${(props) => props?.width ?? '100%'};
	height: 100%;
`

export const HorizontalDivider = styled.hr`
	margin: 0 0;
	border-color: #dadee3;
`

const FlexContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
`

export const EmptySection = styled(FlexContainer)`
	justify-content: center;
	flex-direction: column;
	gap: 16px;
	padding: 20px 50px;
	background-color: #ffffff;
	width: 100%;
`

export const ClickContainer = styled.div`
	display: contents;
	cursor: zoom-in;
`

export const LinkButtonBreadcrumb = styled(Breadcrumb)`
	> .breadcrumb {
		padding-top: 0;
		padding-bottom: 0;
	}
`

export const FiltersAndTableContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
`

export const FilterSidebarContainer = styled.div`
	min-width: 150px;
	max-width: 350px;
	border-right: 1px solid #f0f0f0;
	transition: all 0.5s ease-in;
`

export const FilterableTableContainer = styled.div`
	flex: 1;
	min-height: 100%;
	overflow: hidden;
`

export const VirtualizedContainer = styled.div`
	width: 100%;
	position: relative;
	height: ${({ height }) => height ?? '100px'};
`

export const VirtualizedItem = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: ${({ height }) => height ?? '100px'};
	transform: ${({ transform }) => transform ?? '100px'};
`

export const CMTableWrapper = styled.div`
	> div > div > div:first-child {
		padding-left: 20px;
	}

	> div > div > div:last-child {
		padding-right: 20px;
	}

	${(props) => {
		if (!!props?.withHeaders) {
			return css`
				> div > div > div {
					padding-top: 12px;
					padding-bottom: 12px;
				}
			`
		}
	}}
`

export const PrimaryWrapper = styled.span`
	color: var(--primary);
`

export const Border = styled.div`
    ${({ variant = 'all', weight = '1px' }) => {
		switch (variant) {
			case 'all':
				return css`
					border: ${weight} solid #f0f0f0;
				`

			case 'left':
				return css`
					border-left: ${weight} solid #f0f0f0;
				`

			case 'right':
				return css`
					border-right: ${weight} solid #f0f0f0;
				`

			case 'top':
				return css`
					border-top: ${weight} solid #f0f0f0;
				`
			case 'bottom':
				return css`
					border-bottom: ${weight} solid #f0f0f0;
				`
			case 'horizontal':
				return css`
					border-left: ${weight} solid #f0f0f0;
					border-right: ${weight} solid #f0f0f0;
				`
			case 'vertical':
				return css`
					border-top: ${weight} solid #f0f0f0;
					border-bottom: ${weight} solid #f0f0f0;
				`
			default:
				break
		}
	}}
}
`

export const Padding = styled.div`
    ${({ variant = 'all', weight = '16px' }) => {
		switch (variant) {
			case 'all':
				return css`
					padding: ${weight};
				`
			case 'left':
				return css`
					padding-left: ${weight};
				`

			case 'right':
				return css`
					padding-right: ${weight};
				`

			case 'top':
				return css`
					padding-top: ${weight};
				`
			case 'bottom':
				return css`
					padding-bottom: ${weight};
				`
			case 'horizontal':
				return css`
					padding-left: ${weight};
					padding-right: ${weight};
				`
			case 'vertical':
				return css`
					padding-top: ${weight};
					padding-bottom: ${weight};
				`
			default:
				return css`
					padding: ${variant};
				`
		}
	}}
}`

export const EqualWidthColumnGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
	grid-gap: 20px;
	align-content: space-evenly;
	align-items: center;
`
