import { gql } from 'graphql-request'

export const GetAccountingCodesQuery = {
	identifier: 'get_accounting_codes_query',
	gql: gql`
		query getAccountingCodes($modifiers: QueryModifierInput) {
			me {
				id
				organization {
					id
					accounting_codes(modifiers: $modifiers) {
						data {
							... on AccountingCode {
								id
								name
								description
								type
								active_count
								location_count
							}
						}
						paginatorInfo {
							currentPage
							lastPage
							perPage
							total
						}
					}
				}
			}
		}
	`,
	formatResults: (data) => {
		return data?.me?.organization?.accounting_codes || []
	},
}
