import styled from 'styled-components'

export const AlertBannerSection = styled.div`
	margin-bottom: ${(props) => `${props?.hasBreadcrumbs ? '-12' : '0'}px`};
`

export const BreadcrumbSection = styled.div`
	margin-bottom: -20px;
`

export const BadgeSection = styled.div`
	margin-top: 4px;
`

export const PageTitle = styled.h1`
	font-weight: 600;
	font-size: 44px;
	line-height: 55px;
	color: #000000;
`

export const PageActionsSection = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 0;
	margin: 0;

	& > :last-child {
		margin-right: inherit;
	}
`

export const PageAction = styled.span`
	margin: auto 8px;
`
