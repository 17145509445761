// @flow

import { useQueryClient } from 'react-query'
import { Query, useQuery } from 'src/api/index'
import { GetManufacturersByPopularityQuery } from 'src/api/manufacturers/get-manufacturers-by-popularity-query'

export const useGetManufacturersByPopularity = (
	page = 1,
	searchTarget = ''
) => {
	const queryClient = useQueryClient()

	const { data, isLoading } = useQuery({
		key: ['manufacturers_by_popularity', { page, searchTarget }],
		request: GetManufacturersByPopularityQuery,
		options: {
			staleTime: 1000 * 60 * 60,
		},
	})

	const getManufacturersByPopularity = async (
		page = 1,
		searchTarget = ''
	) => {
		return await queryClient.fetchQuery(
			['manufacturers_by_popularity', { page, searchTarget }],
			Query(GetManufacturersByPopularityQuery, queryClient)
		)
	}

	return {
		manufacturers: data?.manufacturers ?? [],
		paginatorInfo: data?.paginatorInfo ?? { lastPage: 1 },
		isLoading,
		getManufacturersByPopularity,
	}
}
