import React from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
	ErrorStatusIcon,
	SuccessStatusIcon,
} from 'src/components/AccountingSoftware/AccountingSoftware.styles'
import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	DEFAULT: {
		name: 'default',
		title: '',
		color: '#6C757D',
		icon: null,
	},
	INFO: {
		name: 'info',
		title: '',
		color: '#0071B8',
		icon: null,
	},
	SUCCESS: {
		name: 'success',
		title: 'Success',
		color: 'var(--success)',
		icon: <SuccessStatusIcon icon={solid('circle-check')} />,
	},
	ERROR: {
		name: 'error',
		title: 'Error',
		color: 'var(--danger)',
		icon: <ErrorStatusIcon icon={solid('diamond-exclamation')} />,
	},
})
