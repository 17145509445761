// @flow
import { useQuery } from 'src/api/index'
import { GetMyFormularyListQuery } from 'src/api/formularies/get-my-formulary-list-query'
import Formulary from 'src/apollo/_entities/formulary'

export const useGetMyFormularyList = () => {
	const { data: formularyList, isLoading } = useQuery({
		key: ['formulary-lists'],
		request: GetMyFormularyListQuery,
		options: {
			staleTime: 1000 * 60 * 10,
		},
	})

	const findById = (formularyId) => {
		return formularies?.find(
			({ formulary }) => String(formulary?.id) === String(formularyId)
		)
	}

	const searchFormularies = () => {
		return formularies
	}

	const formularies = formularyList?.map((formulary) => Formulary(formulary))

	return { formularies, findById, isLoading, searchFormularies }
}
