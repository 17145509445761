// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	deposit_product: {
		checking: {
			value: 1,
			label: 'Cash',
		},
		savings: {
			value: 2,
			label: 'Cash',
		},
	},
	status: ConfigTools({
		open: {
			value: 'Open',
			label: 'Active',
			color: '#2A8D4B',
		},
		frozen: {
			value: 'Frozen',
			label: 'Frozen',
			color: '#E7AE1B',
		},
		closed: {
			value: 'Closed',
			label: 'Closed',
			color: '#697888',
		},
	}),
})
