// @flow
import React, { useState } from 'react'
import { useGetShoppingLists } from 'src/api/shopping_lists/useGetShoppingLists'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'
import { useUpsertShoppingList } from 'src/api/shopping_lists/useUpsertShoppingList'
import { useField } from 'formik'
import ShoppingList from 'src/api/shopping_lists/_entities/ShoppingList'
import useListCanEdit from 'src/components/Lists/useListCanEdit'
import { useShowToast } from 'src/_boot/ToastContext'
import useCumulativeList from 'src/hooks/useCumulativeList'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import AsyncMultiSelect from 'src/components/_generic/forms/async-select/async-multi-select'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	includeAllOption?: boolean,
	excludeIds?: number[],
	modifiers?: QueryModifierType,
	setters?: QueryModifierType,
}
const MultiSelectListDropdown = ({
	includeAllOption,
	excludeIds = [],
	name,
	modifiers,
	setters,
	...rest
}: Props) => {
	const [inputValue, setInputValue] = useState('')
	const [{ value }, , { setValue }] = useField(name)

	const { authUser } = useGetAuthenticatedUser()
	const { showSuccessToast } = useShowToast()
	const { reportError } = useErrorHandler()
	const {
		lists: unformatted,
		isLoading,
		paginatorInfo,
		getShoppingLists,
	} = useGetShoppingLists({
		...modifiers,
		filters: {
			flags: [
				'my-lists',
				'organization-lists',
				'location-lists',
				'location-group-lists',
			],
		},
	})
	const { mutate: upsertShoppingList } = useUpsertShoppingList()
	const { getEditableLists } = useListCanEdit()

	const handleSubmit = (name) => {
		upsertShoppingList(
			{
				input: {
					name,
					is_editable: false,
					owner_type: 'user',
					owner_id: authUser?.id,
				},
			},
			{
				onSuccess: (newList) => {
					setValue([...value, ShoppingList(newList)])
					setInputValue('')

					return showSuccessToast(
						`Successfully created a new list! - ${name}`
					)
				},
				onError: (err) =>
					reportError(err, 'There was a problem creating this list!'),
			}
		)
	}

	const { cumulativeList } = useCumulativeList(unformatted)
	const editableLists = getEditableLists(cumulativeList)
	const lists = editableLists.map((list) => ShoppingList(list)) ?? []
	const filteredList = lists?.filter((list) => {
		return !excludeIds.includes(list.id)
	})

	if (includeAllOption) {
		filteredList.unshift({ value: 0, label: 'All Lists' })
	}

	const handleLoadOptions = async (inputValue) => {
		let fetched = await getShoppingLists({
			...modifiers,
			filters: {
				flags: [
					'my-lists',
					'organization-lists',
					'location-lists',
					'location-group-lists',
				],
			},
			searchTarget: inputValue,
		})
		let queryData =
			(await fetched?.data?.map((list) => ShoppingList(list)))?.filter(
				(list) => {
					return !excludeIds.includes(list.id)
				}
			) ?? []

		let filtered = queryData.filter((list) =>
			list.name.toLowerCase().includes(inputValue.toLowerCase())
		)
		return Promise.resolve(filtered)
	}

	const onMenuScrollToBottom = () =>
		paginatorInfo.lastPage > modifiers?.page ? setters.nextPage() : {}

	const noOptionsMessage = () => {
		return (
			<ConditionalRender
				condition={filteredList?.length === 0 && lists?.length === 0}
				failedRender={
					<div>
						<p>
							No more lists!
							<br />
							<Button
								style={styles.buttonStyle}
								onClick={() => handleSubmit(inputValue)}
							>
								CREATE LIST
							</Button>
						</p>
					</div>
				}
			>
				<div>
					<p>You haven't created any lists yet!</p>
					<p>
						Add your{' '}
						<Link to='/procure/store/lists'>first list</Link>.
					</p>
				</div>
			</ConditionalRender>
		)
	}

	return (
		<AsyncMultiSelect
			options={filteredList}
			loading={isLoading}
			name={name}
			onLoadMore={handleLoadOptions}
			searchTarget={modifiers.searchTarget}
			inputValue={inputValue}
			onInputChange={(newValue) => {
				setters.setSearchTarget(newValue)
				setInputValue(newValue)
			}}
			onMenuScrollToBottom={onMenuScrollToBottom}
			noOptionsMessage={() => noOptionsMessage()}
			{...rest}
		/>
	)
}

export default withQueryModifiers(MultiSelectListDropdown)

const styles = {
	buttonStyle: {
		background: 'transparent',
		border: 'none',
		color: '#19c6f7',
		padding: 0,
		paddingBottom: 2,
		paddingLeft: 2,
	},
}
