// @flow
import { useQuery } from 'src/api/index'
import { GetManyManufacturersQuery } from 'src/api/manufacturers/get-many-manufacturers-query'

export const useGetManyManufacturers = (ids = []) => {
	const { data: manufacturers, isLoading } = useQuery({
		key: ['many_manufacturers', { ids: ids?.map((id) => '' + id) }],
		request: GetManyManufacturersQuery,
		options: {
			enabled: !!ids,
			staleTime: 1000 * 60 * 60,
		},
	})

	return { manufacturers, isLoading }
}
