// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import SkeletonText from 'src/components/_generic/skeletons/skeleton-text'

type Props = {
	isLoading: boolean,
}

const PriceLoadingSkeleton = ({ isLoading }: Props) => (
	<ConditionalRender condition={isLoading}>
		<div className='d-flex justify-content-between cm-m-normal'>
			<SkeletonText width={200} height={40} isLoading={isLoading} />
			<SkeletonText width={100} height={40} isLoading={isLoading} />
		</div>
		<div className='d-flex justify-content-between cm-m-normal'>
			<SkeletonText width={200} height={40} isLoading={isLoading} />
			<SkeletonText width={100} height={40} isLoading={isLoading} />
		</div>
	</ConditionalRender>
)

export default PriceLoadingSkeleton
