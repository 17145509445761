import { gql } from 'graphql-request'

export const GetLocationListQuery = {
	identifier: 'get_locations',
	gql: gql`
		query locations($modifiers: QueryModifierInput) {
			locations(modifiers: $modifiers) {
				data {
					... on Location {
						id
						name
						users_count
						mtd_spend
						location_code

						shipping_address {
							id
							address1
							address2
							city
							state
							postal_code
							country
						}

						accounting_mappables {
							id
							accounting_category_id
							organization_entity_id
							mappable_id
							mappable_type
						}

						organization_entities_count
						organization_entities {
							id
							organization_entity_id
						}

						lines_of_business {
							id
							accounting_code_id
						}
						contact_email
						contact_phone
					}
				}
				paginatorInfo {
					currentPage
					lastPage
					perPage
					total
				}
			}
		}
	`,
	formatResults: (data) => {
		return data?.locations
	},
}
