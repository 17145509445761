// @flow
import React from 'react'
import NumberIncrementInput from 'src/components/_generic/forms/number-increment-input/number-increment-input'
import { useFormikContext } from 'formik'

type Props = {
	label?: string,
	menuPortalTarget?: string,
	height?: number,
}

const QuickshopQuantity = ({ label = 'Qty', height = 30 }: Props) => {
	const { values, setFieldValue } = useFormikContext()

	return (
		<NumberIncrementInput
			name={`quantity`}
			label={label}
			value={values?.quantity}
			defaultValue={values?.quantity}
			height={height}
			onChange={(e) => {
				const newValue = e?.target?.value
				setFieldValue('quantity', newValue >= 1 ? newValue : 1)
			}}
			onDecrement={() => {
				const newValue = Number(values.quantity) - 1
				setFieldValue('quantity', newValue >= 1 ? newValue : 1)
			}}
			onIncrement={() => {
				setFieldValue('quantity', Number(values.quantity) + 1)
			}}
		/>
	)
}

export default QuickshopQuantity
