// @flow
import { useQuery } from 'src/api/index'
import { GetManyOrganizationsQuery } from 'src/api/organizations/get-many-organizations-query'

export const useGetManyOrganizations = (ids = []) => {
	const { data: organizations, isLoading } = useQuery({
		key: ['many_organizations', { ids: '' + ids }],
		request: GetManyOrganizationsQuery,
		options: {
			enabled: !!ids,
			staleTime: 1000 * 60 * 10,
		},
	})

	return { organizations, isLoading }
}
