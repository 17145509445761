import { useGetPricesForProduct } from 'src/api/prices/useGetPricesForProduct'
import mapPriceToOption from 'src/components/Products/Prices/_methods/mapPriceToOption'
import { useGetMyFormularyList } from 'src/api/formularies/useGetMyFormularyList'
import type { PriceOptionType } from 'src/flowtypes/Objects/PriceOptionType'
import mapPriceBookToOption from 'src/components/Products/Prices/_methods/mapPriceBookToOption'
import { useGetLivePrice } from 'src/api/prices/useGetLivePrice'
import { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

const usePriceOptions = (product) => {
	const {
		loaded_prices,
		managed_prices,
		isLoading: pricesLoading,
	} = useGetPricesForProduct(product?.id)
	const { livePricing } = useFlags()

	const { formularies: price_books, isLoading: priceBooksIsLoading } =
		useGetMyFormularyList()
	const priceBooksMissingPrices =
		price_books?.filter((price_book) => {
			let found = false

			for (let i = 0; i < loaded_prices?.length; i++) {
				if (loaded_prices[i].formulary?.id === price_book?.id) {
					found = true
				}
			}

			for (let i = 0; i < managed_prices?.length; i++) {
				if (managed_prices[i].formulary?.id === price_book?.id) {
					found = true
				}
			}

			if (price_book?.is_live) {
				found = true
			}

			return !found
		}) ?? []

	const { livePrice, isLoading: livePriceIsLoading } = useGetLivePrice(
		livePricing && product?.vendor?.has_live_prices ? product?.id : null
	)
	const livePrices =
		livePrice || (livePricing && product?.vendor?.has_live_prices)
			? [livePrice]
			: []

	const isLoading = useMemo(
		() => pricesLoading || priceBooksIsLoading,
		[pricesLoading, priceBooksIsLoading]
	)

	const options = [
		...livePrices?.map((option) => ({
			...mapPriceToOption(option),
			is_loading: livePriceIsLoading,
			is_live: true,
		})),
		...loaded_prices?.map(mapPriceToOption),
		...managed_prices?.map(mapPriceToOption),
		...priceBooksMissingPrices?.map((price_book): PriceOptionType =>
			mapPriceBookToOption(price_book, product)
		),
	]

	return {
		options,
		isLoading,
	}
}

export default usePriceOptions
