// @flow
import React, { useContext } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import { StrongText } from 'src/pages/_styles/text.styles'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'
import NumberedList from 'src/components/_generic/lists/NumberedList'

const SageIntacctFlowLoginStep = () => {
	const { nextStep, setSubmit } = useContext(
		ConnectAccountingSoftwareFlowContext
	)

	useComponentDidMount(() => {
		setSubmit(() => () => nextStep())
	})

	const sageLink = (
		<a
			style={Styles.underline}
			href='https://www.intacct.com/ia/acct/login.phtml'
			target='_blank'
			rel='noreferrer'
		>
			link
		</a>
	)

	const listItems = [
		{
			value: (
				<p>
					If you do not already have Sage Intacct open, click this{' '}
					{sageLink} to open up Sage Intacct in a new tab.
				</p>
			),
		},
		{
			value: (
				<p>
					Please make sure you are an <StrongText>Admin</StrongText>{' '}
					user as admin privileges will be needed for the following
					steps.
				</p>
			),
		},
		{
			value: (
				<p>
					Once you have <StrongText>logged in</StrongText> to Sage
					Intacct on a new browser tab, continue to the next step.
				</p>
			),
		},
	]

	return (
		<ConnectAccountingSoftwareStepLayout title={'Login To Sage Intacct'}>
			<p>
				You will need to be logged into Sage Intacct to setup the
				integration between CureMint and Sage Intacct.
			</p>
			<NumberedList listItems={listItems} />
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctFlowLoginStep

const Styles = {
	underline: { textDecoration: 'underline' },
}
