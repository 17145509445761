// @flow
import React from 'react'
import { useGetCategoriesList } from 'src/api/categories/useGetCategoriesList'
import Select from 'src/components/_generic/forms/select'

type Props = {
	handleSelectCategory?: (categoryId: Object) => void,
	parentId?: number | null,
	label: string,
	disabled?: boolean,
	required?: boolean,
}

export const CategoryDropdown = (props: Props) => {
	const { handleSelectCategory = () => {}, parentId = null } = props

	const { data: categories, isLoading } = useGetCategoriesList(parentId)

	return (
		<Select
			options={categories}
			loading={isLoading}
			{...props}
			onChange={({ value }) => handleSelectCategory(value)}
		/>
	)
}
