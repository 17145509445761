import DateEntity from 'src/apollo/_entities/date'
import Budget from 'src/apollo/_entities/Budgets/budget'

const BudgetAllotment = (allotmentData) => {
	if (!allotmentData) return null

	return {
		...allotmentData,
		budget: Budget(allotmentData?.budget),
		start_date: DateEntity(allotmentData?.start_date),
		end_date: DateEntity(allotmentData?.end_date),
	}
}

export default BudgetAllotment
