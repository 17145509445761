// @flow
import { Query, useQuery } from 'src/api/index'
import { useQueryClient } from 'react-query'
import { GetAuthenticatedUserQuery } from 'src/api/users/get-authenticated-user-query'
import { useEffect } from 'react'
import axios from 'axios'
import AppConfigs from 'src/configs/app'

export const useGetAuthenticatedUser = () => {
	const queryClient = useQueryClient()

	const {
		data: authUser,
		isLoading,
		error,
		...rest
	} = useQuery({
		key: ['me'],
		request: GetAuthenticatedUserQuery,
		options: {
			staleTime: 1000 * 60 * 5,
		},
	})

	const getAuthUser = async () => {
		return await queryClient.fetchQuery(
			['me'],
			Query(GetAuthenticatedUserQuery, queryClient)
		)
	}

	useEffect(() => {
		if (error) {
			axios.defaults.withCredentials = true
			axios.post(`${AppConfigs.serverUrl}/logout`)
		}
	}, [JSON.stringify(authUser), isLoading])

	return { authUser, getAuthUser, isLoading, ...rest }
}
