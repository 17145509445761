import MoneyFormatter from 'src/library/MoneyFormatter'

export default {
	importFields: [
		{
			label: 'Vendor SKU',
			dataKey: 'vendor_sku',
			required: true,
			format: 'text, e.g. SKU003271',
			formatForAPI: (value) => '' + value,
		},
		{
			label: 'Price',
			dataKey: 'price',
			required: true,
			format: 'number indicating the price of the product, e.g. 85.00',
			formatForAPI: (value) => MoneyFormatter.toPennies(value),
		},
	],
}
