// @flow
import React, { createContext, useMemo } from 'react'
import { gql } from 'graphql-request'
import usePrivatePusherChannel from 'src/pages/_hooks/usePrivatePusherChannel'
import { useQueryClient } from 'react-query'
import type { OrganizationEntityProviderProps } from 'src/pages/entities/_contexts/OrganizationEntityInfoContext'
import { useQuery } from 'src/api'
import api from 'src/pages/entities/_apis/entities-api'

export const AccountingSoftwareContext = createContext()

export const AccountingSoftwareProvider = ({
	children,
	organizationEntityId,
}: OrganizationEntityProviderProps) => {
	const queryClient = useQueryClient()
	const { data: accountingSoftwareSettings, isLoading } = useQuery({
		key: [
			'organization_entity',
			{
				id: '' + organizationEntityId,
				requestedKeys: ['accounting_software_settings'],
			},
			'accounting_software_settings',
		],
		request: api.getConnectedAccountingSoftware([
			AccountingSoftwareProvider.fragments.AccountingSoftware,
		]),
		options: {
			enabled: !!organizationEntityId,
			staleTime: 1000 * 60 * 10,
		},
	})

	const entityState = useMemo(
		() => ({ ...accountingSoftwareSettings, isLoading }),
		[JSON.stringify(accountingSoftwareSettings), isLoading]
	)

	usePrivatePusherChannel(
		`organization_entity.accounting_software.${organizationEntityId}`,
		{
			'organization_entity.accounting_software': () => {
				queryClient.invalidateQueries(['organization_entity'])
				queryClient.invalidateQueries(['get_accounting_software_list'])
			},
		}
	)

	return (
		<AccountingSoftwareContext.Provider value={entityState}>
			{children}
		</AccountingSoftwareContext.Provider>
	)
}

AccountingSoftwareProvider.fragments = {
	AccountingSoftware: gql`
		fragment AccountingSoftware on OrganizationEntity {
			id
			code
			name
			settings(requestedKeys: $requestedKeys)
			autoSyncEnabled
		}
	`,
}
