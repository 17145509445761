// @flow
import React from 'react'
import type { FilterSelectionType } from 'src/flowtypes/Objects/Filters/FilterSelectionType'
import RenderSingleOrgLocationPills from 'src/components/EntityFilters/FilterPillComponents/Locations/RenderSingleOrgLocationPills'
import RenderAdminLocationPills from 'src/components/EntityFilters/FilterPillComponents/Locations/RenderAdminLocationPills'
import RenderOrderRulesActionPills from 'src/components/EntityFilters/FilterPillComponents/OrderRules/RenderOrderRulesActionPills'
import RenderOrderRulesConditionPills from 'src/components/EntityFilters/FilterPillComponents/OrderRules/RenderOrderRulesConditionPills'
import RenderOrderRulesWhoTypePills from 'src/components/EntityFilters/FilterPillComponents/OrderRules/RenderOrderRulesWhoTypePills'
import RenderOrderDatesPills from 'src/components/EntityFilters/FilterPillComponents/Orders/RenderOrderDatesPills'
import RenderOrderItemIssuesPills from 'src/components/EntityFilters/FilterPillComponents/Orders/RenderOrderItemIssuesPills'
import RenderOrderStatusPills from 'src/components/EntityFilters/FilterPillComponents/Orders/RenderOrderStatusPills'
import RenderTimeInStatusPills from 'src/components/EntityFilters/FilterPillComponents/Orders/RenderTimeInStatusPills'
import RenderCategoryPills from 'src/components/EntityFilters/FilterPillComponents/Store/RenderCategoryPills'
import RenderFavoritesPills from 'src/components/EntityFilters/FilterPillComponents/Store/RenderFavoritesPills'
import RenderListPills from 'src/components/EntityFilters/FilterPillComponents/Store/RenderListPills'
import RenderFormularyPills from 'src/components/EntityFilters/FilterPillComponents/Store/RenderFormularyPills'
import RenderManufacturerPills from 'src/components/EntityFilters/FilterPillComponents/Store/RenderManufacturerPills'
import RenderAdminVendorPills from 'src/components/EntityFilters/FilterPillComponents/Vendors/RenderAdminVendorPills'
import RenderOrganizationActiveVendorsPills from 'src/components/EntityFilters/FilterPillComponents/Vendors/RenderOrganizationActiveVendorsPills'
import RenderOrganizationPills from 'src/components/EntityFilters/FilterPillComponents/RenderOrganizationPills'
import RenderVendorPills from 'src/components/EntityFilters/FilterPillComponents/Vendors/RenderVendorPills'
import RenderBillMatchStatusPills from 'src/components/EntityFilters/FilterPillComponents/Bills/RenderBillMatchStatusPills'

type Props = FilterSelectionType

const FilterPillSection = ({ filters, setFilters }: Props) => {
	const renderFilterPills = (filters) => {
		let renderedFilters = []

		if (filters?.billMatchStatus) {
			renderedFilters.push(
				<RenderBillMatchStatusPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.adminLocations) {
			renderedFilters.push(
				<RenderAdminLocationPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.singleOrgLocations) {
			renderedFilters.push(
				<RenderSingleOrgLocationPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.whos) {
			renderedFilters.push(
				<RenderOrderRulesWhoTypePills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.conditions) {
			renderedFilters.push(
				<RenderOrderRulesConditionPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.actions) {
			renderedFilters.push(
				<RenderOrderRulesActionPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.organizationActiveVendors) {
			renderedFilters.push(
				<RenderOrganizationActiveVendorsPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.orderDates) {
			renderedFilters.push(
				<RenderOrderDatesPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.orderStatus) {
			renderedFilters.push(
				<RenderOrderStatusPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.orderItemIssues) {
			renderedFilters.push(
				<RenderOrderItemIssuesPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.timeInStatus) {
			renderedFilters.push(
				<RenderTimeInStatusPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.categories) {
			renderedFilters.push(
				<RenderCategoryPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.shopping_lists) {
			renderedFilters.push(
				<RenderFavoritesPills
					filters={filters}
					setFilters={setFilters}
				/>,
				<RenderListPills filters={filters} setFilters={setFilters} />
			)
		}

		if (filters?.formularies) {
			renderedFilters.push(
				<RenderFormularyPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.manufacturers) {
			renderedFilters.push(
				<RenderManufacturerPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.adminVendors) {
			renderedFilters.push(
				<RenderAdminVendorPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		if (filters?.vendors) {
			renderedFilters.push(
				<RenderVendorPills filters={filters} setFilters={setFilters} />
			)
		}

		if (filters?.organizations) {
			renderedFilters.push(
				<RenderOrganizationPills
					filters={filters}
					setFilters={setFilters}
				/>
			)
		}

		return renderedFilters ?? null
	}

	return renderFilterPills(filters)
}

export default FilterPillSection
