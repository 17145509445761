// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import enums from 'src/configs/enums'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrderItemIssuesPills = ({ filters, setFilters }: Props) => {
	const orderItemIssues = enums.ORDER_ITEM_STATUS.getOrderItemIssueStatuses()

	const handleFilterRemove = (filter) => {
		let orderItemIssuesFilters = filters?.orderItemIssues?.filter(
			(issue) => '' + issue !== '' + filter
		)
		setFilters({
			...filters,
			orderItemIssues: orderItemIssuesFilters,
		})
	}

	let orderItemIssuesPills = orderItemIssues?.filter((issue) => {
		return filters?.orderItemIssues?.includes(issue?.toString())
	})

	return orderItemIssuesPills?.map((issue) => {
		return (
			<FilterPill
				filter={enums.ORDER_ITEM_STATUS.getHumanReadable(issue)}
				filterType={'ISSUE'}
				onRemove={() => handleFilterRemove(issue)}
				key={`issue-badge-${issue}`}
			/>
		)
	})
}

export default RenderOrderItemIssuesPills
