// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import SkeletonText from 'src/components/_generic/skeletons/skeleton-text'
import SettingsRow from 'src/components/_generic/settings/settings-row'

type Props = {
	isLoading: boolean,
	children?: Node,
	card?: boolean,
}

const SkeletonSettingsCard = ({ isLoading, children, card }: Props) => {
	return (
		<ConditionalRender condition={isLoading} failedRender={children}>
			<SettingsRow
				leftContent={
					<SkeletonText
						width={card ? 40 : 125}
						isLoading={true}
						circle={card}
					/>
				}
				rightContent={<SkeletonText width={250} isLoading={true} />}
			/>
			<SettingsRow
				leftContent={<SkeletonText width={125} isLoading={true} />}
				rightContent={<SkeletonText width={250} isLoading={true} />}
			/>
			<SettingsRow
				leftContent={<SkeletonText width={125} isLoading={true} />}
				rightContent={<SkeletonText width={250} isLoading={true} />}
			/>
		</ConditionalRender>
	)
}

export default SkeletonSettingsCard
