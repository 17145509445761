// @flow

export default {
	COMPLETED: {
		value: 0,
		name: 'completed',
		label: 'Synced',
	},
	ERROR: {
		value: 1,
		name: 'errored',
		label: 'Sync Error',
	},
	SYNCING: {
		value: 2,
		name: 'syncing',
		label: 'Syncing',
	},
	WARNING: {
		value: 3,
		name: 'data_changed',
		label: 'Out of Sync',
	},
}
