import * as Yup from 'yup'

export default {
	accounting_software: {
		sage_intacct: Yup.object().shape({
			company_id: Yup.string()
				.required('Please enter a Company ID')
				.nullable(),
			user_id: Yup.string().required('Please enter a User ID').nullable(),
			password: Yup.string()
				.required('Please enter a password')
				.nullable(),
		}),
	},
}
