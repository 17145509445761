// @flow
import React, { useContext } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import Validators from 'src/configs/ValidationModels/accounting_software'
import { Formik } from 'formik'
import useExternalFormSubmit from 'src/hooks/useExternalFormSubmit'
import { StrongText } from 'src/pages/_styles/text.styles'
import Spacer from 'src/components/_generic/spacer'
import { FlexItem } from 'src/pages/_styles/container.styles'
import PageBanner from 'src/components/_generic/banners/PageBanner'
import RadioGroup from 'src/components/_generic/forms/radio-group'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'

const SageIntacctFlowEntityStep = () => {
	const { nextStep, setSubmit } = useContext(
		ConnectAccountingSoftwareFlowContext
	)
	const { formRef } = useExternalFormSubmit()

	const handleSubmit = () => {
		nextStep()
	}

	useComponentDidMount(() => {
		setSubmit(() => () => handleSubmit())
	})

	return (
		<ConnectAccountingSoftwareStepLayout title={'Entity Selection'}>
			<p>
				Select at which level you want to connect to Sage Intacct. You
				can choose to connect to the top level or target a specific
				entity.
			</p>
			<Spacer />
			<FlexItem alignSelf={'center'}>
				<PageBanner bannerType={'info'} clearable>
					<p>
						<StrongText>Tip:</StrongText> 98% of CureMint customers
						connect to the Top Level.
					</p>
				</PageBanner>
			</FlexItem>
			<Spacer />
			<FlexItem alignSelf={'center'}>
				<Formik
					validationSchema={
						Validators.accounting_software.sage_intacct
					}
					validateOnBlur
					onSubmit={handleSubmit}
					initialValues={{
						entity_id: null,
						level: 'true',
					}}
					innerRef={formRef}
				>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<RadioGroup
								name={'level'}
								label={'Connection Level'}
								options={[
									{
										label: 'Top Level',
										subtitleText:
											'Access data from all entities.',
										value: 'true',
									},
									{
										label: 'Single Entity',
										subtitleText:
											'Access data from an individual entity.',
										value: 'false',
										disabled: true,
										disabledTooltip: '',
									},
								]}
								isBorderedFill
								hideRadioInput
							/>
						</form>
					)}
				</Formik>
			</FlexItem>
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctFlowEntityStep
