// @flow
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useGetOrgCatalogsByProduct } from 'src/api/catalogs/useGetOrgCatalogsByProduct'

const useUserCanAccessProduct = (productId) => {
	const { authUser } = useGetAuthenticatedUser()
	const { catalogs: productCatalogs, isLoading } = useGetOrgCatalogsByProduct(
		authUser.organization.id,
		productId
	)

	const hasAccess =
		authUser.hasFullCatalogAccess ||
		authUser.catalogs
			?.map((userCatalog) => userCatalog?.id)
			?.filter((userCatalogId) =>
				productCatalogs
					?.map((prodCatalog) => prodCatalog?.id)
					?.includes(userCatalogId)
			).length > 0

	return {
		hasAccess,
		isLoading,
	}
}

export default useUserCanAccessProduct
