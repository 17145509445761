// @flow
import { gql } from 'graphql-request'
import { OrganizationsVendorsDataFragment } from 'src/api/organization_vendors/_fragments/_organizations_vendors_data_fragment'

export const UpsertOrganizationVendorMutation = {
	identifier: 'upsert_organization_vendor',
	gql: gql`
		mutation upsertOrganizationVendor(
			$organization_id: ID!
			$vendor_id: ID!
			$input: UpsertOrganizationVendorInput
		) {
			upsertOrganizationVendor(
				organization_id: $organization_id
				vendor_id: $vendor_id
				input: $input
			) {
				${OrganizationsVendorsDataFragment}
			}
		}
	`,
	variables: ({ organization_id, vendor_id, input }) => ({
		organization_id,
		vendor_id,
		input: {
			...input,
			secret_configs: input?.secret_configs
				? JSON.stringify(input?.secret_configs)
				: undefined,
		},
	}),
	formatResults: (data) => {
		return data?.upsertOrganizationVendor ?? null
	},
	onSuccess: (key, queryClient, data, variables) => {
		queryClient.invalidateQueries([
			'vendor',
			{ id: '' + variables?.vendor_id },
		])
		queryClient.invalidateQueries(['vendors'])
		queryClient.invalidateQueries(['organizations_vendors'])
		queryClient.invalidateQueries(['product_list'])
		queryClient.invalidateQueries(['product'])
	},
}
