// @flow
import React from 'react'
import Image from 'src/components/_generic/image'

type Props = {
	alt: string,
	src: string,
	fallbackSrc: string,
	caption: string,
	imageStyle?: Object,
}

const CaptionedImage = ({
	alt,
	src,
	fallbackSrc,
	caption,
	imageStyle = {},
}: Props) => {
	return (
		<div>
			<p style={Styles.imageCaption}>{caption}</p>
			<Image
				alt={alt}
				style={{
					...Styles.image,
					...imageStyle,
				}}
				src={src}
				fallbackSrc={fallbackSrc}
			/>
		</div>
	)
}

export default CaptionedImage

const Styles = {
	image: {
		borderBottomLeftRadius: '12px',
		borderBottomRightRadius: '12px',
		border: '1px solid #DADEE3',
	},
	imageCaption: {
		fontSize: '10pt',
		padding: '4px 12px',
		backgroundColor: '#F7F8F9',
		borderTopLeftRadius: '12px',
		borderTopRightRadius: '12px',
		border: '1px solid #DADEE3',
		borderBottom: 'none',
		margin: '0px',
	},
}
