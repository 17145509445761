// @flow

const Catalog = (catalogData) => {
	if (!catalogData) return null

	const hasProducts = catalogData?.catalog_products_count === 0

	const needsSetup =
		catalogData?.organization_id && !catalogData?.is_global && hasProducts

	return {
		...catalogData,
		value: catalogData?.id,
		label: `${catalogData?.name}`,
		hasProducts,
		needsSetup,
	}
}

export default Catalog
