// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import {
	ToastCard,
	ToastCloseButton,
	ToastIconSection,
	ToastLeftBorder,
	ToastMessageText,
	ToastSection,
	ToastTextSection,
	ToastTitle,
} from 'src/components/_generic/toasts/toast.styles'
import enums from 'src/configs/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

type Props = {
	message?: string | Node,
	title?: string,
	type?: 'default' | 'info' | 'success' | 'error',
	icon?: Node,
	onClose?: () => {},
}

export const ToastMessage = ({
	message = null,
	title = null,
	type = 'default',
	icon = null,
	onClose,
}: Props) => {
	const enumType = enums.toastTypes.findByName(type)
	const typeIcon = icon ?? enumType.icon
	const typeTitle = title ?? enumType.title

	return (
		<ToastCard>
			<ToastLeftBorder color={enumType.color} />
			<ToastSection>
				<ConditionalRender condition={!!typeIcon}>
					<ToastIconSection>{typeIcon}</ToastIconSection>
				</ConditionalRender>
				<ToastTextSection>
					<ConditionalRender condition={!!typeTitle}>
						<ToastTitle>{typeTitle}</ToastTitle>
					</ConditionalRender>
					<ConditionalRender condition={!!message}>
						<ToastMessageText>{message}</ToastMessageText>
					</ConditionalRender>
				</ToastTextSection>
				<ToastCloseButton
					className='close'
					type='button'
					onClick={onClose}
				>
					<FontAwesomeIcon icon={solid('xmark')} />
				</ToastCloseButton>
			</ToastSection>
		</ToastCard>
	)
}
