// @flow
import React from 'react'
import ConfigTools from 'src/configs/ConfigTools'
import SageIntacctFlowLoginStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowLoginStep'
import SageIntacctFlowSubscriptionsStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowSubscriptionsStep'
import SageIntacctFlowWebServicesStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowWebServicesStep'
import SageIntacctFlowUserStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowUserStep'
import SageIntacctFlowCredentialsStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowCredentialsStep'
import SageIntacctFlowConnectStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowConnectStep'
import SageIntacctFlowEntityStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowEntityStep'
import SageIntacctFlowSyncStep from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/SageIntacctFlow/SageIntacctFlowSyncStep'

export default ConfigTools({
	LOGIN: {
		value: 0,
		label: 'Login',
		showBackButton: true,
		submitText: 'Next',
		backText: 'Cancel',
		render: <SageIntacctFlowLoginStep />,
	},
	SUBSCRIPTIONS: {
		value: 1,
		label: 'Subscriptions',
		showBackButton: true,
		submitText: 'Next',
		backText: 'Back',
		render: <SageIntacctFlowSubscriptionsStep />,
	},
	WEB_SERVICES: {
		value: 2,
		label: 'Web Services',
		showBackButton: true,
		submitText: 'Next',
		backText: 'Back',
		render: <SageIntacctFlowWebServicesStep />,
	},
	USER: {
		value: 3,
		label: 'User',
		showBackButton: true,
		submitText: 'Next',
		backText: 'Back',
		render: <SageIntacctFlowUserStep />,
	},
	CREDENTIALS: {
		value: 4,
		label: 'Credentials',
		showBackButton: true,
		submitText: 'Next',
		backText: 'Back',
		render: <SageIntacctFlowCredentialsStep />,
	},
	CONNECT: {
		value: 5,
		label: 'Connect',
		showBackButton: true,
		submitText: 'Submit',
		backText: 'Back',
		render: <SageIntacctFlowConnectStep />,
	},
	ENTITY: {
		value: null,
		label: 'Entity',
		showBackButton: true,
		submitText: 'Submit',
		backText: 'Back',
		render: <SageIntacctFlowEntityStep />,
	},
	SYNC: {
		value: 6,
		label: 'Sync',
		showBackButton: false,
		submitText: 'Finish',
		backText: 'Back',
		render: <SageIntacctFlowSyncStep />,
	},
})
