// @flow
import EmptyFunc from 'src/library/emptyFunc'

export const staticDefaults = {
	children: 'Loading...',
	size: 'md',
	open: false,
	center: true,
	title: '',
	fillHeight: false,
	overflowVisible: false,
	hideScrollbars: false,
	horizontalTitle: false,
	onClose: EmptyFunc,
	onScrollBottom: EmptyFunc,
}
