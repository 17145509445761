// @flow

const Feature = (featureData) => {
	if (!featureData) return []

	return {
		id: featureData?.id,
		value: featureData?.id,
		label: featureData?.label,
		...featureData,
	}
}

export default Feature
