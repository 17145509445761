// @flow
import React from 'react'
import AsyncSelect from 'src/components/_generic/forms/async-select/async-select'
import { components } from 'react-select'
import VerticalLayout from 'src/components/_layouts/vertical-layout'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import enums from 'src/configs/enums'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import useCumulativeList from 'src/hooks/useCumulativeList'
import type { QueryModifierRenderType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierRenderType'
import type { QueryModifierSetterType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierSetterType'
import { useDebounce } from 'use-debounce'
import { SEARCH_DEBOUNCE_MS } from 'src/configs/app'
import { useGetAccountingCodes } from 'src/api/accounting_codes/useGetAccountingCodes'
import type { AccountingCodeType } from 'src/flowtypes/Entities/AccountingCodeType'
import AccountingCode from 'src/apollo/_entities/accountingCode'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	extraFilters?: Object,
	accountingCodes?: AccountingCodeType[],
	modifiers?: QueryModifierType,
	renders?: QueryModifierRenderType,
	setters?: QueryModifierSetterType,
	codeType?: number,
	expectName?: boolean,
	otherOptions?: [Object],
}

const SelectAccountingCodeDropdown = ({
	modifiers,
	setters,
	accountingCodes,
	extraFilters = {},
	codeType = enums.accountingCodeType.CHART_OF_ACCOUNT,
	expectName = false,
	otherOptions = [],
	...rest
}: Props) => {
	const [target] = useDebounce(modifiers.searchTarget, SEARCH_DEBOUNCE_MS)
	const accountingCodeFilters = {
		...modifiers?.filters,
		...extraFilters,
		accountingCodeTypes: [codeType],
	}

	const { accounting_codes, isLoading, paginatorInfo, getAccountingCodes } =
		useGetAccountingCodes({
			...modifiers,
			filters: accountingCodeFilters,
			searchTarget: target,
		})
	const { cumulativeList } = useCumulativeList(accounting_codes)

	const formatAccountingCode = (code: Object) => {
		return {
			...code,
			value: expectName ? `${code.name}` : code.id,
		}
	}

	const Option = (props) => {
		return (
			<components.Option {...props}>
				<VerticalLayout>
					<div className={'text-left'}>{props?.data?.label}</div>
				</VerticalLayout>
			</components.Option>
		)
	}

	const handleLoadOptions = async (inputValue) => {
		let fetched = await getAccountingCodes({
			...modifiers,
			page: 1,
			searchTarget: inputValue,
			filters: accountingCodeFilters,
		})
		let queryData = (await fetched.data) || []

		let filtered = queryData
			.map((accounting_code) =>
				formatAccountingCode(AccountingCode(accounting_code))
			)
			.filter((accounting_code) =>
				accounting_code.label
					.toLowerCase()
					.includes(inputValue.toLowerCase())
			)
		return Promise.resolve(filtered)
	}
	const onMenuScrollToBottom = () =>
		paginatorInfo.lastPage > modifiers?.page ? setters?.nextPage() : {}

	return (
		<AsyncSelect
			options={[
				...otherOptions,
				...(accountingCodes ?? cumulativeList)?.map(
					formatAccountingCode
				),
			]}
			loading={isLoading}
			searchTarget={modifiers?.searchTarget}
			onLoadMore={handleLoadOptions}
			onInputChange={setters?.setSearchTarget}
			components={{ Option }}
			onMenuScrollToBottom={onMenuScrollToBottom}
			styles={Styles}
			minMenuWidth={300}
			{...rest}
		/>
	)
}

// setting results to 1000 per page to overcome
// issue with the inner workings of the Terrible AsyncSelect component specifically surrounding the onLoadMore
// property.
export default withQueryModifiers(SelectAccountingCodeDropdown, null, [], 1000)

const Styles = {
	pStyle: { alignSelf: 'unset' },
}
