import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import PricePriorities from 'src/configs/Enums/PricePriorities'
import sortLivePriceFirst from 'src/components/Products/Prices/_methods/sortLivePriceFirst'
import sortLowestPriceFirst from 'src/components/Products/Prices/_methods/sortLowestPriceFirst'

const useSortPriceOptions = (options) => {
	const { authUser } = useGetAuthenticatedUser()
	if (authUser?.organization?.price_priority === PricePriorities.live.value) {
		return options?.sort(sortLivePriceFirst)
	} else {
		return options.sort(sortLowestPriceFirst)
	}
}

export default useSortPriceOptions
