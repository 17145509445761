// @flow
import { useMutation } from 'src/api/index'
import { UpsertVendorMutation } from 'src/api/vendors/upsert-vendor-mutation'

const useMutateUpsertVendor = () => {
	return useMutation({
		key: ['upsert_vendor'],
		request: UpsertVendorMutation,
	})
}

export default useMutateUpsertVendor
