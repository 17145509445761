// @flow
import React, { useRef } from 'react'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import MultiSelectUsersDropdown from 'src/components/Users/SelectUser/MultiSelectUsersDropdown'

const WhoFollowOnUsers = () => {
	const { authUser } = useGetAuthenticatedUser()

	const filterFn = useRef((user) =>
		user?.value === 0 ? true : !user?.isOrganizationAdmin()
	)

	return (
		<MultiSelectUsersDropdown
			name={'users'}
			label={'Choose user(s)'}
			organization_id={authUser?.organization_id}
			includeAllOption={true}
			filterFn={filterFn.current}
		/>
	)
}

export default WhoFollowOnUsers
