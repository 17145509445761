import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FlexSection } from 'src/pages/_styles/container.styles'

export const BannerBox = styled(FlexSection)`
	background: ${(props) => props?.bgColor};
	border: 1px solid ${(props) => props?.bordColor};
	border-radius: 6px;
	padding: 0.5rem;
	font-size: 14px;
	margin-bottom: ${(props) => (props?.noMargin ? '0px' : '16px')};
	min-height: 48px;
`

export const BannerIcon = styled(FontAwesomeIcon)`
	padding: 0 0 0 0.25rem;
	font-size: 16px;
	color: ${(props) => props?.iconColor};
	animation-duration: 500ms;
`

export const BannerTitle = styled.span`
	font-weight: 600;
	color: #000;
	margin-right: 4px;
`
