// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import enums from 'src/configs/enums'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrderRulesActionPills = ({ filters, setFilters }: Props) => {
	const orderRuleActions = enums.ORDER_RULE_COMPONENT_TYPE.getActionComponentTypes()

	const handleFilterRemove = (filter) => {
		let activeActionFilters = filters?.actions?.filter(
			(action) => '' + action !== '' + filter
		)
		setFilters({ ...filters, actions: activeActionFilters })
	}

	let actionPills = orderRuleActions?.filter((action) =>
		filters?.actions?.includes(action.toString())
	)

	return actionPills?.map((action) => {
		return (
			<FilterPill
				filter={enums.ORDER_RULE_COMPONENT_TYPE.getHumanReadableComponentType(
					action
				)}
				filterType={'ACTION'}
				onRemove={() => handleFilterRemove(action)}
				key={`order-rules-action-badge-${action}`}
			/>
		)
	})
}

export default RenderOrderRulesActionPills
