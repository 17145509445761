const OrganizationVendorContact = (contactData) => {
	if (!contactData) return null

	const isValidCreate = () => {
		return contactData?.name && contactData?.email && contactData?.phone
	}

	return {
		id: contactData?.id ?? undefined,
		name: contactData?.name ?? '',
		email: contactData?.email ?? '',
		phone: contactData?.phone ?? '',
		isValidCreate,
	}
}

export default OrganizationVendorContact
