import styled from 'styled-components'

export const ToastCollector = styled.div`
	position: fixed;
	width: 300px;
	z-index: 2000;
	bottom: 18px;
	left: 18px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export const ToastContainer = styled.div`
	margin-bottom: 8px;
	flex-grow: 1;
	width: 100%;
`
