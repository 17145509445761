// @flow
import type { Node } from 'react'
import React from 'react'
import { Table as RSTable } from 'reactstrap'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { handleVariableType } from 'src/components/_generic/utils'
import { default as SkeletonText } from 'src/components/_generic/skeletons/skeleton-text'

export type SkeletonTableProps = {
	dataSource: Array<Object>,
	renderHeaderRow?: () => any | Node,
	renderItem: (Object) => any | Node,
	renderEmpty?: () => any | Node,
	isLoading: boolean,
	loadingRows?: number[],
	className?: string,
}

export const SkeletonLoadingTable = ({
	index,
	loadingRows = [],
}: {
	index: number,
	loadingRows: number[],
}) => {
	return (
		<tr
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				padding: `${
					!index ? 'var(--spacing-half)' : 'var(--spacing-normal)'
				} var(--spacing-double)`,
			}}
		>
			<td
				style={{
					justifyContent: 'space-between',
					alignItems: 'center',
					display: 'flex',
					flex: 1,
				}}
			>
				{[
					...loadingRows,
					loadingRows.length,
					loadingRows.length + 1,
				].map((item) => (
					<SkeletonText
						isLoading
						circle={!item && !!index}
						width={!item ? 35 : 100}
						key={item}
					/>
				))}
			</td>
		</tr>
	)
}

export const SkeletonLoadingTables = ({
	loadingRows = [],
}: {
	loadingRows: number[],
}) => {
	return (
		<>
			{loadingRows.map((item) => (
				<SkeletonLoadingTable
					index={item}
					loadingRows={loadingRows}
					key={item}
				/>
			))}
		</>
	)
}

const SkeletonTable = ({
	dataSource = [],
	renderHeaderRow = () => {},
	renderItem,
	isLoading,
	renderEmpty = (isLoading) => (
		<tr>
			<td>
				<p>{isLoading ? 'Loading' : 'This list is empty.'}</p>
			</td>
		</tr>
	),
	loadingRows = [0, 1, 2, 3],
	className,
}: SkeletonTableProps) => {
	const hasData = !!dataSource?.length

	return (
		<div className={`position-relative ${className}`}>
			<RSTable>
				<thead>
					<ConditionalRender condition={hasData}>
						{handleVariableType(renderHeaderRow)}
					</ConditionalRender>
				</thead>
				<tbody>
					<ConditionalRender
						condition={hasData && !isLoading}
						failedRender={
							isLoading ? (
								<SkeletonLoadingTables
									loadingRows={loadingRows}
								/>
							) : (
								handleVariableType(renderEmpty, isLoading)
							)
						}
					>
						{(dataSource || []).map((item, index) =>
							handleVariableType(renderItem, item, index)
						)}
					</ConditionalRender>
				</tbody>
			</RSTable>
		</div>
	)
}

export { SkeletonTable }
