import { AddressDataFragment } from 'src/api/addresses/_fragments/_address_data_fragment'
import { PermissionDataFragment } from 'src/api/permissions/_fragments/_permission_data_fragment'

export const LocationDataFragment = `
    id
    name
    contact_email
    contact_phone
    users_count
    order_count
    location_code
    mtd_spend
    tax_rate {
        state
        postal_code
        est_tax_rate
    }

    users {
        id
        user {
            id
            name
        }
        permission {
            ${PermissionDataFragment}
        }
    }

    shipping_address {
        ${AddressDataFragment}
    }
`
