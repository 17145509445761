import { gql } from 'graphql-request'
import { CategoryDataFragment } from 'src/api/categories/_fragments/_category_data_fragment'
import Category from 'src/apollo/_entities/category'

export const GetCategoriesListQuery = {
	identifier: 'get_categories_list',
	gql: gql`
        query categories($parentId: ID) {
            categories(parent_id: $parentId) {
                ${CategoryDataFragment}
            }
        }
    `,
	formatResults: (data) => {
		return data?.categories
			?.sort((categoryA, categoryB) =>
				categoryA?.name < categoryB?.name ? -1 : 1
			)
			.map((category) => Category(category))
	},
}
