import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'

const useInitLaunchDarkly = () => {
	const { authUser } = useGetAuthenticatedUser()
	const ldClient = useLDClient()

	useEffect(() => {
		if (authUser && ldClient) {
			ldClient.identify({
				kind: 'user',
				key: authUser?.id,
				email: authUser?.email,
				name: authUser?.name,
				orgID: authUser?.organization?.id,
				orgName: authUser?.organization?.name,
			})
		}
	}, [authUser?.id, ldClient])
}

export default useInitLaunchDarkly
