// @flow
import React from 'react'
import { useQuickshopModal } from 'src/components/Store/MarketplaceProductList/Quickshop/withQuickshopModal'

type Props = {
	isVisible: boolean,
	product: Object,
}

const QuickshopOverlayButton = ({ isVisible, product }: Props) => {
	const { openModal, setProduct } = useQuickshopModal()

	const handleClick = () => {
		setProduct(product)
		openModal()
	}

	return (
		<div
			className={'cm-quickshop-button'}
			style={{
				visibility: isVisible ? undefined : 'hidden',
				opacity: isVisible ? 0.95 : 0,
				border: 0,
				padding: 0,
			}}
		>
			<button onClick={handleClick}>
				<p>QUICKSHOP</p>
			</button>
		</div>
	)
}

export default QuickshopOverlayButton
