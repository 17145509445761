// @flow

import AssignmentTypes from 'src/configs/Enums/AssignmentTypes'

const TranscriptionTypes = {
	NONE: 0,
	TRANSCRIPTION: 10,
	VALIDATION: 20,
	ESCALATION: 30,
	DONE: 100,

	mapTranscriptionToAssignment: (transcriptionType) => {
		switch (transcriptionType) {
			case TranscriptionTypes.TRANSCRIPTION:
				return AssignmentTypes.bill_transcriber.value
			case TranscriptionTypes.VALIDATION:
				return AssignmentTypes.bill_validator.value
			case TranscriptionTypes.ESCALATION:
				return AssignmentTypes.bill_escalation.value
			default:
				return AssignmentTypes.none.value
		}
	},

	mapTranscriptionToPermissionName: (transcriptionType) => {
		switch (transcriptionType) {
			case TranscriptionTypes.TRANSCRIPTION:
				return AssignmentTypes.bill_transcriber.permissionName
			case TranscriptionTypes.VALIDATION:
				return AssignmentTypes.bill_validator.permissionName
			case TranscriptionTypes.ESCALATION:
				return AssignmentTypes.bill_escalation.permissionName
			default:
				return AssignmentTypes.none.permissionName
		}
	},
}

export default TranscriptionTypes
