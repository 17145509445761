import React, { createContext, useContext, useReducer } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ToastCollector, ToastContainer } from 'src/_boot/container.styles'
import { ToastMessage } from 'src/components/_generic/toasts/toast-message'

export const ToastStateContext = createContext()

export const useShowToast = () => {
	const showToast = useContext(ToastStateContext)
	const showSuccessToast = (message, duration = 3000) =>
		showToast(<ToastMessage type={'success'} message={message} />, duration)
	const showErrorToast = (message, duration = 3000) =>
		showToast(<ToastMessage type={'error'} message={message} />, duration)

	return { showToast, showSuccessToast, showErrorToast }
}

export const ToastProvider = ({ children }) => {
	const [toasts, dispatch] = useReducer(ToastReducer, [])

	const addToast = (toast, duration = 3000) => {
		const key = uuidv4()
		dispatch({
			type: 'add-toast',
			payload: { toast, key },
		})
		if (duration) {
			setTimeout(() => removeToast(key), duration)
		}
	}

	const removeToast = (targetKey) => {
		dispatch({
			type: 'remove-toast',
			payload: { targetKey },
		})
	}

	return (
		<ToastStateContext.Provider value={addToast}>
			{children}
			<ToastCollector>
				{toasts?.map((child) => {
					return (
						<ToastContainer key={`toast-${child?.key}`}>
							{React.cloneElement(child?.toast, {
								...child?.toast?.props,
								onClose: () => {
									removeToast(child?.key)
								},
							})}
						</ToastContainer>
					)
				})}
			</ToastCollector>
		</ToastStateContext.Provider>
	)
}

const ToastReducer = (state, action) => {
	switch (action.type) {
		case 'add-toast':
			return [...state, action.payload]
		case 'remove-toast':
			return state?.filter(
				(toastAndKey) => toastAndKey?.key !== action.payload.targetKey
			)
		default:
			return state
	}
}
