export const AuthUserDataFragment = `
	id
	name
	image_path
	email
	phone
	bio
	is_active
	current_location_id
    current_location {
        name
        location_code
    }
    current_bank_customer {
        user_id
        activated_at
        organization_id
        association_type
    }
    last_activity
    favorite_product_ids
	locations_count
	location_groups_count
	created_at
`
