// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderFavoritesPills = ({ filters, setFilters }: Props) => {
	const handleFilterRemove = (filter) => {
		let activeShoppingListFilters = filters?.shopping_lists?.filter(
			(list) => '' + list !== '' + filter
		)
		setFilters({ ...filters, shopping_lists: activeShoppingListFilters })
	}

	let favoritePills = filters?.shopping_lists?.includes('favorites')

	return favoritePills ? (
		<FilterPill
			filter={'Favorites'}
			filterType={'LIST'}
			onRemove={() => handleFilterRemove('favorites')}
		/>
	) : null
}

export default RenderFavoritesPills
