// @flow
import { useShowToast } from 'src/_boot/ToastContext'

export default function useErrorHandler() {
	const { showErrorToast } = useShowToast()

	const reportError = (err: Error, toastMessage?: string) => {
		if (toastMessage) {
			showErrorToast(toastMessage)
		}
	}
	const getErrorMessage = (err: Error) => err?.response?.errors[0]?.message

	return { reportError, getErrorMessage }
}
