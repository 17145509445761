// @flow
import React from 'react'
import ConfigTools from 'src/configs/ConfigTools'
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
	ErrorStatusIcon,
	LoadingStatusIcon,
	NotConnectedStatusIcon,
	SuccessStatusIcon,
	WarningStatusIcon,
} from 'src/components/AccountingSoftware/AccountingSoftware.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import enums from 'src/configs/enums'
import StatusTextLabel from 'src/components/_generic/labels/status-text-label'

export default ConfigTools({
	NOT_SYNCED: {
		value: 0,
		name: 'not_synced',
		label: 'Not Synced',
		description: "This bill hasn't been synced.",
		icon: null,
		badge: (
			<StatusTextLabel
				color={'#535E6B'}
				backgroundColor={'#ECEEF0'}
				label={'Not Synced'}
			/>
		),
		reason: null,
	},
	NOT_CONNECTED: {
		value: 4,
		name: 'not_connected',
		label: 'Not Connected',
		description:
			'The entity on this bill is not connected to an accounting software.',
		icon: <NotConnectedStatusIcon icon={solid('ban')} />,
		badge: (
			<StatusTextLabel
				icon={<FontAwesomeIcon icon={solid('ban')} />}
				color={'#535E6B'}
				backgroundColor={'#ECEEF0'}
				label={'Not Connected'}
			/>
		),
		reason: 'There is no entity associated with this bill',
	},
	UNABLE_TO_SYNC: {
		value: 5,
		name: 'unable_to_sync',
		label: 'Unable To Sync',
		description: 'This bill has issues that prevent it from being synced.',
		icon: <ErrorStatusIcon icon={solid('diamond-exclamation')} />,
		badge: (
			<StatusTextLabel
				icon={<FontAwesomeIcon icon={solid('diamond-exclamation')} />}
				color={'#A73221'}
				backgroundColor={'#F9E5E2'}
				label={'Unable To Sync'}
			/>
		),
		reason: 'Unable to sync with Accounting Software',
	},
	SYNCING: {
		value: 1,
		name: 'syncing',
		label: 'Syncing',
		description: 'This bill is currently syncing.',
		icon: <LoadingStatusIcon icon={duotone('loader')} spinPulse />,
		badge: (
			<StatusTextLabel
				icon={<FontAwesomeIcon icon={solid('sync')} />}
				color={'#535E6B'}
				backgroundColor={'#ECEEF0'}
				label={'Syncing'}
			/>
		),
		reason: 'Bill is currently syncing',
	},
	OUT_OF_SYNC: {
		value: 6,
		name: 'out_of_sync',
		label: 'Out Of Sync',
		description: 'This bill has been updated since it was last synced.',
		icon: <WarningStatusIcon icon={solid('exclamation-triangle')} />,
		badge: (
			<StatusTextLabel
				icon={<FontAwesomeIcon icon={solid('warning')} />}
				color={'#262C32'}
				backgroundColor={'#F8E9C1'}
				label={'Out Of Sync'}
			/>
		),
		reason: null,
	},
	SYNC_ERRORED: {
		value: 3,
		name: 'sync_errored',
		label: 'Sync Error',
		description: 'This bill was unable to sync.',
		icon: <ErrorStatusIcon icon={solid('rotate-exclamation')} />,
		badge: (
			<StatusTextLabel
				icon={<FontAwesomeIcon icon={solid('rotate-exclamation')} />}
				color={'#A73221'}
				backgroundColor={'#F9E5E2'}
				label={'Sync Error'}
			/>
		),
		reason: null,
	},
	SYNCED: {
		value: 2,
		name: 'synced',
		label: 'Synced',
		description: 'This bill has been synced.',
		icon: <SuccessStatusIcon icon={solid('circle-check')} />,
		badge: (
			<StatusTextLabel
				icon={<FontAwesomeIcon icon={solid('circle-check')} />}
				color={'#1E6636'}
				backgroundColor={'#DFF5E7'}
				label={'Synced'}
			/>
		),
		reason: null,
	},
	getUnsyncableStatusesValues: function () {
		return [
			enums.billSyncStatuses.SYNCING.value,
			enums.billSyncStatuses.NOT_CONNECTED.value,
			enums.billSyncStatuses.UNABLE_TO_SYNC.value,
		]
	},
})
