// @flow
import React, { useEffect, useState } from 'react'
import ManagedPriceOption from 'src/components/Products/Prices/_components/Options/ManagedPriceOption'
import EnterPriceOption from 'src/components/Products/Prices/_components/Options/EnterPriceOption'
import ConditionalRender from 'src/components/_generic/conditional-render'
import LivePriceOption from 'src/components/Products/Prices/_components/Options/LivePriceOption'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import NoPriceOption from 'src/components/Products/Prices/_components/Options/NoPriceOption'
import isNullOrUndefined from 'src/library/isNullOrUndefined'
import VirtualHiddenInput from 'src/components/_generic/forms/virtual-hidden-input'
import mapPriceToOption from 'src/components/Products/Prices/_methods/mapPriceToOption'
import { PriceOptionContextProps } from 'src/components/Products/Prices/_contexts/PriceOptionContext'

const PriceSelector = ({ computed, options }: PriceOptionContextProps) => {
	const { authUser } = useGetAuthenticatedUser()
	const defaultSelection = options?.find(
		(item) => !isNullOrUndefined(item.price)
	)
	const [selection, setSelection] = useState(defaultSelection)

	const onSavePrice = (price) => {
		const newPrice = mapPriceToOption(price)
		setSelection(newPrice)
	}

	useEffect(() => {
		if (defaultSelection) {
			setSelection(defaultSelection)
		}
	}, [JSON.stringify(options)])

	return (
		<>
			{options?.map((option) => (
				<div key={option?.key}>
					<ConditionalRender condition={option?.is_live}>
						<LivePriceOption
							option={option}
							selected={selection?.value === option?.value}
							onClick={() => {
								if (!isNullOrUndefined(option?.price_id)) {
									setSelection(option)
								}
							}}
						/>
					</ConditionalRender>
					<ConditionalRender
						condition={authUser?.canEditPriceOption(option)}
					>
						<EnterPriceOption
							option={option}
							selected={selection?.value === option?.value}
							onClick={() => setSelection(option)}
							onSavePrice={onSavePrice}
						/>
					</ConditionalRender>
					<ConditionalRender
						condition={
							!option?.is_live &&
							option?.price !== null &&
							!authUser?.canEditPriceOption(option)
						}
					>
						<ManagedPriceOption
							option={option}
							selected={selection?.value === option?.value}
							onClick={() => setSelection(option)}
						/>
					</ConditionalRender>
				</div>
			))}

			<NoPriceOption
				has_price={computed?.has_price}
				isOrgAdmin={authUser?.isOrganizationAdmin()}
			/>
			<VirtualHiddenInput name={'price_selection'} value={selection} />
		</>
	)
}

export default PriceSelector
