// @flow
import React from 'react'
import { InputGroupAddon, InputGroupText } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import EmptyFunc from 'src/library/emptyFunc'
import usePreventDecimalTyping from 'src/hooks/usePreventDecimalTyping'
import MoneyFormatter from 'src/library/MoneyFormatter'
import Input from 'src/components/_generic/forms/input'

type Props = {
	label: string,
	name: string,
	required?: boolean,
	isNegative?: boolean,
	onChange?: (any) => any,
}

const DollarInput = ({
	isNegative = false,
	onChange = EmptyFunc,
	...rest
}: Props) => {
	const { setFieldValue } = useFormikContext()
	const [field] = useField(rest)
	const { preventDecimalTyping } = usePreventDecimalTyping(true)

	const handleChange = (e) => {
		let value = MoneyFormatter.convertMoneyStringToPennies(e?.target?.value)

		if (isNegative) {
			value = value < 0 ? value : value * -1
		}
		const dollarAmount = MoneyFormatter.toDollars(value)
		setFieldValue(
			rest?.name,
			isNaN(parseInt(dollarAmount)) ? '' : dollarAmount
		)
		onChange(e)
	}

	return (
		<Input
			addOn={
				<InputGroupAddon addonType='prepend'>
					<InputGroupText>
						<i className='cm-icon cm-icon-dollar-bold' />
					</InputGroupText>
				</InputGroupAddon>
			}
			value={field?.value}
			onChange={handleChange}
			onKeyDown={preventDecimalTyping}
			{...rest}
		/>
	)
}

export default DollarInput
