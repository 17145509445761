// @flow
import { Formik, useFormikContext } from 'formik'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'

export const withFormikProtection = (Component) => (props) => {
	const formik = useFormikContext()

	return (
		<ConditionalRender
			condition={!formik}
			failedRender={<Component {...props} />}
		>
			<Formik
				initialValues={{}}
				validationSchema={null}
				onSubmit={() => {}}
			>
				{(formik) => (
					<form onSubmit={formik.handleSubmit} className={'h-100'}>
						<Component {...props} />
					</form>
				)}
			</Formik>
		</ConditionalRender>
	)
}

export default withFormikProtection
