// @flow
import React from 'react'
import ConfigTools from 'src/configs/ConfigTools'
import s3Image from 'src/library/s3Image'
import Image from 'src/components/_generic/image'
import SageIntacctReconnectContent from 'src/pages/accounting_software/ReconnectAccountingSoftwarePage/_components/SageIntacctReconnectContent'
import SageIntacctFlow from 'src/configs/Enums/AccountingSoftware/SageIntacctFlow'

export default ConfigTools({
	sage_intacct: {
		value: 'sage_intacct',
		logo: (
			<Image
				alt={'Sage Intacct Logo'}
				src={s3Image('Sage_Intacct.png')}
				fallbackSrc={s3Image('logo_placeholder.jpg')}
				style={{
					height: '66px',
					margin: '-20px',
					paddingBottom: '2px',
				}}
			/>
		),
		getConnectFlow: () => SageIntacctFlow,
		reconnectPage: <SageIntacctReconnectContent />,
	},
})
