// @flow
import React from 'react'
import EmptyTableRow from 'src/components/_generic/tables/rows/empty-table-row'
import { FlexColumn, FlexItem } from 'src/pages/_styles/container.styles'
import { H3 } from 'src/pages/_styles/text.styles'
import { EmptyStateText } from 'src/pages/_components/Tables/_styles/text.styles'
import ConditionalRender from 'src/components/_generic/conditional-render'

type EmptyTableStateProps = {
	children?: Node,
	title?: string,
	message?: string,
}

const EmptyTableState = ({
	children,
	title = '',
	message = '',
}: EmptyTableStateProps) => {
	return (
		<EmptyTableRow
			message={
				<FlexColumn>
					<ConditionalRender condition={title?.length > 0}>
						<H3>{title}</H3>
					</ConditionalRender>
					<ConditionalRender condition={message?.length > 0}>
						<EmptyStateText>{message}</EmptyStateText>
					</ConditionalRender>
					<ConditionalRender condition={!!children}>
						<FlexItem alignSelf={'center'}>{children}</FlexItem>
					</ConditionalRender>
				</FlexColumn>
			}
		/>
	)
}

export default EmptyTableState
