import React, { createContext, useEffect, useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery } from 'src/api'
import api from 'src/pages/entities/_apis/entities-api'
import EntitiesConnections from 'src/pages/organizations/_entities/EntitiesConnections'
import usePrivatePusherChannel from 'src/pages/_hooks/usePrivatePusherChannel'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

export const EntityConnectionContext = createContext()

export const EntityConnectionProvider = ({ children }) => {
	const { authUser } = useGetAuthenticatedUser()
	const [connection, setConnection] = useState()

	const { data: entities } = useQuery({
		key: ['entities_connections'],
		request: api.getEntitiesConnections([
			EntityConnectionProvider.fragments.EntityConnection,
		]),
		options: {
			staleTime: 1000 * 60 * 10,
		},
	})

	useEffect(() => {
		setConnection(EntitiesConnections(entities))
	}, [entities?.organization_id])

	usePrivatePusherChannel(
		`organization_entity.accounting_software.connection_status.${authUser?.organization_id}`,
		{
			'organization_entity.accounting_software.connection_status_changed':
				(data) => {
					const newConnection = data?.connection ?? {}
					const replStatuses = connection?.statuses?.map((status) => {
						if (String(status?.id) === String(newConnection?.id)) {
							return newConnection
						} else {
							return status
						}
					})
					setConnection(
						EntitiesConnections({ ...data, statuses: replStatuses })
					)
				},
		}
	)

	return (
		<EntityConnectionContext.Provider value={connection}>
			{children}
		</EntityConnectionContext.Provider>
	)
}

EntityConnectionProvider.fragments = {
	EntityConnection: gql`
		fragment EntityConnection on EntitiesConnectionResponse {
			statuses {
				id
				name
				status
				provider_name
			}
			organization_id
			has_connection
			has_linked
			has_deauthorized
			has_multiple_entities
			has_multiple_linked
			has_linked_sage_intacct
		}
	`,
}
