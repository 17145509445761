// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetManyManufacturers } from 'src/api/manufacturers/useGetManyManufacturers'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderManufacturerPills = ({ filters, setFilters }: Props) => {
	const { manufacturers } = useGetManyManufacturers(filters?.manufacturers)

	const handleFilterRemove = (filter) => {
		let activeManufacturerFilters = filters?.manufacturers?.filter(
			(manufacturer) => '' + manufacturer !== '' + filter
		)
		setFilters({ ...filters, manufacturers: activeManufacturerFilters })
	}

	let manufacturerPills = manufacturers?.filter((manufacturer) =>
		filters?.manufacturers?.includes(manufacturer?.id)
	)

	return (
		manufacturerPills?.map((manufacturer) => {
			return (
				<FilterPill
					filter={manufacturer?.name}
					filterType={'MANUFACTURER'}
					onRemove={() => handleFilterRemove(manufacturer?.id)}
					key={`manufacturer-badge-${manufacturer?.id}`}
				/>
			)
		}) ?? null
	)
}

export default RenderManufacturerPills
