import isNullOrUndefined from 'src/library/isNullOrUndefined'

export type EmptyOptions = {
	returnType: any,
}

export type TransformOptions = {
	transform: (any) => any,
	allowEmptyStrings?: boolean,
}

export const DefaultEmptyOptions = {
	returnType: undefined,
}

export const DefaultTransformOptions = {
	transform: (a) => a,
	allowEmptyStrings: true,
}

export default (
	obj: Object,
	emptyOptions: EmptyOptions = DefaultEmptyOptions
) => {
	const keys = Object.keys(obj)

	const getKeyValue = (key: string, transformOptions = null): any => {
		const transformFunc =
			transformOptions?.transform ?? DefaultTransformOptions.transform
		const allowEmptyStrings =
			transformOptions?.allowEmptyStrings ??
			DefaultTransformOptions.allowEmptyStrings

		const hasEmptyString = allowEmptyStrings ? false : obj[key] === ''

		return keys?.includes(key) && !hasEmptyString
			? transformFunc(obj[key])
			: emptyOptions.returnType
	}

	const getValues = () => {
		var result = {}
		keys.forEach((key) => {
			result[key] = getKeyValue(key)
		})
		return result
	}

	return {
		keys,
		isEmpty: isNullOrUndefined(obj) || keys.length === 0,
		getKeyValue,
		getValues,
	}
}
