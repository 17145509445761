// @flow

import SyncStatuses from 'src/configs/Enums/AccountingSoftware/SyncStatuses'
import DateFormatter from 'src/library/DateFormatter'
import isSageMaintenance from 'src/library/AccountingSoftware/isSageMaintenance'

const OrganizationEntity = (organizationEntityData) => {
	if (!organizationEntityData) return null

	const parsedSettings = organizationEntityData?.settings
		? JSON.parse(organizationEntityData?.settings)
		: []

	const accountingSoftwareName =
		parsedSettings?.accounting_software_settings?.provider_name
	const accountingSoftwareStatus =
		parsedSettings?.accounting_software_settings?.connection_status
	const connectionMethod =
		parsedSettings?.accounting_software_settings?.connection_method
	const hasConnectedAccountingSoftware = accountingSoftwareStatus
	const linkUrl =
		parsedSettings?.accounting_software_settings?.[
			`${connectionMethod}_link_url`
		]
	const autoSync = parsedSettings?.accounting_software_settings?.auto_sync

	const getDimensionSettings = (
		dimensionName: 'chartOfAccounts' | 'suppliers' | 'trackingCategories'
	) => {
		return hasConnectedAccountingSoftware
			? parsedSettings?.accounting_software_settings?.[
					`${connectionMethod}_${dimensionName}`
			  ]
			: null
	}

	const chartOfAccountSettings = getDimensionSettings('chartOfAccounts')
	const supplierSettings = getDimensionSettings('suppliers')
	const otherCategoriesSettings = getDimensionSettings('trackingCategories')

	const accountingSoftwareDataChanged =
		hasConnectedAccountingSoftware &&
		(chartOfAccountSettings?.data_changed ||
			supplierSettings?.data_changed ||
			otherCategoriesSettings?.data_changed)

	const accountingSoftwareSyncStatus = () => {
		if (!hasConnectedAccountingSoftware) return null

		const syncStatuses = [
			chartOfAccountSettings?.sync_status,
			supplierSettings?.sync_status,
			otherCategoriesSettings?.sync_status,
		]
		if (syncStatuses?.includes(SyncStatuses.SYNCING.name))
			return SyncStatuses.SYNCING.name
		if (syncStatuses?.includes(SyncStatuses.ERROR.name))
			return SyncStatuses.ERROR.name
		return SyncStatuses.COMPLETED.name
	}

	const refreshTriggered = () => {
		if (!hasConnectedAccountingSoftware) return null

		const syncStatuses = [
			chartOfAccountSettings?.refresh_triggered,
			supplierSettings?.refresh_triggered,
			otherCategoriesSettings?.refresh_triggered,
		]

		return syncStatuses?.includes(true)
	}

	const syncedTimes = [
		chartOfAccountSettings?.last_synced_at,
		supplierSettings?.last_synced_at,
		otherCategoriesSettings?.last_synced_at,
	]

	const accountingSoftwareLastSyncedTime = hasConnectedAccountingSoftware
		? DateFormatter.humanReadableDateAndTime(
				DateFormatter.toUserTimestamp(
					new Date(
						Math.max(
							...syncedTimes.map((datetime) =>
								new Date(datetime).getTime()
							)
						)
					)
				)
		  )
		: null

	const checkSageMaintenance = () => {
		if (
			parsedSettings?.accounting_software_settings?.identifier ===
			'sage_intacct'
		) {
			return isSageMaintenance()
		}

		return false
	}

	return {
		...organizationEntityData,
		value: organizationEntityData?.id,
		label: `${
			organizationEntityData?.code
				? `${organizationEntityData?.code} `
				: ''
		}${organizationEntityData?.name}`,
		codeLabel: organizationEntityData?.name,
		settings: parsedSettings,
		hasConnectedAccountingSoftware,
		accountingSoftwareName,
		accountingSoftwareStatus,
		getDimensionSettings: (dimensionType) =>
			getDimensionSettings(dimensionType),
		accountingSoftwareDataChanged: accountingSoftwareDataChanged ?? null,
		accountingSoftwareSyncStatus: accountingSoftwareSyncStatus(),
		chartOfAccountSettings,
		supplierSettings,
		otherCategoriesSettings,
		accountingSoftwareLastSyncedTime:
			accountingSoftwareLastSyncedTime ?? null,
		refreshTriggered: refreshTriggered(),
		linkUrl: linkUrl,
		autoSync: autoSync,
		isSageMaintenance: checkSageMaintenance(),
	}
}

export default OrganizationEntity
