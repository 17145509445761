// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	none: {
		value: 0,
		label: 'None',
		permissionName: '',
	},
	bill_transcriber: {
		value: 1,
		label: 'Bill Transcription Assignment',
		permissionName: 'bill-transcriber',
	},
	bill_validator: {
		value: 2,
		label: 'Bill Validation Assignment',
		permissionName: 'bill-validator',
	},
	bill_escalation: {
		value: 3,
		label: 'Bill Validation Assignment',
		permissionName: 'bill-manager',
	},
})
