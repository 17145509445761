import isSageMaintenance from 'src/library/AccountingSoftware/isSageMaintenance'

const EntitiesConnections = (connectionData) => {
	if (!connectionData) return null

	const getConnectionInfo = (orgEntityId) => {
		return connectionData?.statuses?.filter(
			(orgEntityStatus) => orgEntityStatus?.id === orgEntityId
		)[0]
	}

	const checkSageMaintenance = (orgEntityId) => {
		const connectionInfo = getConnectionInfo(orgEntityId)

		if (connectionInfo?.provider_name === 'Sage Intacct') {
			return isSageMaintenance()
		}

		return false
	}

	return {
		...connectionData,
		getConnectionInfo,
		hasLinked: connectionData?.has_linked,
		hasMultipleLinked: connectionData?.has_multiple_linked,
		checkSageMaintenance,
	}
}

export default EntitiesConnections
