// @flow
import React, { createContext, useContext, useEffect } from 'react'
import QuickshopModal from 'src/components/Store/MarketplaceProductList/Quickshop/QuickshopModal'
import useEntityModalReducer, {
	closeModal,
	openModal,
	setEntity,
} from 'src/hooks/useEntityModalReducer'
import { useGetProduct } from 'src/api/products/useGetProduct'
import ConditionalRender from 'src/components/_generic/conditional-render'

const QuickshopContext = createContext()

export function useQuickshopModal() {
	const dispatch = useContext(QuickshopContext)

	return {
		openModal: openModal(dispatch),
		closeModal: closeModal(dispatch),
		setProduct: setEntity(dispatch),
	}
}

export const withQuickshopModal = (Component) => (props) => {
	const [state, dispatch] = useEntityModalReducer({
		entity: null,
		isOpen: false,
	})

	const {
		product: marketplaceProduct,
		isSuccess,
		isLoading,
	} = useGetProduct(state?.entity?.id)

	useEffect(() => {
		if (isSuccess) {
			setEntity(dispatch)(marketplaceProduct)
		}
	}, [isSuccess, marketplaceProduct])

	return (
		<>
			<QuickshopContext.Provider value={dispatch}>
				<Component {...props} />
				<ConditionalRender condition={state?.isOpen}>
					<QuickshopModal
						isOpen={state?.isOpen}
						isLoading={isLoading}
						product={marketplaceProduct ?? state?.entity}
					/>
				</ConditionalRender>
			</QuickshopContext.Provider>
		</>
	)
}
