// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	Unknown: {
		value: 0,
		description: 'Unknown rejection reason',
	},
	InsufficientFunds: {
		value: 1,
		description: 'Insufficient funds',
	},
	DailyACHCreditLimitExceeded: {
		value: 2,
		description: 'Daily ACH credit limit exceeded',
	},
	DailyACHDebitLimitExceeded: {
		value: 3,
		description: 'Daily ACH debit limit exceeded',
	},
	MonthlyACHCreditLimitExceeded: {
		value: 4,
		description: 'Monthly ACH credit limit exceeded',
	},
	MonthlyACHDebitLimitExceeded: {
		value: 5,
		description: 'Monthly ACH debit limit exceeded',
	},
	CounterpartyInsufficientFunds: {
		value: 6,
		description: 'Insufficient funds',
	},
	PlaidBalanceUnavailable: {
		value: 7,
		description: 'Unable to determine account balance',
	},
	PlaidLoginRequired: {
		value: 8,
		description: 'Plaid login required',
	},
	PlaidInvalidProduct: {
		value: 9,
		description: 'Plaid integration is not available. Please contact Unit.',
	},
	PlaidUnsupportedBank: {
		value: 10,
		description: 'Plaid integration is not available. Please contact Unit.',
	},
	PlaidInternalError: {
		value: 11,
		description:
			'An error occurred while establishing a connection with Plaid. Please contact Unit.',
	},
})
