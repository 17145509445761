import { OrganizationTagDataFragment } from 'src/api/organizations/_fragments/_organization_tag_data_fragment'

export const OrganizationAuthUserDataFragment = `
    id
    name
    image_path
    locations_count
    location_groups_count
    user_groups_count
    catalogs_count
    organization_entities_count
    stored_bill_email_address
    users_count
    is_curemint
    off_catalog_status
    require_chart_of_account
    lock_bill_editing
    is_strict_three_way_match
    transcriptions_on
    estimated_taxes_on
    auto_track_inventory
    price_priority
    show_price_options
    bank_customer_id
    tags {
        ${OrganizationTagDataFragment}
    }
    features {
        id
        feature_id
        is_active
        limit
        limit_type
        source
        feature {
            is_active
            feature_key
        }
    }
    tier {
        id
        label
        features {
            id
            feature_id
            is_active
            limit
            limit_type
        }
    }
    onboarding {
        id
        status
    }
    formularies {
        id
        is_active
        formulary {
            id
            name
        }
    }
    organization_entities {
        id
        code
        name
    }
    locations {
        id
        location_code
    }
`
