// @flow
import React from 'react'
import CheckboxInput from 'src/components/_generic/forms/checkbox-input'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import { useField } from 'formik'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	product: ProductType,
	checkboxStyles?: {},
	containerStyles?: {},
	isVisible?: boolean,
}

const MarketplaceProductMultiSelector = ({
	product,
	checkboxStyles = {},
	containerStyles = {},
	isVisible = true,
}: Props) => {
	const [{ value: selections }] = useField('selections')
	const isChecked = selections?.includes(product?.id) ?? false

	return (
		<ConditionalRender condition={isVisible || !!isChecked}>
			<div style={containerStyles}>
				<CheckboxInput
					name={`selections`}
					value={product?.id}
					checked={isChecked}
					style={checkboxStyles}
				/>
			</div>
		</ConditionalRender>
	)
}

export default MarketplaceProductMultiSelector
