import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

const useHidePriceOptions = (options) => {
	const { authUser } = useGetAuthenticatedUser()
	const shouldHideOptions =
		!authUser?.isOrganizationAdmin() &&
		!authUser?.organization?.show_price_options

	if (shouldHideOptions) {
		const index = options?.findIndex(
			(item) => !isNullOrUndefined(item.price)
		)

		return options.slice(0, index === -1 ? 1 : index + 1)
	}

	return options
}

export default useHidePriceOptions
