// @flow
import React from 'react'
import { StatusText } from 'src/components/_generic/labels/StatusTextLabel.styles'

type Props = {
	label: string,
	icon?: Node,
	color?: string,
	background?: string,
}

const StatusTextLabel = ({
	label,
	icon = null,
	color = 'var(--brand-text-dark)',
	backgroundColor = '#FFFFFF',
	...rest
}: Props) => {
	return (
		<StatusText color={color} backgroundColor={backgroundColor} {...rest}>
			{icon} {label}
		</StatusText>
	)
}

export default StatusTextLabel
