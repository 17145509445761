import { gql } from 'graphql-request'
import { ShoppingListDataFragment } from 'src/api/shopping_lists/_fragments/_shopping_list_data_fragment'

export const GetShoppingListsQuery = {
	identifier: 'get_shopping_lists',
	gql: gql`
        query getShoppingLists($modifiers: QueryModifierInput!) {
            shopping_lists(modifiers: $modifiers) {
                data {
                    ... on ShoppingList {
                        ${ShoppingListDataFragment}
                    }
                }
                paginatorInfo {
                    currentPage
                    lastPage
                    perPage
                    total
                }
            }
        }
    `,
	formatResults: (data) => {
		return data?.shopping_lists
	},
}
