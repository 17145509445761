import { gql } from 'graphql-request'

export const GetFavoritesQuery = {
	identifier: 'get_favorite_products',
	gql: gql`
		query me {
			me {
				favorite_product_ids
			}
		}
	`,
	formatResults: (data) => {
		return data?.me?.favorite_product_ids?.map((id) => '' + id) ?? []
	},
}
