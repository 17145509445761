// @flow

import AccountingMappable from 'src/apollo/_entities/accountingMappable'

const AccountingCode = (accountingCodeData) => {
	if (!accountingCodeData) return null

	const accountingMappables =
		(accountingCodeData?.accounting_mappables ?? [])?.map((mappable) =>
			AccountingMappable(mappable)
		) ?? []

	return {
		...accountingCodeData,
		value: accountingCodeData?.id,
		label: !!accountingCodeData?.name
			? `${accountingCodeData?.name} ${accountingCodeData?.description}`
			: accountingCodeData?.description,
		code: accountingCodeData?.name,
		codeLabel: accountingCodeData?.description,
		accounting_mappables: accountingMappables,
	}
}

export default AccountingCode
