// @flow
import React from 'react'
import type { paginatorInfoType } from 'src/flowtypes/Objects/PaginatorInfoType'
import ReactPaginate from 'react-paginate'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	paginatorInfo: paginatorInfoType,
	setPage: (number) => any,
}

const DetailedPaginator = ({
	paginatorInfo,
	setPage,
	currentPage = undefined,
}: Props) => {
	const { lastPage } = paginatorInfo ?? 1

	const handlePageClick = (data) => {
		setPage(data.selected + 1)
		//window.scrollTo(0, 0)
	}

	return (
		<ConditionalRender condition={paginatorInfo?.lastPage > 1}>
			<nav aria-label='page-navigation'>
				<ReactPaginate
					previousLabel={'<'}
					nextLabel={'>'}
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={lastPage}
					marginPagesDisplayed={1}
					pageRangeDisplayed={3}
					forcePage={
						currentPage
							? currentPage - 1
							: paginatorInfo?.currentPage - 1
					}
					onPageChange={handlePageClick}
					containerClassName={
						'pagination justify-content-center cm-mt-normal cm-mb-double'
					}
					subContainerClassName={'pages pagination'}
					activeClassName={'active'}
				/>
			</nav>
		</ConditionalRender>
	)
}

export default DetailedPaginator
