import { gql } from 'graphql-request'
import { LocationDataFragment } from 'src/api/locations/_fragments/_location_data_fragment'
import Location from 'src/apollo/_entities/location'

export const GetManyLocationsQuery = {
	identifier: 'get_many_locations',
	gql: gql`
        query getManyLocations($ids: [ID]!) {
            many_locations(ids: $ids) {
                ${LocationDataFragment}
            }
        }
	`,
	formatResults: (data) => {
		return data?.many_locations?.map((location) => Location(location)) || []
	},
}
