import { gql } from 'graphql-request'

export const GetLivePriceForProductQuery = {
	identifier: 'get_live_price',
	gql: gql`
		query getLivePrice($product_id: ID!) {
			getLivePrice(product_id: $product_id) {
				id
				price
				product_id
				unit_of_measure
				is_expired
				formulary {
					id
					name
					is_managed
					owner_id
					is_static
					is_live
				}
			}
		}
	`,
	formatResults: (data) => {
		return data?.getLivePrice
	},
}
