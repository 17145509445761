// @flow
import React from 'react'
import useRenderLayout from 'src/library/RenderLayout/useRenderLayout'
import { useFormikContext } from 'formik'
import MoneyFormatter from 'src/library/MoneyFormatter'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	children: Node,
}

const QuickshopModalLayout = ({ children }: Props) => {
	const { renderLayoutItem, hasLid } = useRenderLayout(children)
	const { values } = useFormikContext()
	const price = values?.loaded_price ?? values?.price_selection?.price

	return (
		<div>
			<ConditionalRender condition={hasLid('close-button')}>
				<div style={Styles.closeButton}>
					{renderLayoutItem('close-button')}
				</div>
			</ConditionalRender>

			<div style={Styles.container}>
				<ConditionalRender condition={hasLid('product-grid-item')}>
					<div style={Styles.productPreview}>
						{renderLayoutItem('product-grid-item', {
							displayPrice: price
								? `$${MoneyFormatter?.toDollars(price)}`
								: '-',
						})}
					</div>
				</ConditionalRender>
				{renderLayoutItem('product-details-actions')}
			</div>
		</div>
	)
}

export default QuickshopModalLayout

const Styles = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		margin: 20,
	},
	productPreview: {
		position: 'relative',
		flex: 1,
		minWidth: 325,
		margin: 20,
		minHeight: 425,
		border: '1px solid #f0f0f0',
		borderRadius: 5,
	},
	titleSku: {
		margin: '20px 20px 0 20px',
	},
	closeButton: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
}
