// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import TooltipWrapper from 'src/components/_generic/tooltips/tooltip-wrapper'
import ProductStockStatusText from 'src/components/Products/ProductStatus/ProductStockStatusText'
import enums from 'src/configs/enums'

type Props = {
	target: string,
	status: number,
	shorthandLabel?: boolean,
}

const ProductStockStatus = ({
	target,
	status,
	shorthandLabel = false,
}: Props) => {
	return (
		<ConditionalRender
			condition={shorthandLabel}
			failedRender={
				<ProductStockStatusText
					status={status}
					id={`product-stock-status-${target}`}
				/>
			}
		>
			<TooltipWrapper
				targetId={`product-stock-status-${target}`}
				tooltipContent={
					<span>
						{enums.PRODUCT_STOCK_STATUS.getHumanReadable(status)}
					</span>
				}
				placement='top'
			>
				<ProductStockStatusText
					status={status}
					id={`product-stock-status-${target}`}
					shorthandLabel={shorthandLabel}
				/>
			</TooltipWrapper>
		</ConditionalRender>
	)
}

export default ProductStockStatus
