// @flow
import { gql } from 'graphql-request'

export const GetMyOrgVendorListQuery = {
	identifier: 'get_my_org_vendor_list',
	gql: gql`
		query me {
			me {
				organization {
					id
					vendors {
						id
						vendor_id
						organization_id
						account_number
						order_email
						billing_instructions
						vendor_code
						vendor {
							id
							name
							image_path
							banner_image_path
							is_vetted
							is_catalogable
							default_order_email
							has_punchout
						}
						created_at
					}
				}
			}
		}
	`,
	formatResults: (data) => {
		return data?.me?.organization?.vendors ?? []
	},
}
