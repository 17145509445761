import type { PriceOptionType } from 'src/flowtypes/Objects/PriceOptionType'

export default function (price_book, product): PriceOptionType {
	return {
		key: `price-option-${price_book?.name}-${price_book?.id}`,
		label: price_book?.name,
		value: `f-${product?.id}${price_book?.id}`,
		price_id: null,
		product_id: product?.id,
		formulary_id: price_book?.id,
		owner_id: price_book?.owner_id,
		price: null,
		is_managed: price_book?.is_managed,
		is_static: price_book?.is_static,
		is_live: price_book?.is_live,
	}
}
