// @flow
import React from 'react'

type Props = {
	disabled?: boolean,
	children?: Node,
	style?: Object,
	disabledOpacity?: number,
	CustomElement?: string,
}

const DisabledOverlay = ({
	disabled = true,
	children,
	style = {},
	disabledOpacity = 0.4,
	CustomElement = 'div',
}: Props) => {
	return (
		<CustomElement
			style={{
				opacity: disabled ? disabledOpacity : 1,
				pointerEvents: disabled ? 'none' : undefined,
				...style,
			}}
		>
			{children}
		</CustomElement>
	)
}

export default DisabledOverlay
