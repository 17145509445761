// @flow
import { defaultAddress } from 'src/flowtypes/Entities/AddressType'

const Address = (addressData) => {
	if (!addressData) return defaultAddress

	const getSearchString = (): string => {
		return `${addressData?.address1 ?? ''}${addressData?.city ?? ''}${
			addressData?.state ?? ''
		}${addressData?.postal_code ?? ''}`
	}

	const search = (needle: string): boolean =>
		getSearchString()
			.toLowerCase()
			.includes(needle?.toLowerCase() ?? '')

	return {
		...addressData,
		value: addressData?.id,
		label: `${addressData?.address1} ${addressData?.city}, ${addressData?.state} ${addressData?.postal_code}`,
		address2: addressData.address2 || '',
		getSearchString,
		search,
	}
}

export default Address
