// @flow
export default {
	not_connected: {
		value: 0,
		name: 'Not Connected',
	},
	connected: {
		value: 1,
		name: 'Connected',
	},
	issues: {
		value: 2,
		name: 'Credential Issues',
	},
}
