// @flow
import type { Node } from 'react'
import React from 'react'

type Props = {
	children?: Node,
	className?: string,
	targetId?: string,
	styles?: Object,
	align?: 'left' | 'right' | 'spaceBetween',
}

const HorizontalLayout = ({
	children,
	className = '',
	targetId = '',
	styles = {},
	align = 'left',
}: Props) => {
	let alignment = ''
	switch (align) {
		case 'right':
			alignment = Styles.rightAligned
			break
		case 'spaceBetween':
			alignment = Styles.spaceBetween
			break
		default:
			alignment = Styles.leftAligned
			break
	}

	return (
		<div
			className={`${className}`}
			style={{ ...Styles.container, ...alignment, ...styles }}
			id={targetId}
		>
			{children}
		</div>
	)
}

export default HorizontalLayout

const Styles = {
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		alignContent: 'center',
	},
	leftAligned: {
		textAlign: 'left',
		justifyContent: 'flex-start',
		alignItems: 'center',
		alignContent: 'center',
	},
	rightAligned: {
		textAlign: 'right',
		justifyContent: 'flex-end',
		alignItems: 'center',
		alignContent: 'center',
	},
	spaceBetween: {
		justifyContent: 'space-between',
	},
}
