// @flow
import React, { useContext } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import NumberedList from 'src/components/_generic/lists/NumberedList'
import s3Image from 'src/library/s3Image'
import { StrongText } from 'src/pages/_styles/text.styles'
import CaptionedImage from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/CaptionedImage'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'

const SageIntacctFlowCredentialsStep = () => {
	const { nextStep, setSubmit } = useContext(
		ConnectAccountingSoftwareFlowContext
	)

	useComponentDidMount(() => {
		setSubmit(() => () => nextStep())
	})

	const listItems = [
		{
			value: (
				<p>
					Check the <StrongText>email inbox</StrongText> for the email
					address used to create the user in the previous step
				</p>
			),
		},
		{
			value: (
				<p>
					Look for an email with the <StrongText>subject</StrongText>{' '}
					Welcome to Sage Intacct!
				</p>
			),
		},
		{
			value: (
				<p>
					Make a note of the <StrongText>Credentials,</StrongText>{' '}
					these will be used to login on the next screen
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<CaptionedImage
					caption={'Your email should look like this'}
					alt={'Sage Intacct welcome email'}
					src={s3Image(
						'sage_intacct_auth/sage_intacct_welcome_email.png'
					)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
					imageStyle={Styles.image}
				/>
			),
		},
	]

	return (
		<ConnectAccountingSoftwareStepLayout title={'Get Credentials'}>
			<p>
				The credentials received from the web services user will be used
				to login to connect CureMint with Sage Intacct.
			</p>
			<NumberedList listItems={listItems} />
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctFlowCredentialsStep

const Styles = {
	image: {
		width: '720px',
	},
}
