// @flow
import React from 'react'
import { components } from 'react-select'
import VerticalLayout from 'src/components/_layouts/vertical-layout'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	data: {
		image_path: string,
		name: string,
		vendor: string,
		sku: string,
	},
}

const SelectProductDropdownItemSlim = (props: Props) => {
	const { data } = props

	return (
		<components.Option {...props}>
			<ConditionalRender
				condition={data?.value !== 0}
				failedRender={
					<div>
						<p style={{ fontSize: '.9em' }}>{data?.label}</p>
					</div>
				}
			>
				<VerticalLayout>
					<p style={{ fontSize: '.9em' }}>{data?.name}</p>
					<div
						className='cm-subtext-italic'
						style={{ marginTop: -5 }}
					>
						<p style={{ fontSize: '.9em' }}>{data?.vendor?.name}</p>
						<p style={{ fontSize: '.9em' }}>SKU: {data?.sku}</p>
					</div>
				</VerticalLayout>
			</ConditionalRender>
		</components.Option>
	)
}

export default SelectProductDropdownItemSlim
