import MoneyFormatter from 'src/library/MoneyFormatter'
import DateFormatter from 'src/library/DateFormatter'

const formatDate = (date) => {
	try {
		let parsedDate = date.split(/[-/]/)
		let rawYear = parseInt(
			parsedDate.length > 2 ? parsedDate[2] : parsedDate[1]
		)
		const year = rawYear.toString().length === 2 ? rawYear + 2000 : rawYear
		let month = parsedDate[0] - 1

		return DateFormatter.toTimestamp(new Date(year, month))
	} catch {
		return null
	}
}

export default {
	importFields: [
		{
			label: 'Location Name',
			dataKey: 'location_name',
			required: true,
			format: 'text, e.g. Location Name',
			formatForAPI: (value) => value,
		},
		{
			label: 'Budget Name',
			dataKey: 'budget_name',
			required: true,
			format: 'text, e.g. Monthly Budget',
			formatForAPI: (value) => value,
		},
		{
			label: 'Starts',
			dataKey: 'start_date',
			required: true,
			format: 'text, e.g. 01/2021',
			formatForAPI: (value) =>
				!!formatDate(value) ? formatDate(value).toString() : null,
		},
		{
			label: 'Budget Amount',
			dataKey: 'budget_amount',
			required: true,
			format: 'number indicating the amount of a budget, e.g. 1500.00',
			formatForAPI: (value) => MoneyFormatter.toPennies(value),
		},
	],
}
