// @flow
import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	NO_ORDER_LINK: {
		value: 1,
		name: 'No Order',
		label: 'No Order',
		description: 'This bill is not associated with a CureMint order.',
		color: '#535E6B',
		backgroundColor: '#ECEEF0',
	},
	TWO_WAY_MATCH: {
		value: 2,
		name: '2-Way',
		label: '2-Way',
		description:
			'All bill item prices and quantities match the associated order items.',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
		altColor: '#262C32',
		altBackgroundColor: '#F8E9C1',
	},
	THREE_WAY_MATCH: {
		value: 3,
		name: '3-Way',
		label: '3-Way',
		description:
			'All bill item prices and quantities match the associated order items AND all bill items have been marked received.',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
	},
	HAS_EXCEPTIONS: {
		value: 4,
		name: 'Exceptions',
		label: 'Exceptions',
		description:
			'Some bill item prices or quantities exceed the acceptable variance threshold.',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
	},
	IS_DUPLICATE: {
		value: 5,
		name: 'Duplicate',
		label: 'Duplicate',
		description: 'This bill already exists in CureMint.',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
	},
	ORDER_APPROVAL_REQUIRED: {
		value: 6,
		name: 'Order Approval',
		label: 'Order Approval',
		description:
			'This bill is associated with a CureMint order that is not approved and needs review.',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
	},
	ORDER_DENIED: {
		value: 7,
		name: 'Order Denied',
		label: 'Order Denied',
		description:
			'This bill is associated with a CureMint order that has been denied.',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
	},
})
