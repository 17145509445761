// @flow

import MoneyFormatter from 'src/library/MoneyFormatter'
import Product from 'src/apollo/_entities/product'

const Price = (priceData) => {
	if (!priceData) return null

	const userCanEdit = (user) => {
		return (
			user?.isOrganizationAdmin() &&
			String(priceData?.formulary.owner_id) ===
				String(user?.organization?.id)
		)
	}

	return {
		...priceData,
		id: priceData.id,
		value: priceData.id,
		label: `$${MoneyFormatter.toDollars(priceData.price)} - ${
			priceData?.formulary?.name
		}`,
		price: priceData.price,
		is_expired: priceData?.is_expired,
		is_managed: priceData?.formulary?.is_managed,
		is_live: priceData?.formulary?.is_live,
		product_id: priceData?.product_id,
		formularies: priceData?.formularies ?? [],
		product: Product(priceData?.product),
		formulary_id: priceData?.formulary_id,
		formulary_name: priceData?.formulary?.name ?? 'Unknown Formulary',
		cart_items: priceData?.cartItems ?? null,
		unit_of_measure: priceData?.unit_of_measure ?? null,
		userCanEdit,
		_requestdata: priceData,
	}
}

export default Price
