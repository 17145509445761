// @flow
import React from 'react'

type Props = {
	className?: string,
}

const InfoIcon = ({ className = '', ...rest }: Props) => {
	return (
		<i
			className={`cm-icon cm-icon-c-info text-info ${className}`}
			{...rest}
		/>
	)
}

export default InfoIcon
