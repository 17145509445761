// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	UNKNOWN: 0,
	COMPANY: 1,
	CONTACT: 2,
	TICKET: 3,
})
