// @flow
import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	INCOMPLETE: {
		value: false,
		name: 'Incomplete',
		label: 'Incomplete',
		description:
			'Some or all of the bill total has not been allocated to a line of business.',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
	},
	COMPLETE: {
		value: true,
		name: 'Complete',
		label: 'Complete',
		description:
			'The entire bill total has been allocated to a line of business.',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
	},
})
