// @flow
import { createElement } from 'react'
import type { Node, Element } from 'react'

export const renderElement = (
	element: Node | (() => Element | mixed),
	props?: any
) => {
	return typeof element === 'function'
		? element(props)
		: createElement(element, props)
}
