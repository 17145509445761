import MoneyFormatter from 'src/library/MoneyFormatter'

export default {
	importFields: [
		{
			label: 'Vendor SKU',
			dataKey: 'vendor_sku',
			required: true,
			format: 'text, e.g. SKU003271',
			formatForAPI: (value) => '' + value,
		},
		{
			label: 'Price',
			dataKey: 'price',
			required: false,
			format: 'number indicating the price of the product, e.g. 85.00',
			formatForAPI: (value) => MoneyFormatter.toPennies(value),
		},
		{
			label: 'Unit of Measure',
			dataKey: 'unit_of_measure',
			required: false,
			format:
				'text, indicating the unit of measure of the product, e.g. BX',
			formatForAPI: (value) => (value === undefined ? value : '' + value),
		},
	],
}
