// @flow
import React from 'react'
import { useQueryModifiers } from 'src/components/Search/useQueryModifiers'

export const withQueryModifiers = (
	Component, // these are props passed in via hoc
	hocStorageKey = null,
	hocFilterTypes = [],
	hocResultsPerPage = 10,
	hocSearchTarget = '',
	hocPage = 1
) => ({
	storageKey = null, // these are passed in via props and override the hoc inclusions
	filterTypes = null,
	resultsPerPage = null,
	page = null,
	searchTarget = null,
	...rest
}) => {
	const { setters, renders, modifiers, helpers } = useQueryModifiers(
		storageKey ?? hocStorageKey,
		filterTypes ?? hocFilterTypes,
		resultsPerPage ?? hocResultsPerPage,
		page ?? hocPage,
		searchTarget ?? hocSearchTarget
	)

	return (
		<Component
			{...rest}
			modifiers={modifiers}
			renders={renders}
			setters={setters}
			helpers={helpers}
		/>
	)
}
