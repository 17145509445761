// @flow

export default {
	ORGANIZATION: 1,
	LOCATION_GROUP: 2,
	LOCATION: 3,
	CUREMINT: 4,
	TRANSCRIPTION: 5,
	PAYABLES: 6,
}
