// @flow
import Address from 'src/apollo/_entities/Addresses/address'
import Formulary from 'src/apollo/_entities/formulary'
import OrganizationFeature from 'src/api/organization_features/_entities/OrganizationFeature'
import TierFeature from 'src/api/tier_features/_entities/TierFeature'

const Organization = (organizationData) => {
	if (!!organizationData) {
		organizationData.billing_address = Address(
			organizationData.billing_address
		)
		organizationData.shipping_address = Address(
			organizationData.shipping_address
		)
	}

	const hasMissingVendorInfo = () => missingVendorInfoCount() > 0

	const missingVendorInfoCount = () => {
		let organizationVendors = organizationData?.vendors

		return organizationVendors.filter(
			(ov) =>
				!ov?.account_number ||
				(!ov?.order_email && !ov?.vendor?.default_order_email)
		).length
	}

	const onboardingComplete = () => {
		return organizationData?.onboarding?.status === 1
	}

	const organizationFeatures =
		organizationData?.features?.map((feature) =>
			OrganizationFeature(feature)
		) ?? []

	const tierFeatures =
		organizationData?.tier?.features?.map((feature) =>
			TierFeature(feature)
		) ?? []

	const orgFeatures =
		organizationFeatures?.reduce((accum, orgFeature) => {
			const tierFeature = tierFeatures?.find(
				(tierFeature) =>
					tierFeature?.feature_id === orgFeature.feature_id
			)

			if (orgFeature?.feature?.feature_key) {
				accum[orgFeature.feature.feature_key] = {
					is_active: orgFeature?.is_active ?? tierFeature?.is_active,
					limit: orgFeature?.limit ?? tierFeature?.limit,
					limit_type:
						orgFeature?.limit_type ?? tierFeature?.limit_type,
					source: orgFeature?.source,
					isUnlimited: orgFeature?.isUnlimited,
				}
			}

			return accum
		}, {}) ?? []

	const isFeatureEnabled = (
		featureKey: string | null,
		limit?: number
	): boolean => {
		if (!featureKey) {
			return true
		}

		const feature = orgFeatures[featureKey]

		if (!!feature) {
			const checkLimit = feature?.isUnlimited()
				? Infinity
				: feature?.limit
			const checkCurrent = limit ?? 0
			if (feature?.is_active && checkLimit > checkCurrent) {
				return true
			}
		}

		return false
	}

	const hasTags = (tags: string[]) =>
		organizationData?.tags?.filter(({ tag }) =>
			tags.includes(`${tag.descriptor}:${tag.name}`)
		).length > 0

	const parsedSettings = organizationData?.organization_settings
		? JSON.parse(organizationData?.organization_settings)
		: []

	const hasBankingApplicationForm = () => {
		return !!organizationData?.latest_banking_application_form
	}

	return {
		...organizationData,
		formularies: organizationData?.formularies?.map((formulary) =>
			Formulary(formulary)
		),
		locations_count: organizationData?.locations_count,
		location_groups_count: organizationData?.location_groups_count,
		user_groups_count: organizationData?.user_groups_count,
		orgFeatures: orgFeatures,
		hasBankingApplicationForm: hasBankingApplicationForm(),
		onboardingComplete,
		hasMissingVendorInfo,
		missingVendorInfoCount,
		hasTags,
		isFeatureEnabled,
		organization_settings: parsedSettings,
	}
}

export default Organization
