// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	ach: {
		value: 1,
		name: 'ACH',
		label: 'ACH',
	},
	wire: {
		value: 2,
		name: 'Wire',
		label: 'Wire',
	},
	cheque: {
		value: 3,
		name: 'Cheque',
		label: 'Cheque',
	},
})
