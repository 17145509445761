// @flow
import styled from 'styled-components'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import { RenderFooter } from 'src/components/_generic/new-modal/footers/render-footer'

export const CancelSubmitButton = styled(PrimaryButton)`
	margin: 16px 0;
`

export const StyledFooter = styled(RenderFooter)`
	&.cm-modal-footer {
		box-shadow: none;
		height: unset;
		border-radius: 0 0 8px 8px;
	}
`

export const CloseIcon = styled.i`
	font-size: 24px;
`
