// @flow
import React from 'react'
import MultiSelect from 'src/components/_generic/forms/multi-select'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useGetOrganization } from 'src/api/organizations/useGetOrganization'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	organization_id?: number,
	includeAllOption?: boolean,
}

const MultiSelectFormularyDropdown = (props: Props) => {
	const { organization_id = undefined, includeAllOption } = props

	const { authUser } = useGetAuthenticatedUser()
	const { organization } = useGetOrganization(
		organization_id ? organization_id : authUser.organization.id
	)

	const selectAllOption = { value: 0, label: 'All Price Books' }
	const options = organization?.formularies
		? [...organization?.formularies]
		: []

	if (includeAllOption) options.unshift(selectAllOption)

	return <MultiSelect options={options} {...props} />
}

export default MultiSelectFormularyDropdown
