import { gql } from 'graphql-request'
import { getFragmentBodies } from 'src/api/index'
import VendorAlias from 'src/pages/vendors/_entities/VendorAlias'
import Vendor from 'src/api/vendors/_entities/Vendor'

export default {
	getVendor: (fragments = [], body = '') => ({
		identifier: 'get_vendor',
		gql: gql`
            query getVendor($id : ID!) {
                vendor(id: $id) {
                    ${body}
                    ${getFragmentBodies(fragments)}
                }
            }
            ${fragments.join('\n')}
        `,
		formatResults: ({ vendor }) => {
			return Vendor(vendor)
		},
	}),
	getVendors: (fragments = [], body = '') => ({
		identifier: 'get_vendors',
		gql: gql`
            query getVendors($modifiers: QueryModifierInput) {
                vendors(modifiers: $modifiers) {
                    data {
                        ${body}
                        ${getFragmentBodies(fragments)}
                    }
                    paginatorInfo {
                        currentPage
                        lastPage
                        perPage
                        total
                    }
                }
            }
            ${fragments.join('\n')}
        `,
		formatResults: ({ vendors }) => {
			return {
				vendors: vendors?.data?.map((vendor) => Vendor(vendor)) ?? [],
				paginatorInfo: vendors?.paginatorInfo ?? {
					lastPage: 1,
				},
			}
		},
	}),
	getVendorsForDropdown: (fragments = [], body = '') => ({
		identifier: 'get_vendors_for_dropdown',
		gql: gql`
            query getDropdownVendors($modifiers: QueryModifierInput, $id: ID!) {
                vendors(modifiers: $modifiers) {
                    data {
                        ${body}
                        ${getFragmentBodies(fragments)}
                    }
                    paginatorInfo {
                        currentPage
                        lastPage
                        perPage
                        total
                    }
                }
                vendor(id: $id) {
                    ${body}
                    ${getFragmentBodies(fragments)}
                }
            }
            ${fragments.join('\n')}
        `,
		formatResults: ({ vendor, vendors }) => {
			let returnVendors =
				vendors?.data?.map((vendor) => Vendor(vendor)) ?? []
			if (!!vendor) {
				returnVendors = returnVendors.filter(
					(returnVendor) => returnVendor?.id !== vendor?.id
				)

				returnVendors.unshift(Vendor(vendor))
			}

			return {
				vendors: returnVendors,
				paginatorInfo: vendors?.paginatorInfo ?? {
					lastPage: 1,
				},
			}
		},
	}),
	getVendorAliases: (fragments = [], body = '') => ({
		identifier: 'get_vendor_aliases',
		gql: gql`
            query getVendorAliases($modifiers: QueryModifierInput) {
                vendor_aliases(modifiers: $modifiers) {
                    data {
                        ${body}
                        ${getFragmentBodies(fragments)}
                    }
                    paginatorInfo {
                        currentPage
                        lastPage
                        perPage
                        total
                    }
                }
            }
            ${fragments.join('\n')}
        `,
		formatResults: ({ vendor_aliases }) => {
			return {
				vendorAliases:
					vendor_aliases?.data?.map((alias) => VendorAlias(alias)) ??
					[],
				paginatorInfo: vendor_aliases?.paginatorInfo ?? {
					lastPage: 1,
				},
			}
		},
	}),
	getVendorAlias: (fragments = [], body = '') => ({
		identifier: 'get_vendor_alias',
		gql: gql`
            query getVendorAlias($id : ID!) {
                vendor_alias(id: $id) {
                    ${body}
                    ${getFragmentBodies(fragments)}
                }
            }
            ${fragments.join('\n')}
        `,
		formatResults: ({ vendor_alias }) => {
			return VendorAlias(vendor_alias)
		},
	}),
	addVendorAliasLink: (fragments = [], body = '') => ({
		identifier: 'add_vendor_alias_link',
		gql: gql`
            mutation addVendorAliasLink($id: ID!, $vendorId: ID!)
            {
                addVendorAliasLink(id: $id, vendorId: $vendorId)
                {
                    ${body}
                    ${getFragmentBodies(fragments)}
                }
            }
            ${fragments.join('\n')}
        `,
		variables: ({ id, vendorId }) => ({
			id,
			vendorId,
		}),
		formatResults: (data) => {
			return data?.addVendorAliasLink
		},
		onSuccess: (key, queryClient) => {
			queryClient.invalidateQueries(['vendor_alias'])
			queryClient.invalidateQueries(['vendor_alias_list'])
			queryClient.invalidateQueries(['catalog_load_batch_items'])
		},
	}),
	removeVendorAliasLink: (fragments = [], body = '') => ({
		identifier: 'remove_vendor_alias_link',
		gql: gql`
            mutation removeVendorAliasLink($id:ID!)
            {
                removeVendorAliasLink(id: $id)
                {
                    ${body}
                    ${getFragmentBodies(fragments)}
                }
            }
            ${fragments.join('\n')}
        `,
		variables: ({ id }) => ({
			id,
		}),
		formatResults: (data) => {
			return data?.removeVendorAliasLink
		},
		onSuccess: (key, queryClient) => {
			queryClient.invalidateQueries(['vendor_alias'])
			queryClient.invalidateQueries(['vendor_alias_list'])
			queryClient.invalidateQueries(['catalog_load_batch_items'])
		},
	}),
}
