// @flow
import React, { useContext } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import { FlexRow } from 'src/pages/_styles/container.styles'
import Image from 'src/components/_generic/image'
import s3Image from 'src/library/s3Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import Spacer from 'src/components/_generic/spacer'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'

const SageIntacctFlowSyncStep = () => {
	const { nextStep, setSubmit } = useContext(
		ConnectAccountingSoftwareFlowContext
	)

	useComponentDidMount(() => {
		setSubmit(() => () => nextStep())
	})

	return (
		<ConnectAccountingSoftwareStepLayout
			title={'Setting Up Your Integration'}
		>
			<p>
				It will take a few minutes to connect Sage Intacct and verify
				that a successful connection has been made.
			</p>
			<p>
				Once a connection has been established, the information from
				Sage Intacct will be synced with CureMint. Once the sync has
				finished, you can map your CureMint information to your Sage
				Intacct information.
			</p>
			<Spacer />
			<div style={Styles.container}>
				<FlexRow alignItems={'center'}>
					<Image
						alt={'CureMint logo'}
						style={Styles.curemintLogo}
						src={s3Image('logo/curemint_logo_horizontal_color.png')}
						fallbackSrc={s3Image('logo_placeholder.jpg')}
					/>
					<FontAwesomeIcon
						icon={light('arrows-left-right')}
						size={'3x'}
						color={'#0071B8'}
					/>
					<Image
						alt={'Sage Intacct Logo'}
						style={Styles.sageLogo}
						src={s3Image('Sage_Intacct.png')}
						fallbackSrc={s3Image('logo_placeholder.jpg')}
					/>
				</FlexRow>
			</div>
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctFlowSyncStep

const Styles = {
	container: {
		backgroundColor: '#F7F8F9',
		border: '1px solid #DADEE3',
		borderRadius: '12px',
	},
	curemintLogo: {
		width: '300px',
		margin: '-10px 30px 0px 40px',
	},
	sageLogo: {
		width: '400px',
		margin: '-30px -40px -20px -20px',
	},
}
