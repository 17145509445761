import React, { createContext, useState } from 'react'
import Pusher from 'pusher-js'
import { PUSHER_CONFIGS } from 'src/configs/app'
import { useComponentDidMount } from 'src/hooks/index'
import axios from 'axios'

export const PusherContext = createContext()

export const PusherProvider = ({ children }) => {
	const [pusherClient, setPusherClient] = useState()

	useComponentDidMount(() => {
		setPusherClient(
			new Pusher(PUSHER_CONFIGS.appKey, {
				...PUSHER_CONFIGS.options,
				authorizer: (channel) => {
					return {
						authorize: (socketId, callback) => {
							axios.defaults.withCredentials = true
							axios
								.post(PUSHER_CONFIGS.options.authEndpoint, {
									socket_id: socketId,
									channel_name: channel.name,
								})
								.then((response) => {
									callback(null, response.data)
								})
						},
					}
				},
			})
		)
	})

	return (
		<PusherContext.Provider value={pusherClient}>
			{children}
		</PusherContext.Provider>
	)
}
