// @flow
import { useQuery } from 'src/api/index'
import { GetAuthUserOrganizationOnboardingQuery } from 'src/api/users/get-auth-user-organization-onboarding-query'

export const useGetAuthUserOrgOnboarding = () => {
	const { data: organization, ...rest } = useQuery({
		key: ['auth_user_org_onboarding'],
		request: GetAuthUserOrganizationOnboardingQuery,
		options: {
			staleTime: 1000 * 60 * 5,
		},
	})

	return { organization, ...rest }
}
