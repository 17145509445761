import { gql } from 'graphql-request'
import Vendor from 'src/api/vendors/_entities/Vendor'

export const GetManyVendorsQuery = {
	identifier: 'get_many_vendors',
	gql: gql`
		query getManyVendors($ids: [ID]!) {
			many_vendors(ids: $ids) {
				id
				name
			}
		}
	`,
	formatResults: (data) => {
		return data?.many_vendors?.map((vendor) => Vendor(vendor)) ?? []
	},
}
