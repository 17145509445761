// @flow
import enums from 'src/configs/enums'

export default {
	GREATER_THAN: 1,
	LESS_THAN: 2,
	EQUAL_TO: 3,

	getHumanReadableComparisonType: (comparisonType: number) => {
		switch (comparisonType) {
			case enums.COMPARISON_TYPE.GREATER_THAN:
				return 'greater than'
			case enums.COMPARISON_TYPE.LESS_THAN:
				return 'less than'
			case enums.COMPARISON_TYPE.EQUAL_TO:
				return 'equal to'
			default:
				return 'Unknown'
		}
	},

	getHumanReadableComparisonSymbol: (comparisonType: number) => {
		switch (comparisonType) {
			case enums.COMPARISON_TYPE.GREATER_THAN:
				return 'is greater than'
			case enums.COMPARISON_TYPE.LESS_THAN:
				return 'is less than'
			case enums.COMPARISON_TYPE.EQUAL_TO:
				return 'is equal to'
			default:
				return '?'
		}
	},

	getComparisonTypes: () => {
		return [
			enums.COMPARISON_TYPE.GREATER_THAN,
			enums.COMPARISON_TYPE.LESS_THAN,
			enums.COMPARISON_TYPE.EQUAL_TO,
		]
	},
}
