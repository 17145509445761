// @flow
import React, { useContext } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import NumberedList from 'src/components/_generic/lists/NumberedList'
import s3Image from 'src/library/s3Image'
import { StrongText } from 'src/pages/_styles/text.styles'
import CaptionedImage from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/CaptionedImage'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'

const SageIntacctFlowSubscriptionsStep = () => {
	const { nextStep, setSubmit } = useContext(
		ConnectAccountingSoftwareFlowContext
	)

	useComponentDidMount(() => {
		setSubmit(() => () => nextStep())
	})

	const listItems = [
		{
			value: (
				<p>
					Navigate to{' '}
					<StrongText>Company > Admin > Subscriptions</StrongText>
				</p>
			),
		},
		{
			value: (
				<p>
					Scroll down to <StrongText>Web Services</StrongText> and
					toggle it on
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<CaptionedImage
					caption={'Your subscription should look like this'}
					alt={'Sage Intacct subscriptions'}
					src={s3Image(
						'sage_intacct_auth/sage_intacct_subscriptions.png'
					)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
					imageStyle={Styles.image}
				/>
			),
		},
	]

	return (
		<ConnectAccountingSoftwareStepLayout title={'Turn on Web Services'}>
			<p>
				Web Services allows you to send Sage Intacct information with
				external applications and integrations such as CureMint.
			</p>
			<NumberedList listItems={listItems} />
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctFlowSubscriptionsStep

const Styles = {
	image: {
		width: '720px',
	},
}
