// @flow
import React from 'react'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import type { FilterSelectionType } from 'src/flowtypes/Objects/Filters/FilterSelectionType'

type Props = {
	...FilterSelectionType,
	filterOptions: Object[],
}

const FilterRow = ({ filterOptions, filters, setFilters }: Props) => {
	return (
		<HorizontalLayout>
			{filterOptions.map((filterType) => {
				const Filter = filterType.component
				return (
					<Filter
						key={`entity_filter_${filterType?.id}`}
						filters={filters}
						setFilters={setFilters}
					/>
				)
			})}
		</HorizontalLayout>
	)
}

export default FilterRow
