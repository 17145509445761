// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	ach: {
		value: 10,
		name: 'ACH',
		label: 'ACH',
		unitName: 'achPayment',
	},
	transfer: {
		value: 20,
		name: 'Transfer',
		label: 'Transfer',
		unitName: 'bookPayment',
	},
	fee: {
		value: 30,
		name: 'Fee',
		label: 'Fee',
	},
	returnAch: {
		value: 40,
		name: 'Return ACH',
		label: 'Return ACH',
		unitName: 'returnedAch',
	},
	getBankingTransactionTypes: function () {
		return [this.ach, this.transfer, this.fee, this.returnAch]
	},
	getBankingPaymentTypes: function () {
		return [this.ach, this.transfer]
	},
})
