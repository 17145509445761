// @flow

const Formulary = (formularyData) => {
	if (!formularyData) return null

	const userCanEdit = (user) => {
		return (
			user?.isOrganizationAdmin() &&
			String(formularyData.owner_id) === String(user?.organization?.id)
		)
	}

	return {
		value: formularyData?.formulary?.id,
		label: `${formularyData?.formulary?.name}`,
		userCanEdit,
		...formularyData,
	}
}

export default Formulary
