// @flow
import Feature from 'src/api/features/_entities/Feature'
import FeatureLimits from 'src/configs/Enums/FeatureLimits'
import FeatureLimitTypes from 'src/configs/Enums/FeatureLimitTypes'

const TierFeature = (tierFeatureData) => {
	if (!tierFeatureData) return {}

	const is_active = !!tierFeatureData?.feature
		? tierFeatureData?.feature?.is_active === true &&
		  tierFeatureData?.is_active === true
		: tierFeatureData?.is_active

	const isUnlimited = () => {
		return (
			is_active &&
			tierFeatureData?.limit === FeatureLimits.unlimited.value &&
			tierFeatureData?.limit_type === FeatureLimitTypes.unlimited.value
		)
	}

	return {
		id: tierFeatureData.id,
		value: tierFeatureData?.id,
		label: tierFeatureData?.feature?.label,
		...tierFeatureData,
		feature: Feature(tierFeatureData?.feature),
		is_active,
		isUnlimited,
	}
}

export default TierFeature
