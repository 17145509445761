// @flow
import { useEffect } from 'react'

export default function useDidClickOutsideHook(
	ref,
	handler,
	exemptSelectTarget = undefined
) {
	useEffect(() => {
		const handleClick = (e) => {
			if (ref?.current && !ref?.current?.contains(e.target)) {
				const isExemptTarget = e?.target
					?.getAttribute('class')
					?.split(' ')
					?.includes(exemptSelectTarget)

				const isAnimatedSVGElement =
					e.target.className.baseVal !== undefined

				if (
					exemptSelectTarget &&
					(isExemptTarget || isAnimatedSVGElement)
				) {
					return
				}
				handler(e)
			}
		}

		document.addEventListener('mouseup', handleClick)

		return () => {
			document.removeEventListener('mouseup', handleClick)
		}
	}, [ref, handler, exemptSelectTarget])
}
