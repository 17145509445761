import enums from 'src/configs/enums'

export default {
	UNKNOWN: 0,
	CHART_OF_ACCOUNT: 1,
	LOCATION: 2,
	LINE_OF_BUSINESS: 3,
	VENDOR: 4,

	getHumanReadableTitle: (accountingCodeType: number) => {
		switch (accountingCodeType) {
			case enums.accountingCodeType.CHART_OF_ACCOUNT:
				return 'Chart of Accounts'
			case enums.accountingCodeType.LOCATION:
				return 'Location'
			case enums.accountingCodeType.LINE_OF_BUSINESS:
				return 'Line of Business'
			case enums.accountingCodeType.VENDOR:
				return 'Vendor'
			default:
				return 'Unknown'
		}
	},

	getHumanReadableTitlePlural: (accountingCodeType: number) => {
		switch (accountingCodeType) {
			case enums.accountingCodeType.CHART_OF_ACCOUNT:
				return 'Charts of Accounts'
			case enums.accountingCodeType.LOCATION:
				return 'Locations'
			case enums.accountingCodeType.LINE_OF_BUSINESS:
				return 'Lines of Business'
			case enums.accountingCodeType.VENDOR:
				return 'Vendors'
			default:
				return 'Unknown'
		}
	},

	getHumanReadable: (accountingCodeType: number) => {
		switch (accountingCodeType) {
			case enums.accountingCodeType.CHART_OF_ACCOUNT:
				return 'chart of accounts'
			case enums.accountingCodeType.LOCATION:
				return 'location'
			case enums.accountingCodeType.LINE_OF_BUSINESS:
				return 'line of business'
			case enums.accountingCodeType.VENDOR:
				return 'vendor'
			default:
				return 'Unknown'
		}
	},

	getHumanReadablePlural: (accountingCodeType: number) => {
		switch (accountingCodeType) {
			case enums.accountingCodeType.CHART_OF_ACCOUNT:
				return 'charts of accounts'
			case enums.accountingCodeType.LOCATION:
				return 'locations'
			case enums.accountingCodeType.LINE_OF_BUSINESS:
				return 'lines of business'
			case enums.accountingCodeType.VENDOR:
				return 'vendors'
			default:
				return 'unknown'
		}
	},
}
