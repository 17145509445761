// @flow
import React from 'react'
import MultiSelect from 'src/components/_generic/forms/multi-select'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import { useGetMyOrgVendorList } from 'src/api/organization_vendors/useGetMyOrgVendorList'
import usePaginateArray from 'src/hooks/Pagination/usePaginateArray'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	includeAllOption?: boolean,
	modifiers?: QueryModifierType,
}

const MultiSelectOrganizationVendorsDropdown = ({
	includeAllOption,
	modifiers,
	...rest
}: Props) => {
	const { searchVendors } = useGetMyOrgVendorList()
	const { items: organizationVendors } = usePaginateArray(
		searchVendors({
			...modifiers,
			searchTarget: '',
		}),
		modifiers
	)

	const optionLists =
		organizationVendors?.sort((a, b) =>
			a.vendor?.name.toLowerCase() < b.vendor?.name.toLowerCase() ? -1 : 1
		) ?? []

	const selectAllOption = { value: 0, label: 'All Vendors' }
	const options = [...optionLists]
	if (includeAllOption) options.unshift(selectAllOption)

	return (
		<MultiSelect
			options={options}
			closeMenuOnSelect={options?.length === 1}
			{...rest}
		/>
	)
}

export default withQueryModifiers(
	MultiSelectOrganizationVendorsDropdown,
	null,
	[],
	500
)
