import React from 'react'

const useRenderLayout = (children: Node) => {
	const childArray = React.Children.toArray(children)

	const getChildByLayoutId = (layoutId: string) => {
		return childArray?.find((child) => child?.props?.lid === layoutId)
	}

	const renderLayoutItem = (
		layoutId: string,
		props = {},
		fallbackRender = () => null
	) => {
		const element = getChildByLayoutId(layoutId)

		if (!element) {
			return fallbackRender()
		}
		return React.cloneElement(element, { ...element?.props, ...props })
	}

	const hasLid = (layoutId: string) => {
		return !!getChildByLayoutId(layoutId)
	}

	return { renderLayoutItem, hasLid }
}

export default useRenderLayout
