// @flow

export default {
	UPS: 1,
	USPS: 2,
	FEDEX: 3,
	DHL: 4,
	OTHER: 5,
	getHumanReadableShippingProvider: function (shippingProvider: number) {
		switch (+shippingProvider) {
			case this.UPS:
				return 'UPS'
			case this.USPS:
				return 'USPS'
			case this.FEDEX:
				return 'FEDEX'
			case this.DHL:
				return 'DHL'
			case this.OTHER:
				return 'OTHER'
			default:
				return 'Unknown'
		}
	},
}
