// @flow
import { Query, useQuery } from 'src/api/index'
import { GetLocationGroupListQuery } from 'src/api/location_groups/get-location-group-list-query'
import { useQueryClient } from 'react-query'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import LocationGroup from 'src/apollo/_entities/locationGroup'

export const useGetLocationGroupList = (
	modifiers: QueryModifierType,
	organization_id = undefined
) => {
	const queryClient = useQueryClient()

	const { data, isLoading } = useQuery({
		key: ['location_groups', { modifiers, organization_id }],
		request: GetLocationGroupListQuery,
		options: {
			staleTime: 1000 * 60 * 5,
		},
	})

	const getLocationGroupList = async (
		modifiers: QueryModifierType,
		organization_id = undefined
	) => {
		return await queryClient.fetchQuery(
			['location_groups', { modifiers, organization_id }],
			Query(GetLocationGroupListQuery, queryClient)
		)
	}

	return {
		location_groups:
			data?.location_groups?.map((group) => LocationGroup(group)) ?? [],
		paginatorInfo: data?.paginatorInfo ?? { lastPage: 1 },
		isLoading,
		getLocationGroupList,
	}
}
