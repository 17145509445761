import { useEffect, useState } from 'react'
import MoneyFormatter from 'src/library/MoneyFormatter'
import { useDebounce } from 'use-debounce'
import { useUpsertPrice } from 'src/api/prices/useUpsertPrice'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

const useAdminSavesPrice = (option, onSave = () => {}) => {
	const { authUser } = useGetAuthenticatedUser()
	const [newPrice, setNewPrice] = useState(option?.price)
	const [debouncedValue] = useDebounce(newPrice, 1000)
	const { mutate: upsertPrice } = useUpsertPrice({
		onSuccess: onSave,
	})

	const priceHasChanged = debouncedValue !== option?.price

	useEffect(() => {
		if (priceHasChanged && authUser?.canEditPriceOption(option)) {
			upsertPrice({
				id: option?.price_id ?? undefined,
				input: {
					product_id: option?.product_id,
					price: isNaN(debouncedValue) ? 0 : debouncedValue,
					formulary_id: option?.formulary_id ?? undefined,
				},
			})
		}
	}, [priceHasChanged])

	const handlePriceChanged = (value) => {
		setNewPrice(MoneyFormatter.toPennies(value.floatValue))
	}

	return { newPrice, setNewPrice, handlePriceChanged }
}

export default useAdminSavesPrice
