// @flow
import React from 'react'

type Props = {
	row?: boolean,
	size?: number,
}
const Spacer = ({ row = false, size = 20 }: Props) =>
	row ? (
		<div style={{ marginRight: size }} />
	) : (
		<div style={{ marginBottom: size }} />
	)

export default Spacer
