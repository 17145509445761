// @flow
import React, { useEffect, useRef } from 'react'
import { useField } from 'formik'

const CheckboxInput = ({ name, indeterminate, ...rest }) => {
	const [field] = useField(name)
	const checkboxRef = useRef()

	useEffect(() => {
		checkboxRef.current.indeterminate = indeterminate
	}, [indeterminate])

	return (
		<input
			ref={checkboxRef}
			type={'checkbox'}
			name={name}
			className={'cm-legacy-checkbox'}
			{...field}
			{...rest}
		/>
	)
}

export default CheckboxInput
