// @flow
import * as Yup from 'yup'

export default {
	marketplace: {
		quickshop: {
			addToCart: Yup.object().shape(
				{
					quantity: Yup.number()
						.positive('Quantity must be positive')
						.integer('Quantity must be in integer')
						.required('Please enter a quantity amount')
						.typeError('Quantity must be a number'),

					price_selection: Yup.object().when('loaded_price', {
						is: (loaded_price) => loaded_price === undefined,
						then: Yup.object()
							.shape({
								price_id: Yup.string().required(),
							})
							.required('Please select a price.'),
					}),
					loaded_price: Yup.number().when('price_selection', {
						is: (price_selection) => !price_selection,
						then: Yup.number().required(
							'Please enter an expected price'
						),
					}),
				},
				[['price_selection', 'loaded_price']]
			),
		},
	},
}
