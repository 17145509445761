// @flow
import React from 'react'

type Props = {
	style?: Object,
	className?: string,
	children?: React.Node,
}

const PaddedLayout = ({ style = {}, children, className = '' }: Props) => {
	return (
		<div className={`cm-p-normal ${className}`} style={style}>
			{children}
		</div>
	)
}

export default PaddedLayout
