// @flow
import type { Node } from 'react'
import React from 'react'

type Props = {
	leftContent?: Node,
	rightContent?: Node,
	leftStyles?: Object,
	rightStyles?: Object,
	containerStyles?: Object,
	className?: string,
}

const SplitLayout = ({
	leftContent = null,
	rightContent = null,
	leftStyles = {},
	rightStyles = {},
	containerStyles = {},
	className = '',
}: Props) => (
	<div
		className={className}
		style={{ ...Styles.container, ...containerStyles }}
	>
		{leftContent ? (
			<div
				style={{ ...Styles.left, ...leftStyles }}
				className={'left-content'}
			>
				{leftContent}
			</div>
		) : null}
		{rightContent ? (
			<div
				style={{
					...Styles.right,
					...rightStyles,
					...(!leftContent ? { flex: 1 } : {}),
				}}
				className={'right-content'}
			>
				{rightContent}
			</div>
		) : null}
	</div>
)

export default SplitLayout

const Styles = {
	container: {
		flex: 1,
		flexDirection: 'row',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	left: {
		flex: 1,
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	center: {
		flex: 1,
	},
	right: {
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
}
