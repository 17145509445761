// @flow

export const TimeInStatus = [
	{
		label: '0 - 2 Hours',
		range: {
			start: 0,
			end: 2 * 60 * 60, // in seconds
		},
	},
	{
		label: '2 - 8 Hours',
		range: {
			start: 2 * 60 * 60,
			end: 8 * 60 * 60, // in seconds
		},
	},
	{
		label: '8 - 24 Hours',
		range: {
			start: 8 * 60 * 60,
			end: 24 * 60 * 60, // in seconds
		},
	},
	{
		label: '1 - 2 Days',
		range: {
			start: 24 * 60 * 60,
			end: 2 * 24 * 60 * 60, // in seconds
		},
	},
	{
		label: 'More Than 2 Days',
		range: {
			start: 2 * 24 * 60 * 60,
			end: 20 * 365 * 24 * 60 * 60, // in seconds
		},
	},
]
