export const UserDataFragment = `
    id
    name
    email
    phone
    image_path
    is_active
    last_activity
    created_at
    current_location_id
`
