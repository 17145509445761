// @flow

import Catalog from 'src/apollo/_entities/catalog'

const UserGroup = (userGroupData) => {
	if (!userGroupData) return null

	const catalogIds = userGroupData?.catalogs?.map((catalog) => catalog?.id)
	const catalogs = userGroupData?.catalogs?.map((catalog) => Catalog(catalog))

	return {
		...userGroupData,
		value: userGroupData?.id,
		label: `${userGroupData?.name}`,
		link: `/admin/user_groups/${userGroupData?.id}`,
		catalogIds,
		catalogs,
	}
}

export default UserGroup
