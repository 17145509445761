// @flow
import React from 'react'
import MultiSelect from 'src/components/_generic/forms/multi-select'
import { useGetCategoriesList } from 'src/api/categories/useGetCategoriesList'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	includeAllOption?: boolean,
}

const MultiSelectCategoryDropdown = (props: Props) => {
	const { includeAllOption } = props
	const { data: categories } = useGetCategoriesList()

	const selectAllOption = { value: 0, label: 'All Categories' }
	const options = categories ? [...categories] : []

	if (includeAllOption) options.unshift(selectAllOption)

	return (
		<MultiSelect options={options} closeMenuOnSelect={false} {...props} />
	)
}

export default MultiSelectCategoryDropdown
