import { OrganizationsVendorsShippingRuleDataFragment } from 'src/api/organization_vendors/_fragments/_organizations_vendors_shipping_rule_data_fragment'
import { OrganizationIntegrationDataFragment } from 'src/api/integrations/_fragments/_organization_integration_data_fragment'

export const OrganizationsVendorsDataFragment = `
        id
        organization_id
        vendor_id
        account_number
        order_email
        billing_instructions
        admin_notes
        vendor_code
        can_place_order
        has_active_punchout
        procurement_is_active
        has_accepted_terms
        connection_status
        link_status
        organization {
            id
            name
        }
        vendor {
            id
            name
            image_path
        }
        integrations {
            ${OrganizationIntegrationDataFragment}
        }
        shipping_rules {
            ${OrganizationsVendorsShippingRuleDataFragment}
        }
        accounting_mappables {
            id
            organization_entity_id
            accounting_category_id
        }
        created_at
`
