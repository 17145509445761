// @flow
import { useReducer, useEffect } from 'react'
import type { SearchState, SearchAction } from 'src/components/Search/types'

function init(state?: SearchState) {
	return {
		previousTarget: state?.previousTarget ?? '',
		currentTarget: state?.currentTarget ?? '',
		isFocused: state?.isFocused ?? false,
	}
}

function reducer(state: SearchState, action: SearchAction) {
	switch (action.type) {
		case 'setSearchTarget':
			return {
				...state,
				previousTarget: state.currentTarget,
				currentTarget: action.payload.currentTarget,
			}
		case 'onFocus':
			return { ...state, isFocused: true }
		case 'onBlur':
			return { ...state, isFocused: false }
		case 'reset':
			return init(action.payload)
		default:
			throw new Error()
	}
}

const useSearchBar = (
	searchState: SearchState = {
		previousTarget: '',
		currentTarget: '',
		isFocused: false,
	},
	onSearch: () => void
) => {
	const [state, dispatch] = useReducer(reducer, searchState, init)

	useEffect(() => {
		if (
			typeof onSearch === 'function' &&
			!state.currentTarget &&
			state.previousTarget
		) {
			dispatch({ type: 'reset' })
			onSearch('')
		}
	}, [state, onSearch])

	return [state, dispatch]
}

export { useSearchBar }
