// @flow
import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-responsive-modal'
import SplitLayout from 'src/components/_layouts/split-layout'
import useTargetScrolling from 'src/hooks/useTargetScrolling'
import {
	CloseIcon,
	StyledFooter,
} from 'src/components/_generic/new-modal/NewModal.styles'
import LoadingView from 'src/components/_generic/loading-view'
import { H2 } from 'src/pages/_styles/text.styles'
import type { CMModalProps } from 'src/components/_generic/new-modal/types'

export const NewModal = ({
	size,
	open,
	center,
	onClose,
	onScrollBottom,
	className,
	title,
	actionPanel,
	footer,
	noScrollHover,
	fillHeight,
	overflowVisible,
	hideScrollbars,
	isLoading = false,
	children,
	focusTrapped = false,
	...rest
}: CMModalProps) => {
	const contentRef = useRef()
	const { atBottom, hasScroll, handleScroll } = useTargetScrolling(contentRef)
	const noTitle = !title
	const rightStyles = { ...rest.rightStyles }

	if (noTitle) {
		rightStyles.minHeight = '20px'
	}

	useEffect(() => {
		if (onScrollBottom && atBottom) {
			onScrollBottom()
		}
	}, [onScrollBottom, atBottom])

	if (!open) {
		return null
	}

	return (
		<Modal
			open={open}
			center={center}
			closeIcon={<>{''}</>}
			classNames={{
				overlay: className?.overlay
					? className?.overlay
					: 'cm-modal-overlay',
				modal: className?.modal
					? className?.modal
					: `cm-modal cm-modal-${String(size)} ${className}`,
			}}
			styles={{
				closeButton: {
					top: !!noTitle ? '8px' : '16px',
				},
				modal: {
					// the pixel values of other dom elements contributing to content height
					height: fillHeight
						? 'calc(100vh - 20px - 20px - 50px)'
						: 'auto',
					minHeight: fillHeight
						? className?.overlay === 'cm-modal-fullscreen-overlay'
							? '100%'
							: 'calc(100vh - 20px - 20px - 50px)'
						: undefined,
					overlay: {
						zIndex: 0,
					},
				},
			}}
			noTitle={noTitle}
			focusTrapped={focusTrapped}
			{...rest}
		>
			<div className={'cm-modal-header'}>
				<SplitLayout
					leftContent={<H2>{title}</H2>}
					rightContent={
						<>
							{actionPanel}
							<CloseIcon
								className='cm-icon cm-icon-close'
								onClick={onClose}
								style={{
									cursor: 'pointer',
									marginRight: '-2rem',
								}}
							/>
						</>
					}
					leftStyles={rest?.leftStyles}
					rightStyles={rightStyles}
				/>
			</div>

			<div
				className={`
					${
						className?.contentOverride
							? className?.contentOverride
							: !overflowVisible
							? 'cm-modal-content'
							: 'cm-modal-content-with-overflow'
					}
					${!hasScroll ? 'cm-modal-content-no-scrollbar' : ''}
					${hideScrollbars ? 'cm-no-overflow' : ''}
				`}
				onScroll={handleScroll}
				ref={contentRef}
			>
				{isLoading ? <LoadingView /> : children}
			</div>
			<StyledFooter
				atBottom={atBottom}
				noScrollHover={noScrollHover}
				footer={footer}
			/>
		</Modal>
	)
}

NewModal.defaultProps = require('./static').staticDefaults

export default NewModal
