// @flow
import React, { useContext } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import NumberedList from 'src/components/_generic/lists/NumberedList'
import s3Image from 'src/library/s3Image'
import { StrongText } from 'src/pages/_styles/text.styles'
import CaptionedImage from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/CaptionedImage'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'

const SageIntacctFlowWebServicesStep = () => {
	const { nextStep, setSubmit } = useContext(
		ConnectAccountingSoftwareFlowContext
	)

	useComponentDidMount(() => {
		setSubmit(() => () => nextStep())
	})

	const listItems = [
		{
			value: (
				<p>
					Navigate to{' '}
					<StrongText>Company > Setup > Company</StrongText>
				</p>
			),
		},
		{
			value: (
				<p>
					Select the <StrongText>Security</StrongText> tab
				</p>
			),
		},
		{
			value: (
				<p>
					Click <StrongText>Edit</StrongText> in the top right of the
					page
				</p>
			),
		},
		{
			value: (
				<p>
					Scroll to the{' '}
					<StrongText>Web Services authorizations</StrongText> section
				</p>
			),
		},
		{
			value: (
				<p>
					Add CureMint's <StrongText>Sender ID:</StrongText>{' '}
					CureMintMPP
				</p>
			),
		},
		{
			value: (
				<p>
					Set the status to <StrongText>Active</StrongText>
				</p>
			),
		},
		{
			value: (
				<p>
					Click <StrongText>Save</StrongText> in the modal
				</p>
			),
		},
		{
			skipNumber: true,
			value: (
				<CaptionedImage
					caption={
						'Your web services authorization should look like this'
					}
					alt={'Sage Intacct web services authorization'}
					src={s3Image(
						'sage_intacct_auth/sage_intacct_web_services_authorization.png'
					)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
					imageStyle={Styles.image}
				/>
			),
		},
		{
			value: (
				<p>
					Click <StrongText>Save</StrongText> in the top right of the
					page
				</p>
			),
		},
	]

	return (
		<ConnectAccountingSoftwareStepLayout title={'Configure Web Services'}>
			<p>
				You’ll need to authorize CureMint to use the Web Services by
				adding CureMint to the Web Services authorizations list.
			</p>
			<NumberedList listItems={listItems} />
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctFlowWebServicesStep

const Styles = {
	image: {
		width: '760px',
	},
}
