// @flow
import { useQuery } from 'src/api/index'
import { GetCategoriesListQuery } from 'src/api/categories/get-categories-list-query'

export const useGetCategoriesList = (parentId = null) => {
	const { data, isLoading } = useQuery({
		key: ['categories-list', { parentId }],
		request: GetCategoriesListQuery,
		options: {
			staleTime: 1000 * 60 * 10,
		},
	})

	return { data, isLoading }
}
