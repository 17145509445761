// @flow
import React from 'react'
import enums from 'src/configs/enums'
import Select from 'src/components/_generic/forms/select'

type Props = {
	onChange?: (value: number) => void,
	name?: string,
}

const QuantityComparatorRadioGroup = ({
	onChange,
	name = 'comparator',
}: Props) => {
	const {
		GREATER_THAN,
		LESS_THAN,
		EQUAL_TO,
		getHumanReadableComparisonSymbol,
	} = enums.COMPARISON_TYPE

	return (
		<Select
			name={name}
			options={[
				{
					label: getHumanReadableComparisonSymbol(GREATER_THAN),
					value: GREATER_THAN,
				},
				{
					label: getHumanReadableComparisonSymbol(LESS_THAN),
					value: LESS_THAN,
				},
				{
					label: getHumanReadableComparisonSymbol(EQUAL_TO),
					value: EQUAL_TO,
				},
			]}
			onChange={onChange} // radio group booleans get converted to strings (dumb)
			label={'Comparator'}
			required
		/>
	)
}

export default QuantityComparatorRadioGroup
