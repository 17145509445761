// @flow

import ConfigTools from 'src/configs/ConfigTools'
import enums from 'src/configs/enums'

export default ConfigTools({
	businessChecking: {
		value: 'Business Checking',
		label: 'Business Checking',
	},
	businessSavings: {
		value: 'Business Savings',
		label: 'Business Savings',
	},
	personalChecking: {
		value: 'Person Checking',
		label: 'Personal Checking',
	},
	personalSavings: {
		value: 'Person Savings',
		label: 'Personal Savings',
	},
	getACHCounterpartyAccountTypes: function () {
		return [
			enums.bankingACHCounterpartyAccountTypes.businessChecking,
			enums.bankingACHCounterpartyAccountTypes.businessSavings,
			enums.bankingACHCounterpartyAccountTypes.personalChecking,
			enums.bankingACHCounterpartyAccountTypes.personalSavings,
		]
	},
})
