import { CatalogDataFragment } from 'src/api/catalogs/_fragments/catalog-data-fragment'
import { gql } from 'graphql-request'
import Catalog from 'src/apollo/_entities/catalog'

export const GetOrgCatalogsByProductQuery = {
	identifier: 'get_catalogs_from_product_id',
	gql: gql`
        query getOrgCatalogsByProduct($organization_id: ID!, $product_id:ID!){
            catalogs_from_product_id(organization_id: $organization_id, product_id: $product_id){
                ... on Catalog {
                    ${CatalogDataFragment}
                }
            }
        }
    `,
	formatResults: (data) => {
		return (
			data?.catalogs_from_product_id?.map((catalog) =>
				Catalog(catalog)
			) ?? []
		)
	},
}
