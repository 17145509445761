import { QueryClient } from 'react-query'

const processBankingUnauthenticatedError = async (err, queryClient) => {
	if ((err?.response?.errors?.length ?? 0) > 0) {
		const isUnauthenticated =
			err.response.errors.filter(
				(error) =>
					error?.extensions?.category === 'banking-unauthenticated'
			).length !== 0
		if (isUnauthenticated) {
			await queryClient.invalidateQueries(['me'])
			window.localStorage.removeItem('unit')
			await queryClient.invalidateQueries('unit')
		}
	}
}

const queryClient = new QueryClient()

queryClient.setDefaultOptions({
	queries: {
		onError: async (err) => {
			await processBankingUnauthenticatedError(err, queryClient)
		},
	},
	mutations: {
		onError: async (err) => {
			await processBankingUnauthenticatedError(err, queryClient)
		},
	},
})

export { queryClient }
