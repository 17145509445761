// @flow

import { sageMaintenanceTest } from 'src/configs/app'

export default function () {
	if (sageMaintenanceTest) {
		return true
	}

	const now = new Date()
	const hour = now.getUTCHours()
	const min = now.getUTCMinutes()

	//Check for Saturday 2:20am - 6:40am UTC
	return (
		now.getUTCDay() === 6 &&
		((hour > 2 && hour < 6) ||
			(hour === 2 && min >= 20) ||
			(hour === 6 && min <= 40))
	)
}
