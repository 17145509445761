// @flow
import { Query, useQuery } from 'src/api/index'
import { GetProductListQuery } from 'src/api/products/get-product-list-query'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { useQueryClient } from 'react-query'

export const useGetProductList = (modifiers?: QueryModifierType) => {
	const key = ['product_list', { modifiers }]
	const queryClient = useQueryClient()

	const { data, ...rest } = useQuery({
		key,
		request: GetProductListQuery,
		options: {
			staleTime: 1000 * 60 * 10,
			keepPreviousData: true,
			enabled: !!modifiers,
		},
	})

	const getProductList = async (modifiers: QueryModifierType) => {
		const results = await queryClient.fetchQuery(
			['product_list', { modifiers }],
			Query(GetProductListQuery, queryClient)
		)
		return {
			products: results?.products ?? [],
			paginatorInfo: results?.paginatorInfo ?? { lastPage: 1 },
		}
	}

	const refetch = () => queryClient.invalidateQueries(key)

	return {
		products: data?.products ?? [],
		paginatorInfo: data?.paginatorInfo ?? { lastPage: 1 },
		getProductList,
		refetch,
		...rest,
	}
}
