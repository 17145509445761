// @flow
import React from 'react'

type Props = {
	listItems: Array<{
		skipNumber: boolean,
		value: Node,
	}>,
}

const NumberedList = ({ listItems }: Props) => {
	const renderItems = () => {
		let listNumber = 0

		return listItems?.map((item) => {
			if (!item.skipNumber) {
				listNumber++
			}

			return item.skipNumber ? (
				<>
					<>&nbsp;</>
					{item.value}
				</>
			) : (
				<>
					<p style={Styles.listNumber}>{listNumber}</p>
					{item.value}
				</>
			)
		})
	}

	return <div style={Styles.grid}>{renderItems()}</div>
}

export default NumberedList

const Styles = {
	grid: {
		display: 'grid',
		gridTemplateColumns: '30px auto',
		gap: '12px 8px',
	},
	listNumber: {
		fontSize: '16px',
		borderRadius: '15px',
		height: '30px',
		width: '30px',
		paddingTop: '4px',
		marginTop: '0px',
		fontWeight: 500,
		textAlign: 'center',
		color: '#005285',
		backgroundColor: '#E1F3FF',
	},
}
