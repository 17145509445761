// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetManyVendors } from 'src/api/vendors/useGetManyVendors'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderAdminVendorPills = ({ filters, setFilters }: Props) => {
	const { vendors: adminVendors } = useGetManyVendors(filters?.adminVendors)

	const handleFilterRemove = (filter) => {
		let activeVendorFilters = filters?.adminVendors?.filter(
			(vendor) => '' + vendor !== '' + filter
		)
		setFilters({ ...filters, adminVendors: activeVendorFilters })
	}

	let vendorPills = adminVendors?.filter((vendor) =>
		filters?.adminVendors?.includes(vendor?.id)
	)

	return (
		vendorPills?.map((vendor) => {
			return (
				<FilterPill
					filter={vendor?.name}
					filterType={'VENDOR'}
					onRemove={() => handleFilterRemove(vendor?.id)}
					key={`vendor-badge-${vendor?.id}`}
				/>
			)
		}) ?? null
	)
}

export default RenderAdminVendorPills
