// @flow

export type AddressType = {
	id?: number,
	address1: string,
	address2?: string,
	city: string,
	state: string,
	postal_code: string,
	country: string,
}

export const defaultAddress = {
	address1: '',
	address2: '',
	city: '',
	state: '',
	country: '',
	postal_code: '',
}
