// @flow
const Permission = (permissionData) => {
	return {
		...permissionData,
		id: permissionData?.id,
		value: permissionData?.id,
		label: permissionData?.display_name,
	}
}

export default Permission
