import { gql } from 'graphql-request'
import { PermissionUserDataFragment } from 'src/api/permissions/_fragments/_permission_user_data_fragment'
import { LocationGroupUserDataFragment } from 'src/api/location_groups/_fragments/_location_group_user_data_fragment'
import AuthUser from 'src/apollo/_entities/authUser'
import { OrganizationAuthUserDataFragment } from 'src/api/organizations/_fragments/_organization_auth_user_data_fragment'
import { AuthUserDataFragment } from 'src/api/users/_fragments/_auth_user_data_fragment'
import { LocationDataFragment } from 'src/api/locations/_fragments/_location_data_fragment'

export const GetAuthenticatedUserQuery = {
	identifier: 'me',
	gql: gql`
        query me {
            me {
                ${AuthUserDataFragment}

                current_location {
                    ${LocationDataFragment}
                }

                organization {
                    ${OrganizationAuthUserDataFragment}
                }

                permissions {
                    ${PermissionUserDataFragment}
                }

                location_groups {
                    ${LocationGroupUserDataFragment}
                }

                locations {
                    id
                    location {
                        id
                        name
                        users {
                            user {
                                id
                                name
                            }
                        }
                    }
                    permission {
                        id
                        name
                        display_name
                    }
                }

                catalogs {
                    id
                    catalog_view_id
                    catalog {
                        id
                        name
                        is_full
                    }
                }

                user_groups {
                    id
                    name
                    catalogs {
                        id
                        catalog_view_id
                        catalog {
                            id
                            name
                            is_full
                        }
                    }
                }
            }
        }
    `,
	formatResults: (data) => {
		return AuthUser(data?.me)
	},
}
