// @flow
import type { Node } from 'react'
import React, { useEffect, useRef } from 'react'
import useTargetScrolling from 'src/hooks/useTargetScrolling'
import EmptyFunc from 'src/library/emptyFunc'

type Props = {
	children?: Node,
	className?: string,
	style?: Object,
	onScollToBottom?: (any) => any,
}

const ScrollLayout = ({
	children,
	style = {},
	onScollToBottom = EmptyFunc,
	className,
	...rest
}: Props) => {
	const scrollRef = useRef()
	const { isNewAtBottom, handleScroll } = useTargetScrolling(scrollRef)

	useEffect(() => {
		if (isNewAtBottom) {
			onScollToBottom()
		}
	}, [isNewAtBottom])

	return (
		<div
			ref={scrollRef}
			style={{ ...style }}
			className={`cm-scroll-layout ${className}`}
			{...rest}
			onScroll={handleScroll}
		>
			{children}
		</div>
	)
}

export default ScrollLayout
