// @flow
import { useQuery } from 'src/api/index'
import { GetProductQuery } from 'src/api/products/get-product-query'

export const useGetProduct = (product_id) => {
	const { data: product, ...rest } = useQuery({
		key: ['product', { product_id }],
		request: GetProductQuery,
		options: {
			enabled: !!product_id,
			staleTime: 1000 * 60 * 5,
		},
	})

	return { product, ...rest }
}
