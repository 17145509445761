// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import enums from 'src/configs/enums'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrderStatusPills = ({ filters, setFilters }: Props) => {
	const orderStatuses = enums.ORDER_STATUS.getAllOrderStatuses()

	const handleFilterRemove = (filter) => {
		let activeStatusFilters = filters?.orderStatus?.filter(
			(status) => '' + status !== '' + filter
		)
		setFilters({ ...filters, orderStatus: activeStatusFilters })
	}

	let statusPills = orderStatuses?.filter((status) =>
		filters?.orderStatus?.includes(status.toString())
	)

	return statusPills?.map((status) => {
		return (
			<FilterPill
				filter={enums.ORDER_STATUS.getHumanReadable(status)}
				filterType={'STATUS'}
				onRemove={() => handleFilterRemove(status)}
				key={`status-badge-${status}`}
			/>
		)
	})
}

export default RenderOrderStatusPills
