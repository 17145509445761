// @flow
import React from 'react'
import { withRightSidePanel } from 'src/components/RightSidePanel/withRightSidePanel'

type Props = {
	isDisabledOverlay?: boolean,
	showNav?: boolean,
}

const AppLayout = ({ children }: Props) => {
	return <div>{children}</div>
}

export default withRightSidePanel(AppLayout)
