// @flow
import React from 'react'
import AsyncMultiSelect from 'src/components/_generic/forms/async-select/async-multi-select'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import type { QueryModifierSetterType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierSetterType'
import useCumulativeList from 'src/hooks/useCumulativeList'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import { useGetLocationList } from 'src/api/locations/useGetLocationList'
import type { LocationType } from 'src/flowtypes/Entities/LocationType'
import Location from 'src/apollo/_entities/location'
import type { FilterStateType } from 'src/flowtypes/Objects/Filters/FilterStateType'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	showLocationCode?: boolean,
	filterFn?: (location: LocationType) => any,
	organization_id?: number,
	includeAllOption?: boolean,
	extraFilters?: FilterStateType,
	modifiers: QueryModifierType,
	setters: QueryModifierSetterType,
}

const MultiSelectLocationsDropdown = ({
	modifiers,
	setters,
	showLocationCode = false,
	filterFn = () => true,
	includeAllOption = false,
	extraFilters = {},
	...rest
}: Props) => {
	const listModifiers = {
		...modifiers,
		filters: {
			...modifiers.filters,
			...extraFilters,
		},
	}

	const { locations, isLoading, paginatorInfo, getLocationList } =
		useGetLocationList(listModifiers, showLocationCode)

	const formatLocation = (location: Object) => {
		return {
			...location,
			value: location?.id ?? '',
			label: showLocationCode
				? !!location?.location_code
					? `${location.location_code} ${location?.name}`
					: location?.name
				: location?.name,
		}
	}

	const { cumulativeList } = useCumulativeList(
		locations?.map((location) => formatLocation(location))
	)

	let options = cumulativeList
	if (includeAllOption) {
		options = [{ value: 0, label: 'All Locations' }, ...cumulativeList]
	}

	const handleLoadOptions = async (inputValue) => {
		let fetched = await getLocationList({
			...listModifiers,
			page: 1,
			resultsPerPage: 25,
			searchTarget: inputValue,
		})
		let queryData = (await fetched.data) || []

		let filtered = queryData
			.map((location) => formatLocation(Location(location)))
			.filter((location) =>
				location.label.toLowerCase().includes(inputValue.toLowerCase())
			)
		return Promise.resolve(filtered)
	}

	const onMenuScrollToBottom = () =>
		paginatorInfo.lastPage > listModifiers?.page ? setters.nextPage() : {}

	return (
		<AsyncMultiSelect
			options={options?.filter(filterFn)}
			loading={isLoading}
			closeMenuOnSelect={locations?.length === 1}
			onLoadMore={handleLoadOptions}
			searchTarget={listModifiers.searchTarget}
			onInputChange={setters.setSearchTarget}
			onMenuScrollToBottom={onMenuScrollToBottom}
			required
			{...rest}
		/>
	)
}

export default withQueryModifiers(MultiSelectLocationsDropdown)
