export const OrganizationIntegrationDataFragment = `
    id
    organization_id
    integration_id
    integration {
    	id
    	name
    }
    is_active
    configs
`
