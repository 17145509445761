// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import enums from 'src/configs/enums'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderBillMatchStatusPills = ({ filters, setFilters }: Props) => {
	const matchStatuses = enums.BILL_MATCH_STATUS.getAllMatchStatuses()

	const handleFilterRemove = (filter) => {
		let activeStatusFilters = filters?.billMatchStatus?.filter(
			(status) => '' + status !== '' + filter
		)
		setFilters({ ...filters, billMatchStatus: activeStatusFilters })
	}

	let statusPills = matchStatuses?.filter((status) => {
		return filters?.billMatchStatus?.includes(status.toString())
	})

	return statusPills?.map((status) => {
		return (
			<FilterPill
				filter={enums.BILL_MATCH_STATUS.getHumanReadable(status)}
				filterType={'STATUS'}
				onRemove={() => handleFilterRemove(status)}
				key={`status-badge-${status}`}
			/>
		)
	})
}

export default RenderBillMatchStatusPills
