// @flow
import type { Props } from 'src/components/_generic/new-modal/footers/types'

export function mapProps({ footer, noScrollHover, atBottom, ...rest }: Props) {
	return {
		render: require('./build').render({ footer, ...rest }),
		noScrollHover,
		atBottom,
		...rest,
	}
}
