// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { CardHeaderTitle } from 'src/pages/_styles/text.styles'
import {
	CardHeaderActions,
	CardHeaderDescriptor,
	CardHeaderDivider,
	CardHeaderSection,
} from 'src/pages/_components/Cards/_styles/card.styles'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

type CardHeaderProps = {
	title?: string | React.ReactNode,
	description?: string | Node,
	rightContent?: string | React.ReactNode,
	showDivider?: boolean,
	classNames?: string,
	size?: 'sm' | 'md',
	noPadding?: boolean,
}

const CardHeader = ({
	title = null,
	description = null,
	rightContent = null,
	showDivider = true,
	classNames = '',
	size = 'md',
	noPadding,
}: CardHeaderProps) => {
	const hasActions = rightContent?.props.hasOwnProperty('isNullRender')
		? rightContent.props.isNullRender && !!rightContent?.props?.condition
		: !isNullOrUndefined(rightContent)
	const hasDescription = !isNullOrUndefined(description)
	const hasDescriptor = !isNullOrUndefined(title) | !!hasDescription
	const stringTitle = typeof title === 'string'

	return (
		<>
			<CardHeaderSection
				className={classNames}
				nopadding={noPadding}
				hasdescription={hasDescription}
				hasdescriptor={hasDescriptor}
				hasactions={hasActions}
				stringtitle={stringTitle}
				size={size}
			>
				<CardHeaderDescriptor hasdescription={hasDescription}>
					<ConditionalRender
						condition={stringTitle}
						failedRender={title}
					>
						<CardHeaderTitle className={'mt-0'}>
							{title}
						</CardHeaderTitle>
					</ConditionalRender>
					<ConditionalRender
						condition={typeof description === 'string'}
						failedRender={description}
					>
						<p>{description}</p>
					</ConditionalRender>
				</CardHeaderDescriptor>
				<CardHeaderActions hasdescription={hasDescription}>
					{rightContent}
				</CardHeaderActions>
			</CardHeaderSection>
			<ConditionalRender condition={showDivider}>
				<CardHeaderDivider />
			</ConditionalRender>
		</>
	)
}

export default CardHeader
