// @flow
import React from 'react'
import enums from 'src/configs/enums'
import ConfigTools from 'src/configs/ConfigTools'
import SelectAccountingCodeDropdown from 'src/components/AccountingCodes/Dropdowns/SelectAccountingCodeDropdown'
import SelectVendorDropdown from 'src/components/Vendors/SelectVendor/SelectVendorDropdown/SelectVendorDropdown'
import { CategoryDropdown } from 'src/components/Categories/Dropdowns/CategoryDropdown'

const inputProps = {
	containerClassName: 'cm-value-type-dropdowns',
	placeholder: 'Value',
	required: true,
	menuPortalTarget: document.getElementById('react-select-portal-modals'),
}

export default ConfigTools({
	OPERATOR_TYPE_INCLUDE: {
		value: 0,
		label: 'Include',
		dropdownComponent: () => <></>,
	},
	OPERATOR_TYPE_EXCLUDE: {
		value: 1,
		label: 'Exclude',
		dropdownComponent: <></>,
	},
	PROPERTY_TYPE_CHART_OF_ACCOUNT: {
		value: 2,
		label: 'Chart of Accounts',
		dropdownComponent: (rest) => (
			<SelectAccountingCodeDropdown
				key={`${rest.name}-product`}
				codeType={enums.accountingCodeType.CHART_OF_ACCOUNT}
				{...inputProps}
				{...rest}
			/>
		),
	},
	PROPERTY_TYPE_LINE_OF_BUSINESS: {
		value: 3,
		label: 'Line of Business',
		dropdownComponent: (rest, locationIds) => (
			<SelectAccountingCodeDropdown
				key={`${rest.name}-line-of-business`}
				codeType={enums.accountingCodeType.LINE_OF_BUSINESS}
				extraFilters={{ locations: locationIds }}
				{...inputProps}
				{...rest}
			/>
		),
	},
	PROPERTY_TYPE_VENDOR: {
		value: 4,
		label: 'Vendor',
		dropdownComponent: (rest) => (
			<SelectVendorDropdown
				key={`${rest.name}-vendor`}
				filters={{ flags: ['vetted-vendors'] }}
				styles={{
					menu: {
						minWidth: '240px',
						right: 'unset',
					},
				}}
				{...inputProps}
				{...rest}
			/>
		),
	},
	PROPERTY_TYPE_CATEGORY: {
		value: 5,
		label: 'Category',
		dropdownComponent: (rest) => (
			<CategoryDropdown
				key={`${rest.name}-category`}
				{...inputProps}
				{...rest}
			/>
		),
	},

	getOperatorTypes: (): Object[] => {
		return [
			enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_INCLUDE,
			enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_EXCLUDE,
		]
	},

	getPropertyTypes: (): Object[] => {
		return [
			enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_CHART_OF_ACCOUNT,
			enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_LINE_OF_BUSINESS,
			enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_VENDOR,
			enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_CATEGORY,
		]
	},

	getVendorPropertyType: (): Object[] => {
		return [enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_VENDOR]
	},

	getAccountingCodeTypeFromEntityType: (entity) => {
		switch (entity) {
			case enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_CHART_OF_ACCOUNT:
				return enums.accountingCodeType.CHART_OF_ACCOUNT
			case enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_LINE_OF_BUSINESS:
				return enums.accountingCodeType.LINE_OF_BUSINESS
			default:
				return null
		}
	},
})
