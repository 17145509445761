// @flow
import Feature from 'src/api/features/_entities/Feature'
import FeatureLimits from 'src/configs/Enums/FeatureLimits'
import FeatureLimitTypes from 'src/configs/Enums/FeatureLimitTypes'

const OrganizationFeature = (organizationFeatureData) => {
	if (!organizationFeatureData) return {}

	const is_active = !!organizationFeatureData?.feature
		? organizationFeatureData?.is_active &&
		  organizationFeatureData?.feature?.is_active
		: false

	const isUnlimited = () => {
		return (
			is_active &&
			organizationFeatureData?.limit === FeatureLimits.unlimited.value &&
			organizationFeatureData?.limit_type ===
				FeatureLimitTypes.unlimited.value
		)
	}

	return {
		id: organizationFeatureData?.id,
		value: organizationFeatureData?.id,
		label: organizationFeatureData?.feature?.label,
		...organizationFeatureData,
		feature: Feature(organizationFeatureData?.feature),
		is_active,
		isUnlimited,
	}
}

export default OrganizationFeature
