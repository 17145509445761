// @flow
import React from 'react'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import MultiSelectLocationGroupsDropdown from 'src/components/LocationGroups/MultiSelectLocationGroupsDropdown'

const WhoFollowOnLocationGroups = () => {
	const { authUser } = useGetAuthenticatedUser()

	return (
		<MultiSelectLocationGroupsDropdown
			name={'location_groups'}
			label={'Choose location group(s)'}
			organization_id={authUser?.organization_id}
			includeAllOption={true}
		/>
	)
}

export default WhoFollowOnLocationGroups
