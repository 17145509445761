// @flow
import React, { memo } from 'react'
import { withMappedProps } from 'src/hocs/withMappedProps'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { mapProps } from 'src/components/_generic/new-modal/footers/map-props'
import type { Props } from 'src/components/_generic/new-modal/footers/types'

const Footer = ({
	render,
	noScrollHover,
	atBottom,
	className,
	...rest
}: Props) => {
	return (
		<ConditionalRender condition={!!render}>
			<footer
				className={`cm-modal-footer${
					atBottom || noScrollHover
						? ' cm-modal-footer-scroll-done'
						: ''
				} ${className}`}
				{...rest}
			>
				{render}
			</footer>
		</ConditionalRender>
	)
}

const RenderFooter = withMappedProps(memo(Footer), mapProps)

export { RenderFooter }
