// @flow
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import useMutateUpsertOrganizationVendor from 'src/api/organization_vendors/useMutateUpsertOrganizationVendor'
import { useShowToast } from 'src/_boot/ToastContext'

const useAddVendor = (closeModal) => {
	const { authUser } = useGetAuthenticatedUser()
	const { showSuccessToast } = useShowToast()
	const { reportError } = useErrorHandler()
	const { mutateAsync: upsertOrganizationVendor, isLoading: loading } =
		useMutateUpsertOrganizationVendor()

	const addVendor = async (vendor, organizationId) => {
		return await upsertOrganizationVendor(
			{
				organization_id: organizationId ?? authUser?.organization?.id,
				vendor_id: vendor?.id,
			},
			{
				onSuccess: (data) => {
					showSuccessToast(
						`We’ve successfully created ${vendor?.name} and added it to your list of vendors. `
					)
					closeModal()
					return data
				},
				onError: (err) => {
					reportError(err)
				},
			}
		)
	}

	return { addVendor, loading }
}

export default useAddVendor
