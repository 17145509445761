// @flow

import { QueryClient } from 'react-query'

export const bankingAuthenticationFailed = (queryClient: QueryClient): void => {
	invalidateBankingInformation(queryClient)
}

export const bankingForbidden = (queryClient: QueryClient): void => {
	window.location = '/dashboard?bankingRemoved=true'
	invalidateBankingInformation(queryClient)
}

const invalidateBankingInformation = (queryClient: QueryClient): void => {
	queryClient.invalidateQueries(['me'])
	queryClient.removeQueries({
		predicate: (query) =>
			query.queryKey[0].startsWith('banking_') ||
			query.queryKey === 'unit',
	})
	window.localStorage.removeItem('unit')
}

export const errorHandlers = {
	'banking-unauthenticated': bankingAuthenticationFailed,
	'banking-forbidden': bankingForbidden,
}
