// @flow
import React, { createElement, memo } from 'react'
import type { Node } from 'react'
import PaddedLayout from 'src/components/_generic/padded-layout'
import SplitLayout from 'src/components/_layouts/split-layout'
import ConditionalRender from 'src/components/_generic/conditional-render'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import EmptyFunc from 'src/library/emptyFunc'

type Props = {
	leftContent?: Node,
	rightContent?: Node,
	leftStyles?: Object,
	rightStyles?: Object,
	showDivider?: boolean,
	clickable?: boolean,
	onClick?: () => any,
}

const SettingsRow = ({
	showDivider = true,
	rightContent,
	clickable = false,
	onClick = EmptyFunc,
	...props
}: Props) => {
	return createElement(
		clickable ? 'button' : 'div',
		{
			onClick,
			className: clickable ? 'tr-clickable' : '',
		},
		<>
			<PaddedLayout>
				<SplitLayout
					{...props}
					rightContent={
						<HorizontalLayout align={'right'}>
							{rightContent}
							<ConditionalRender condition={clickable}>
								<i
									className={
										'cm-icon cm-icon-1-3-em cm-icon-chevron-right ml-2'
									}
								/>
							</ConditionalRender>
						</HorizontalLayout>
					}
				/>
			</PaddedLayout>
			<ConditionalRender condition={showDivider}>
				<hr className={'m-0 p-0'} />
			</ConditionalRender>
		</>
	)
}

export default memo<Props>(SettingsRow)
