// @flow
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { useMemo } from 'react'

const usePaginateArray = (
	list: Array<any>,
	{ page = 1, resultsPerPage = 12 }: QueryModifierType
) => {
	const start = useMemo(() => (page - 1) * resultsPerPage ?? 0, [
		page,
		resultsPerPage,
	])
	const end = useMemo(() => start + resultsPerPage ?? 12, [
		start,
		resultsPerPage,
	])

	const items = useMemo(() => list?.slice(start, end), [list, start, end])

	return {
		items,
		paginatorInfo: {
			currentPage: page,
			lastPage: Math.ceil(list?.length / resultsPerPage),
			perPage: resultsPerPage,
			total: list?.length,
		},
	}
}

export default usePaginateArray
