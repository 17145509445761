// @flow
import { useQuery } from 'src/api/index'
import { GetPermissionsQuery } from 'src/api/permissions/get-permissions-query'

const useGetPermissions = (searchTarget = '') => {
	const { isLoading, error, data } = useQuery({
		key: ['get_permissions', searchTarget],
		request: GetPermissionsQuery,
		options: {
			staleTime: 1000 * 60 * 60,
		},
	})

	const permissions = data ?? []

	return {
		permissions,
		error,
		isLoading,
	}
}

export default useGetPermissions
