export const PermissionUserDataFragment = `
    id
    permission {
        id
        name
        display_name
        detailed_display_name
        description
        type
    }
`
