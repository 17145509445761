// @flow
import useComponentDidMount from 'src/hooks/useComponentDidMount'

const useOfflineReconnect = (cb: () => void) => {
	useComponentDidMount(() => {
		window.addEventListener('online', cb)
		return () => {
			window.removeEventListener('online', cb)
		}
	}, null)
}

export { useOfflineReconnect }
