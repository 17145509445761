// @flow
import { useQuery } from 'src/api/index'
import { GetFavoritesQuery } from 'src/api/products/favorites/get-favorites-query'

export const useGetFavoriteIds = () => {
	const { data: favorite_ids, isLoading } = useQuery({
		key: ['favorite_products'],
		request: GetFavoritesQuery,
		options: {
			staleTime: Infinity,
		},
	})

	return { favorite_ids, isLoading }
}
