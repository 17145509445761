import { gql } from 'graphql-request'
import Organization from 'src/apollo/_entities/organization'

export const GetAuthUserOrganizationOnboardingQuery = {
	identifier: 'get_auth_user_org_onboarding',
	gql: gql`
		query getAuthUserOrganizationOnboarding {
			me {
				organization {
					id
					onboarding {
						id
						status
						completion_video_url
					}
				}
			}
		}
	`,
	formatResults: (data) => {
		return Organization(data?.me?.organization)
	},
}
