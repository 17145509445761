// @flow
import React, { useState } from 'react'
import QuickshopOverlayButton from 'src/components/Store/MarketplaceProductList/Quickshop/QuickshopOverlayButton'
import { useHistory } from 'react-router-dom'
import FavoriteIcon from 'src/components/Lists/CreateList/FavoriteIcon'
import ConditionalRender from 'src/components/_generic/conditional-render'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import ProductImage from 'src/components/Products/_generic/product_image'
import ProductInfo from 'src/components/Store/MarketplaceProductList/product-info'
import MarketplaceProductMultiSelector from 'src/components/Store/MarketplaceProductList/MarketplaceProductMultiSelector'
import VerticalLayout from 'src/components/_layouts/vertical-layout'

type Props = {
	product: ProductType,
	isSibling?: boolean,
	showHover?: boolean,
	renderListAction?: (any) => any,
	displayPrice?: Node,
	onClick?: (any) => any | null,
	withSelects?: boolean,
	hasMoreOptions?: boolean,
}

const ProductGridViewItem = ({
	product,
	isSibling = false,
	showHover = true,
	renderListAction = false,
	displayPrice = <></>,
	onImageClick = null,
	withSelects = false,
	hasMoreOptions = false,
}: Props) => {
	const [isVisible, setIsVisible] = useState(false)
	let history = useHistory()

	const handleClick = () =>
		history.push(`/procure/store/products/${product?.id}`)

	return (
		<>
			<div className='card card-product card-plain'>
				<div className='card-image cm-cursor-pointer'>
					<div
						className='text-center cm-product-grid-img'
						style={Styles.body}
						onMouseEnter={() => setIsVisible(true)}
						onMouseLeave={() => setIsVisible(false)}
					>
						<ProductImage
							product={product}
							shouldLinkToProductPage
							closeModal={onImageClick}
						>
							<ConditionalRender condition={showHover}>
								<QuickshopOverlayButton
									isVisible={isVisible}
									product={product}
								/>
								<ConditionalRender condition={withSelects}>
									<MarketplaceProductMultiSelector
										product={product}
										containerStyles={Styles.selector}
										isVisible={isVisible}
									/>
								</ConditionalRender>
							</ConditionalRender>
							<FavoriteIcon
								product={product}
								containerIsHovered={isVisible}
							/>
							<ConditionalRender
								condition={
									typeof renderListAction === 'function'
								}
							>
								{renderListAction
									? renderListAction(true, product)
									: null}
							</ConditionalRender>
						</ProductImage>
					</div>

					<div
						className='card-body cm-cursor-pointer'
						style={Styles.details}
						onClick={handleClick}
					>
						<ProductInfo product={product} isSibling={isSibling} />
					</div>
				</div>
			</div>
			<div style={Styles.cardFooter}>
				<div className='d-flex justify-content-between align-items-end'>
					<VerticalLayout>
						<p>{displayPrice}</p>
						<button
							className='no-button cm-link cm-subtitle'
							onClick={handleClick}
						>
							{hasMoreOptions ? 'More Options' : ''}
						</button>
					</VerticalLayout>

					<ConditionalRender condition={!!product?.packaging_details}>
						<p
							className={'text-right font-italic'}
							style={Styles.packagingDetails}
						>
							{product?.packaging_details}
						</p>
					</ConditionalRender>
				</div>
			</div>
		</>
	)
}

export default ProductGridViewItem

const Styles = {
	body: {
		height: 200,
		overflow: 'hidden',
		backgroundColor: '#fff',
		borderRadius: 10,
		position: 'relative',
	},
	cardFooter: {
		position: 'absolute',
		bottom: 20,
		left: 20,
		right: 20,
	},
	details: {
		padding: '0px 20px',
	},
	packagingDetails: {
		color: '#9a9a9a',
		fontSize: 14,
	},
	selector: {
		position: 'absolute',
		left: '12px',
		top: '12px',
	},
}
