// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	unpaid: {
		value: 10,
		name: 'Unpaid',
		label: 'Unpaid',
		color: '#535E6B',
		backgroundColor: '#ECEEF0',
	},
	draft: {
		value: 20,
		name: 'Draft',
		label: 'Draft',
		color: '#535E6B',
		backgroundColor: '#ECEEF0',
	},
	approving: {
		value: 30,
		name: 'Approving',
		label: 'Approving',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
	},
	scheduled: {
		value: 40,
		name: 'Scheduled',
		label: 'Scheduled',
		color: '#005285',
		backgroundColor: '#E1F3FF',
	},
	rejected: {
		value: 50,
		name: 'Rejected',
		label: 'Rejected',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
	},
	pending: {
		value: 60,
		name: 'Pending',
		label: 'Pending',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
	},
	canceled: {
		value: 70,
		name: 'Canceled',
		label: 'Canceled',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
	},
	clearing: {
		value: 80,
		name: 'Clearing',
		label: 'Clearing',
		color: '#262C32',
		backgroundColor: '#F8E9C1',
	},
	returned: {
		value: 90,
		name: 'Returned',
		label: 'Returned',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
	},
	partiallyCompleted: {
		value: 100,
		name: 'Partially Completed',
		label: 'Partially Completed',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
	},
	paid: {
		value: 110,
		name: 'Paid',
		label: 'Paid',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
	},
	markedPaid: {
		value: 120,
		name: 'Marked Paid',
		label: 'Marked Paid',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
	},
	getEditableStatusValues: function () {
		return [this.unpaid.value, this.draft.value, this.scheduled.value]
	},
	getDeletableStatusValues: function () {
		return [this.unpaid.value, this.draft.value, this.scheduled.value]
	},
	getPayableStatusesForBill: function () {
		return [this.unpaid, this.canceled, this.returned, this.rejected]
	},
	getManuallySettableStatuses: function () {
		return [this.unpaid, this.markedPaid]
	},
})
