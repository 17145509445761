// @flow
import {
	InMemoryCache,
	IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import AppConfigs from 'src/configs/app'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { Auth } from 'aws-amplify'
import introspectionQueryResultData from 'src/apollo/introspection-fragment-data.json'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
})

const cache = new InMemoryCache({ fragmentMatcher, addTypename: true })

const httpLink = createHttpLink({
	uri: AppConfigs.graphQLUrl,
	errorPolicy: 'all',
	headers: {
		'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
	},
})

const authLink = setContext(async (_, { headers }) => {
	// return the headers to the context so httpLink can read them
	try {
		const session = await Auth.currentSession()
		const token = session?.getAccessToken()?.getJwtToken()

		return {
			headers: {
				...headers,
				Authorization: token ? `Bearer ${token}` : undefined,
			},
		}
	} catch (error) {
		return {
			headers,
		}
	}
})

const defaultOptions = {
	mutation: {
		errorPolicy: 'all',
	},
	query: {
		errorPolicy: 'all',
	},
}

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache,
	defaultOptions,
})

export default client
