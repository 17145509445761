// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	contact: {
		value: 10,
		name: 'Business Contact',
		label: 'Business Contact',
	},
	authorizedUser: {
		value: 20,
		name: 'Authorized User',
		label: 'Authorized User',
	},
})
