import { gql } from 'graphql-request'
import { getFragmentBodies } from 'src/api/index'

export default {
	getOrganizationEntityMappingCounts: (fragments = [], body = '') => ({
		identifier: 'org_entity_map_counts',
		gql: gql`
            query getOrganizationEntityMappingCounts($organizationEntityId : ID!) {
                getOrganizationEntityMappingCounts(organizationEntityId: $organizationEntityId) {
                    ${body}
                    ${getFragmentBodies(fragments)}
                }
            }
            ${fragments.join('\n')}
        `,
		formatResults: (data) => data.getOrganizationEntityMappingCounts,
	}),
	authorizeAccountingSoftwareIntegration: (fragments = []) => ({
		identifier: 'auth_accounting_software',
		gql: gql`
			mutation authorizeAccountingSoftwareIntegration(
				$organization_entity_id: ID!
				$input: AccountingSoftwareAuthorizationInput!
			) {
				authorizeAccountingSoftwareIntegration(
					organization_entity_id: $organization_entity_id
					input: $input
				)
			}
			${fragments.join('\n')}
		`,
		variables: ({ organization_entity_id, input }) => ({
			organization_entity_id,
			input,
		}),
		formatResults: (data) => data.authorizeAccountingSoftwareIntegration,
		onSuccess: (key, queryClient) => {
			queryClient.invalidateQueries(['organization_entity'])
			queryClient.invalidateQueries(['accounting_software_settings'])
			queryClient.invalidateQueries(['organization_entities'])
			queryClient.invalidateQueries(['get_location_list'])
			queryClient.invalidateQueries(['location'])
			queryClient.invalidateQueries(['location_group'])
			queryClient.invalidateQueries(['location_groups'])
		},
	}),
}
