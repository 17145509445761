// @flow
import React from 'react'
import { useField, useFormikContext } from 'formik'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { FormErrorMessage } from 'src/pages/_styles/text.styles'
import { StyledMultiSelect } from 'src/pages/_styles/form.styles'

type Props = {
	options: any,
	label: string,
	required?: boolean,
	disabled?: boolean,
	loading?: boolean,
	onChange?: () => void,
	onBlur?: () => any,
	closeMenuOnSelect?: boolean,
	labelClassName?: string,
	dropdownHidden?: boolean,
	borderless?: boolean,
}

const MultiSelect = (props: Props) => {
	const [field, meta] = useField(props)
	const { setFieldValue, setFieldTouched } = useFormikContext()
	const {
		options,
		label,
		disabled = false,
		loading = false,
		onChange = () => {},
		closeMenuOnSelect = true,
		labelClassName = 'h6',
		...rest
	} = props
	const { error, touched } = meta

	const handleBlur = (event) => {
		if (props?.onBlur) {
			props.onBlur(event)
		}
		if (field?.onBlur) {
			field.onBlur(event)
		}
		setFieldTouched(field?.name, true)
	}

	const handleChange = (selectedOptions) => {
		setFieldValue && setFieldValue(field.name, selectedOptions)
		onChange && onChange(selectedOptions)
	}

	return (
		<>
			<ConditionalRender condition={!!label}>
				<label className={labelClassName}>{label}</label>
			</ConditionalRender>
			<StyledMultiSelect
				className={`react-select react-select-default ${
					!!(touched && error) ? `cm-select-has-danger` : ''
				}`}
				classNamePrefix='react-select'
				inputProps={{ 'aria-label': label }}
				options={options}
				name={field.name}
				label={label || ''}
				isSearchable
				value={field?.value}
				onChange={handleChange}
				onBlur={handleBlur}
				onFocus={props?.onFocus}
				isDisabled={disabled || loading}
				isMulti={true}
				closeMenuOnSelect={closeMenuOnSelect}
				styles={{
					control: (base) => {
						if (!!props?.borderless) {
							return { ...base, border: 'none !important' }
						}

						return base
					},
					valueContainer: (base) => {
						if (!!props?.borderless) {
							return { ...base, padding: '0 !important' }
						}

						return base
					},
					dropdownIndicator: (base) => {
						if (!!props?.dropdownHidden) {
							return { ...base, visibility: 'hidden' }
						}
						return base
					},
					multiValue: (base) => {
						const multiValueStyles = {
							...base,
							border: `2px solid var(--color-brand-blue) !important`,
							backgroundColor:
								'var(--color-brand-blue) !important',
						}

						if (!!props?.borderless)
							return {
								...multiValueStyles,
								marginLeft: '0px !important',
								marginRight: '4px !important',
							}

						return multiValueStyles
					},
					multiValueLabel: (base) => ({
						...base,
						color: '#fff !important',
						whiteSpace: 'normal',
					}),
					multiValueRemove: (base) => ({
						...base,
						color: '#fff !important',
						borderLeft: '1px solid #fff !important',
					}),
				}}
				{...rest}
			/>
			<ConditionalRender condition={touched && error}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</ConditionalRender>
		</>
	)
}

export default MultiSelect
