// @flow
import React from 'react'
import StatusTextLabel from 'src/components/_generic/labels/status-text-label'
import enums from 'src/configs/enums'
import useStockStatusColor from 'src/components/Products/_hooks/useStockStatusColor'

type Props = {
	status: number,
	shorthandLabel?: string,
}

const ProductStockStatusText = ({ status, shorthandLabel = false }: Props) => {
	const { getProductStockStatusHex } = useStockStatusColor(status)

	return (
		<>
			<StatusTextLabel
				color={getProductStockStatusHex()}
				label={
					shorthandLabel
						? enums.PRODUCT_STOCK_STATUS.getShorthandHumanReadable(
								status
						  )
						: enums.PRODUCT_STOCK_STATUS.getHumanReadable(status)
				}
			/>
		</>
	)
}

export default ProductStockStatusText
