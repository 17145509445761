// @flow
import { useQuery } from 'src/api/index'
import Price from 'src/apollo/_entities/price'
import { GetPricesForProductQuery } from 'src/api/prices/get-prices-for-product'

export const useGetPricesForProduct = (product_id) => {
	const { data: priceData, ...rest } = useQuery({
		key: ['product-prices', { product_id }],
		request: GetPricesForProductQuery,
		options: {
			enabled: !!product_id,
			staleTime: 1000 * 60 * 10,
		},
	})

	const prices = priceData?.map((price) => Price(price)) ?? []

	const live_prices = prices?.filter((price) => price?.is_live) ?? []
	const managed_prices = prices?.filter((price) => price?.is_managed) ?? []
	const loaded_prices =
		prices?.filter((price) => !price?.is_managed && !price?.is_live) ?? []

	return { prices, live_prices, loaded_prices, managed_prices, ...rest }
}
