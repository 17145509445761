// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	no_errors: {
		value: 0,
		name: 'No Errors',
		label: 'No Errors',
		color: 'var(--status-label-default)',
	},
	proactive: {
		value: 1,
		name: 'Proactive',
		label: 'Proactive',
		color: 'var(--status-label-default)',
	},
	reactive: {
		value: 2,
		name: 'Reactive',
		label: 'Reactive',
		color: 'var(--status-label-default)',
	},
})
