// @flow

const Category = (categoryData) => {
	if (!categoryData) return null

	return {
		value: categoryData?.id,
		label: categoryData?.name,
		...categoryData,
	}
}

export default Category
