// @flow
import enums from 'src/configs/enums'

export default function useStockStatusColor(status) {
	const getProductStockStatusHex = () => {
		switch (status) {
			case enums.PRODUCT_STOCK_STATUS.IN_STOCK:
				return '#26e077'
			case enums.PRODUCT_STOCK_STATUS.OUT_OF_STOCK:
			case enums.PRODUCT_STOCK_STATUS.BACKORDERED:
				return '#f5593d'
			case enums.PRODUCT_STOCK_STATUS.DISCONTINUED:
			case enums.PRODUCT_STOCK_STATUS.UNKNOWN:
				return '#6c757d'
			default:
				return '#6c757d'
		}
	}

	const getStockLevelStatusColor = () => {
		switch (status) {
			case enums.PRODUCT_STOCK_STATUS.IN_STOCK:
				return 'text-success'
			case enums.PRODUCT_STOCK_STATUS.OUT_OF_STOCK:
			case enums.PRODUCT_STOCK_STATUS.BACKORDERED:
				return 'text-danger'
			case enums.PRODUCT_STOCK_STATUS.DISCONTINUED:
			case enums.PRODUCT_STOCK_STATUS.UNKNOWN:
				return '#6c757d'
			default:
				return '#6c757d'
		}
	}

	return { getProductStockStatusHex, getStockLevelStatusColor }
}
