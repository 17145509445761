// @flow
import React from 'react'
import SplitLayout from 'src/components/_layouts/split-layout'
import EmptyFunc from 'src/library/emptyFunc'
import Spacer from 'src/components/_generic/spacer'
import { CancelSubmitButton } from 'src/components/_generic/new-modal/NewModal.styles'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	submitTitle?: string,
	cancelTitle?: string,
	submitButtonType?: string,
	onCancel?: () => any,
	onSubmit?: (any) => any,
	disabled?: boolean,
	isWarning?: boolean,
	isDanger?: boolean,
	additionalAction?: Node,
}

const CancelSubmitButtonFooter = ({
	submitTitle = 'Submit',
	cancelTitle = 'Cancel',
	submitButtonType = 'submit',
	onCancel = EmptyFunc,
	onSubmit = EmptyFunc,
	disabled = false,
	isWarning = false,
	isDanger = false,
	additionalAction,
	...rest
}: Props) => {
	let submitColor = 'primary'
	if (isWarning) submitColor = 'warning'
	if (isDanger) submitColor = 'danger'

	return (
		<SplitLayout
			leftContent={null}
			rightContent={
				<>
					<CancelSubmitButton
						color={'default'}
						type={'button'}
						onClick={onCancel}
						invert
						title={cancelTitle}
						rounded={false}
					/>
					<Spacer row size={8} />
					<ConditionalRender condition={!!additionalAction}>
						{additionalAction}
						<Spacer row size={8} />
					</ConditionalRender>
					<CancelSubmitButton
						color={submitColor}
						type={submitButtonType}
						onClick={onSubmit}
						disabled={disabled}
						className={
							disabled
								? `disabled-checkout-next-btn`
								: `cm-legacy-btn-${submitColor}`
						}
						title={submitTitle}
						rounded={false}
					/>
				</>
			}
			{...rest}
		/>
	)
}

export default CancelSubmitButtonFooter
