// @flow
import React from 'react'
import MoneyFormatter from 'src/library/MoneyFormatter'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { useField } from 'formik'

type Props = {
	option: Object,
	onChange?: (number) => any,
}

const CartPriceOption = ({ option, onChange = () => {} }: Props) => {
	const [{ value: loaded_price }, { touched, error }, { setValue }] =
		useField('loaded_price')

	const handlePriceChanged = (value) => {
		const newValue = MoneyFormatter.toPennies(value.floatValue)
		setValue(newValue)
		onChange(newValue)
	}

	return (
		<div
			className={!!(touched && error) ? 'cm-has-danger' : 'cm-frame'}
			style={Styles.frame}
		>
			<div className='d-flex flex-row justify-content-between align-content-center'>
				<p
					className='align-self-center text-overflow-ellipsis'
					style={Styles.labelContainer}
				>
					{option?.label}
				</p>
				<div style={Styles.inputGroupContainer}>
					<InputGroup>
						<InputGroupAddon addonType='prepend'>
							<InputGroupText>
								<i className='cm-icon cm-icon-dollar-bold' />
							</InputGroupText>
						</InputGroupAddon>
						<NumberFormat
							name={`loaded_price`}
							thousandSeparator={false}
							decimalScale={2}
							value={MoneyFormatter.toDollars(loaded_price)}
							onValueChange={handlePriceChanged}
							className={'form-control text-right'}
						/>
					</InputGroup>
				</div>
			</div>
		</div>
	)
}

export default CartPriceOption

const Styles = {
	labelContainer: {
		flex: 1,
		minWidth: '90px',
	},
	inputGroupContainer: {
		width: 100,
		marginLeft: 20,
		flexGrow: 1,
	},
	frame: {
		padding: '12px',
	},
}
