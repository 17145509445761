// @flow
import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	channel: {
		sms: {
			value: 10,
			name: 'Text Message',
			label: 'Text Message',
		},
		phone: {
			value: 20,
			name: 'Phone Call',
			label: 'Phone Call',
		},
	},
})
