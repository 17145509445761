// @flow
import React, { Suspense, useEffect, useMemo } from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import AppLayout from 'src/navigation/_layouts/AppLayout'
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	withRouter,
} from 'react-router-dom'
import routes from 'src/navigation/routes'
import AppConfigs, { ANALYTICS_CONFIGS, LD_CLIENT_ID } from 'src/configs/app'
import PageTitle from 'src/components/_generic/page-title'
import PermissionRender from 'src/components/_generic/permission-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import ErrorPage from 'src/components/_generic/errors/error-page'
import DevFixture from 'src/library/DevFixture/DevFixture'
import { useGetAuthUserOrgOnboarding } from 'src/api/users/useGetAuthUserOrgOnboarding'
import { useQueryClient } from 'react-query'
import { withQuickshopModal } from 'src/components/Store/MarketplaceProductList/Quickshop/withQuickshopModal'
import useInitLaunchDarkly from 'src/_boot/useInitLaunchDarkly'
import { SubscriptionProvider } from 'src/_boot/SubscriptionContext'
import { PusherProvider } from 'src/_boot/PusherContext'
import { ToastProvider } from 'src/_boot/ToastContext'
import ModuleRender from 'src/components/_generic/module-render'
import ConditionalRender from 'src/components/_generic/conditional-render'
import isNullOrUndefined from 'src/library/isNullOrUndefined'
import mixpanel from 'mixpanel-browser'
import Hotjar from '@hotjar/browser'
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'

const App = ({ location }) => {
	const hostname = useMemo(
		() => window.location.hostname ?? '',
		[window.location.hostname]
	)
	const history = useHistory()
	const { authUser } = useGetAuthenticatedUser()
	const { organization } = useGetAuthUserOrgOnboarding()
	const queryClient = useQueryClient()
	const { reportError } = useErrorHandler()

	useInitLaunchDarkly()

	useEffect(() => {
		try {
			if (hostname === 'app.curemint.io') {
				if (!!authUser?.id) {
					// init mixpanel
					mixpanel.init(ANALYTICS_CONFIGS.mixpanelProjectToken, {
						debug: true,
						track_pageview: true,
						persistence: 'localStorage',
					})
					mixpanel.identify(authUser.id)
				}

				// init hotjar
				Hotjar.init(ANALYTICS_CONFIGS.hotjarProjectId, 6)
			}
		} catch (error) {
			reportError(error)
		}
	}, [hostname, authUser?.id])

	useEffect(() => {
		if (
			location?.pathname?.match('/pay/') &&
			[...location?.pathname?.match('/pay/')][0] === '/pay/'
		) {
			let newPath = location?.pathname?.replace('pay', 'payables')
			history.push(newPath)
		}
	}, [])

	const renderRoute = (key, route, props) => {
		queryClient.setQueryData('cm-dev-tools', { page: { component: key } })

		const shouldOverridePermissions =
			route.permissionOverride &&
			route.permissionOverride(authUser, props)

		if (
			organization &&
			!organization?.onboardingComplete() &&
			route?.path !== '/onboarding' &&
			!(
				authUser?.isCuremintAdmin() ||
				authUser?.isTranscriber() ||
				authUser?.isValidator() ||
				authUser?.isManager()
			)
		) {
			return <Redirect to={'/onboarding'} />
		}

		return (
			<AppLayout>
				<ModuleRender
					modules={route.modules}
					failedRender={
						<ConditionalRender
							condition={!isNullOrUndefined(authUser)}
						>
							<ErrorPage type={'Unauthorized'} />
						</ConditionalRender>
					}
				>
					<PermissionRender
						permissions={route.permissions}
						overridePermissions={shouldOverridePermissions}
						failedRender={<ErrorPage type={'Unauthorized'} />}
					>
						<PageTitle title={route.title} />
						<route.component
							{...props}
							{...route.props}
							title={route?.title}
						/>
					</PermissionRender>
				</ModuleRender>
			</AppLayout>
		)
	}

	return (
		<PusherProvider>
			<SubscriptionProvider>
				<ToastProvider>
					<Suspense fallback={<AppLayout />}>
						<Switch>
							<Route exact path={`/dev`} component={DevFixture} />

							<Switch>
								{routes.keyMap(([key, route]) => (
									<Route
										key={key}
										exact
										path={`${AppConfigs.appPrefix}${route?.path}`}
										render={(props) =>
											renderRoute(key, route, props)
										}
									/>
								))}
								<Route path={'*'}>
									<ErrorPage type={'NoPage'} />
								</Route>
							</Switch>
						</Switch>
					</Suspense>
				</ToastProvider>
			</SubscriptionProvider>
		</PusherProvider>
	)
}

export default withLDProvider({
	clientSideID: LD_CLIENT_ID,
})(withQuickshopModal(withRouter(App)))
