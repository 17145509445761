// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import CartPriceOption from 'src/components/Products/Prices/_components/Options/CartPriceOption'

type Props = {
	has_price: boolean,
	isOrgAdmin: boolean,
}

const NoPriceOption = ({ has_price, isOrgAdmin }: Props) => {
	return (
		<ConditionalRender condition={!has_price && !isOrgAdmin}>
			<CartPriceOption option={{ label: 'Enter Price' }} />
		</ConditionalRender>
	)
}

export default NoPriceOption
