// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	VENDOR: {
		name: 'vendor',
		label: 'Vendor',
	},
	ORGANIZATION: {
		name: 'organization',
		label: 'Organization',
	},
	ORDER: {
		name: 'order',
		label: 'Order',
	},
})
