// @flow
import React from 'react'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import { EmptyFunc } from 'src/library/index'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	buttonTitle: string,
	message: string,
	onClick?: (any) => any,
	showButton?: boolean,
}

const ProductDetailsNoticeAction = ({
	buttonTitle,
	message,
	onClick = EmptyFunc,
	showButton = false,
}: Props) => {
	return (
		<div style={Styles.container}>
			<div style={Styles.message}>
				<p style={Styles.paragraph}>{message}</p>
			</div>
			<ConditionalRender condition={showButton}>
				<PrimaryButton
					title={buttonTitle}
					className={'btn-block'}
					rounded={false}
					onClick={onClick}
				/>
			</ConditionalRender>
		</div>
	)
}

export default ProductDetailsNoticeAction

const Styles = {
	message: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		border: '1px solid #d3d3d3',
		borderRadius: '5px',
		marginBottom: '20px',
		padding: '10px',
	},
	paragraph: {
		width: '80%',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		margin: '20px 20px 20px 20px',
		justifyContent: 'center',
		flex: 1,
	},
}
