// @flow
import { useMutation } from 'src/api/index'
import { UpsertOrganizationVendorMutation } from 'src/api/organization_vendors/upsert-organization-vendor-mutation'

const useMutateUpsertOrganizationVendor = () => {
	return useMutation({
		key: 'upsert_organization_vendor',
		request: UpsertOrganizationVendorMutation,
	})
}

export default useMutateUpsertOrganizationVendor
