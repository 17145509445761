// @flow

import OrganizationVendor from 'src/apollo/_entities/organizationVendor'

const Vendor = (vendorData) => {
	if (!vendorData) return null

	return {
		...vendorData,
		value: vendorData?.id,
		label: vendorData?.name,
		organization_vendor: OrganizationVendor({
			...vendorData?.organization_vendor,
			vendor: {
				...vendorData,
				...vendorData?.organization_vendor?.vendor,
			},
		}),
	}
}

export default Vendor
