// @flow
import type { Node } from 'react'
import React from 'react'
import { Formik } from 'formik'

type Props = {
	children?: Node,
	validator?: Object,
	initialValues?: Object,
	enableReinitialize?: boolean,
	onSubmit: (Object) => any,
	asyncValidator?: (any) => Promise<any>,
	validateOnChange?: boolean,
	validateOnBlur?: boolean,
}

/**
 * @deprecated Please use Formik directly instead
 */
export default function Form({
	children,
	validator = null,
	initialValues = {},
	onSubmit,
	enableReinitialize = true,
	asyncValidator,
	validateOnChange = false,
	validateOnBlur = false,
	...rest
}: Props) {
	return (
		<Formik
			initialValues={initialValues}
			validate={asyncValidator}
			validationSchema={validator}
			onSubmit={onSubmit}
			enableReinitialize={enableReinitialize} // added so we can update initialValues on a form
			validateOnChange={validateOnChange}
			validateOnBlur={validateOnBlur}
			{...rest}
		>
			{(props) => <form onSubmit={props.handleSubmit}>{children}</form>}
		</Formik>
	)
}
