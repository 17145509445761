// @flow
import React from 'react'
import Page from 'src/components/_generic/pages/page'
import {
	BaseErrorText,
	BaseErrorTitle,
	ErrorCard,
	ErrorCardBody,
} from 'src/components/_generic/errors/error-page.styles'

type Props = {
	type?: string,
}

const ErrorPage = ({ type = 'NoPage' }: Props) => {
	const error =
		type === 'Unauthorized'
			? {
					title: 'Access Denied',
					message: 'You do not have permission to access this page.',
			  }
			: {
					title: 'Page Not Found',
					message: "We couldn't find the page you're looking for.",
			  }

	return (
		<Page>
			<ErrorCard noBodyPadding>
				<ErrorCardBody>
					<BaseErrorTitle>{error.title}</BaseErrorTitle>
					<BaseErrorText>{error.message}</BaseErrorText>
				</ErrorCardBody>
			</ErrorCard>
		</Page>
	)
}

ErrorPage.defaultProps = require('./static').staticErrorPageDefaults

export default ErrorPage
