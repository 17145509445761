// @flow
import { useQuery } from 'src/api/index'
import { FetchStockStatusQuery } from 'src/api/products/fetch-stock-status-query'

export const useFetchStockStatus = (productId) => {
	const { data: stockStatus, ...rest } = useQuery({
		key: ['fetch_stock_status', { product_id: '' + productId }],
		request: FetchStockStatusQuery,
		options: {
			enabled: !!productId,
			staleTime: 1000 * 60 * 5,
		},
	})
	return { stockStatus, ...rest }
}
