// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { FormLabel } from 'src/pages/_styles/form.styles'

type Props = {
	children?: Node,
	required?: boolean,
}

const InputLabel = ({ children, required = false }: Props) => {
	return (
		<>
			<FormLabel>{children}</FormLabel>
			<ConditionalRender condition={!required}>
				<span className='ml-1 cm-subtext-italic'>(optional)</span>
			</ConditionalRender>
		</>
	)
}

export default InputLabel
