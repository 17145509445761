// @flow

const handleVariableType = (
	variable: (a?: any, b?: any) => any,
	args?: any,
	index?: number
) => {
	if (typeof variable === 'function') {
		return variable(args, index)
	} else {
		return variable
	}
}
// parseInt wrapper for px converting
const cvpx = (value: number | string, rdx: number = 10): number =>
	parseInt(value, rdx)

function safeArrayMap<T>(data: T[], cb: any): T[] {
	return data?.map(cb) ?? []
}

export { handleVariableType, cvpx, safeArrayMap }
