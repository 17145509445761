// @flow
import React from 'react'
import IconButton from 'src/components/_generic/buttons/icon-button'
import EmptyFunc from 'src/library/emptyFunc'

type Props = {
	onClick?: (any) => any,
	style?: Object,
}

const CloseIconButton = ({ onClick = EmptyFunc, style, ...rest }: Props) => {
	return (
		<IconButton
			icon={
				<span className={'text-default'}>
					<i className={'cm-icon cm-icon-close'} />
				</span>
			}
			onClick={onClick}
			color={'transparent'}
			styles={style}
			{...rest}
		/>
	)
}

export default CloseIconButton
