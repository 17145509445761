// @flow
import { gql } from 'graphql-request'
import Product from 'src/apollo/_entities/product'
import { ProductListDataFragment } from 'src/api/products/_fragments/_product_list_data_fragment'
import { PaginatorInfoFragment } from 'src/api/_generic/_fragments/_paginator_info_fragment'

export const GetProductListQuery = {
	identifier: 'get_product_list',
	gql: gql`
		query products($modifiers: QueryModifierInput!) {
            products(modifiers: $modifiers) {
                data {
                    ... on Product {
                        ${ProductListDataFragment}
                    }
                }
				${PaginatorInfoFragment}
			}
		}
	`,
	formatResults: (data) => {
		const products =
			data?.products?.data?.map((product) => Product(product)) ?? []
		const paginatorInfo = data?.products?.paginatorInfo

		return { products, paginatorInfo }
	},
}
