// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetManyVendors } from 'src/api/vendors/useGetManyVendors'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrganizationActiveVendorsPills = ({
	filters,
	setFilters,
}: Props) => {
	const { vendors: organizationActiveVendors } = useGetManyVendors(
		filters?.organizationActiveVendors
	)

	const handleFilterRemove = (filter) => {
		let organizationActiveVendorFilters = filters?.organizationActiveVendors?.filter(
			(vendor) => '' + vendor !== '' + filter
		)
		setFilters({
			...filters,
			organizationActiveVendors: organizationActiveVendorFilters,
		})
	}

	let vendorPills = organizationActiveVendors?.filter((vendor) =>
		filters?.organizationActiveVendors?.includes(vendor?.id)
	)

	return (
		vendorPills?.map((vendor) => {
			return (
				<FilterPill
					filter={vendor?.name}
					filterType={'VENDOR'}
					onRemove={() => handleFilterRemove(vendor?.id)}
					key={`vendor-badge-${vendor?.id}`}
				/>
			)
		}) ?? null
	)
}

export default RenderOrganizationActiveVendorsPills
