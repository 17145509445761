// @flow

export default {
	UNKNOWN: 0,
	ORDER_DENIAL_REASON: 10,
	OVERRIDE_THREE_WAY_MATCH_REASON: 20,
	REJECTED_BILL_REASON: 30,
	TRANSCRIBER_NOTE: 40,

	UNKNOWN_HUMAN_READABLE: 'Unknown',
	ORDER_DENIAL_REASON_HUMAN_READABLE: 'denied this order',
	OVERRIDE_THREE_WAY_MATCH_REASON_HUMAN_READABLE:
		'overrode strict 3-way-match',
	REJECTED_BILL_REASON_HUMAN_READABLE: 'rejected this bill',
}
