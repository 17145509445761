import { gql } from 'graphql-request'

export const GetLocationGroupListQuery = {
	identifier: 'get_location_group_list',
	gql: gql`
		query location_groups(
			$modifiers: QueryModifierInput
			$organization_id: ID
		) {
			location_groups(
				modifiers: $modifiers
				organization_id: $organization_id
			) {
				data {
					... on LocationGroup {
						id
						name
					}
				}

				paginatorInfo {
					currentPage
					lastPage
					perPage
					total
				}
			}
		}
	`,
	formatResults: (data) => {
		const location_groups = data?.location_groups?.data ?? []
		const paginatorInfo = data?.location_groups?.paginatorInfo

		return { location_groups, paginatorInfo }
	},
}
