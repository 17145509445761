import isNullOrUndefined from 'src/library/isNullOrUndefined'
import type { PriceOptionType } from 'src/flowtypes/Objects/PriceOptionType'

export default function (
	option1: PriceOptionType,
	option2: PriceOptionType
): number {
	if (isNullOrUndefined(option1.price)) {
		return 1
	}
	if (isNullOrUndefined(option2.price)) {
		return -1
	}

	return option1.price - option2.price
}
