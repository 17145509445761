// @flow
import { useEffect, useState } from 'react'
import EmptyFunc from 'src/library/emptyFunc'

export default function useComponentDidMount(
	onMount = EmptyFunc,
	onUnMount = EmptyFunc
) {
	const [status, setStatus] = useState('not-mounted')

	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount()
			setStatus('mounted')
		}
		return () => {
			if (onUnMount && typeof onUnMount === 'function') {
				onUnMount()
				setStatus('unmounted')
			}
		}
	}, [])

	return {
		status,
		mounted: status === 'mounted',
		unmounted: status === 'unmounted',
	}
}
