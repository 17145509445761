// @flow
import React from 'react'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import { Badge } from 'reactstrap'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	filterType?: string,
	filter: string,
	onRemove: () => any,
}

const FilterPill = ({ filterType = '', filter, onRemove }: Props) => {
	return (
		<Badge pill className={'mr-2 cm-filter-pill'}>
			<HorizontalLayout>
				<ConditionalRender condition={!!filterType}>
					{filterType}:{' '}
				</ConditionalRender>
				{filter}
				<i
					className={
						'cm-icon cm-icon-d-remove cm-cursor-pointer ml-2 cm-filter-pill-close-button'
					}
					onClick={onRemove}
				/>
			</HorizontalLayout>
		</Badge>
	)
}

export default FilterPill
