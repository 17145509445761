// @flow

const LocationGroup = (locationGroupData) => {
	if (!locationGroupData) return null

	return {
		...locationGroupData,
		value: locationGroupData?.id,
		label: `${locationGroupData?.name}`,
		link: `/admin/location_groups/${locationGroupData?.id}`,
	}
}

export default LocationGroup
