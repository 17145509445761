import { useComponentDidMount } from 'src/hooks/index'
import { useContext, useEffect, useState } from 'react'
import { PusherContext } from 'src/_boot/PusherContext'

const usePrivatePusherChannel = (
	name: string,
	listeners = {},
	prefix = 'private'
) => {
	const privateName = `${prefix}-${name}`
	const [channel, setChannel] = useState()
	const pusherClient = useContext(PusherContext)

	useComponentDidMount(
		() => {
			const channel = pusherClient?.subscribe(privateName)
			setChannel(channel)

			window.onbeforeunload = function () {
				return pusherClient?.unsubscribe(privateName)
			}
		},
		() => {
			return pusherClient?.unsubscribe(privateName)
		}
	)

	useEffect(() => {
		if (channel) {
			for (const [key, listener] of Object.entries(listeners)) {
				channel.bind(key, listener)
			}
		}
	}, [channel?.name])

	return { channel }
}

export default usePrivatePusherChannel
