// @font

import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

export const Icon: FontAwesomeIconProps = styled(FontAwesomeIcon)`
	${(props) => {
		switch (props?.size) {
			case 'md':
			case 'medium':
				return css`
					font-size: 20px;
				`
			case 'lg':
			case 'large':
				return css`
					font-size: 24px;
				`
			case 'xl':
			case 'extra-large':
				return css`
					font-size: 40px;
				`
			case 'xxl':
			case 'extra-extra-large':
				return css`
					font-size: 56px;
				`
			case 'extraSmall':
			case 'xs':
				return css`
					font-size: 12px;
				`
			case 'sm':
			case 'small':
				return css`
					font-size: 16px;
				`
			default:
				return css`
					font-size: inherit;
				`
		}
	}}
`
