// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetManyLocations } from 'src/api/locations/useGetManyLocations'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderSingleOrgLocationPills = ({ filters, setFilters }: Props) => {
	const handleFilterRemove = (filter) => {
		let activeLocationFilters = filters?.singleOrgLocations?.filter(
			(location) => '' + location !== '' + filter
		)
		setFilters({ ...filters, singleOrgLocations: activeLocationFilters })
	}

	let { locations } = useGetManyLocations(filters?.singleOrgLocations)

	return (
		locations?.map((location) => {
			return (
				<FilterPill
					filter={location?.name}
					filterType={'LOCATION'}
					onRemove={() => handleFilterRemove(location?.id)}
					key={`location-badge-${location?.id}`}
				/>
			)
		}) ?? <></>
	)
}

export default RenderSingleOrgLocationPills
