// @flow
import React from 'react'
import { components } from 'react-select'
import Image from 'src/components/_generic/image'
import s3Image from 'src/library/s3Image'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import VerticalLayout from 'src/components/_layouts/vertical-layout'

type Props = {
	data: {
		image_path: string,
		name: string,
	},
}

const SelectVendorDropdownItem = (props: Props) => {
	const { data } = props
	return (
		<components.Option {...props}>
			<HorizontalLayout>
				<Image
					alt='logo'
					className='img-circle border'
					style={styles.imageStyle}
					src={s3Image(data?.image_path)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
				/>
				<VerticalLayout>
					<div className='text-left'>{data?.name}</div>
				</VerticalLayout>
			</HorizontalLayout>
		</components.Option>
	)
}

export default SelectVendorDropdownItem

const styles = {
	imageStyle: {
		width: 40,
		height: 40,
		marginRight: 20,
		marginBottom: 0,
	},
}
