// @flow
import React from 'react'
import { Modal } from 'reactstrap'
import { useQuickshopModal } from 'src/components/Store/MarketplaceProductList/Quickshop/withQuickshopModal'
import CloseIconButton from 'src/components/_generic/buttons/close-icon-button'
import QuickshopModalLayout from 'src/components/Store/MarketplaceProductList/Quickshop/QuickshopModalLayout'
import ProductGridViewItem from 'src/components/Store/Catalogs/CatalogFilterList/ProductViews/Grid/product-grid-view-item'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import { withAddToCartForm } from 'src/components/Store/MarketplaceProductList/Quickshop/withAddToCartForm'
import ConditionalRender from 'src/components/_generic/conditional-render'
import AddToCartFormUi from 'src/pages/store/StoreProductDetailsPage/_components/Forms/add-to-cart-form-ui'
import ProductDetailsNoticeAction from 'src/components/Store/ProductDetails/ProductDetailsActions/product-details-notice-action'
import { useHistory } from 'react-router-dom'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

const QuickshopLayout = withAddToCartForm(QuickshopModalLayout)

type Props = {
	isOpen: boolean,
	product: ProductType,
	isLoading: boolean,
}

const QuickshopModal = ({ isOpen, product, isLoading }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const { closeModal } = useQuickshopModal()
	const history = useHistory()

	if (!product || isLoading) {
		return null
	}
	const canActivateVendors = authUser?.canActivateVendors()

	const vendorNotice = canActivateVendors
		? 'Purchasing for this vendor has not been configured.  You can configure this vendor on the product details page.'
		: 'Purchasing for this vendor has not been configured.  To configure this vendor contact your organization admin.'

	const handleNavigateToDetails = () => {
		history.push(`/procure/store/products/${product?.id}`)
		closeModal()
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={closeModal}
			className={'cm-quickshop-modal'}
		>
			<QuickshopLayout product={product} closeModal={closeModal}>
				<CloseIconButton lid={'close-button'} onClick={closeModal} />
				<ProductGridViewItem
					lid={'product-grid-item'}
					product={product}
					showHover={false}
					isSibling={false}
					onClick={closeModal}
				/>

				<ConditionalRender
					lid={'product-details-actions'}
					condition={product.canPlaceOrder()}
					failedRender={
						<ProductDetailsNoticeAction
							buttonTitle={'See Product Details'}
							message={vendorNotice}
							showButton={canActivateVendors}
							onClick={handleNavigateToDetails}
						/>
					}
				>
					<AddToCartFormUi product={product} />
				</ConditionalRender>
			</QuickshopLayout>
		</Modal>
	)
}

export default QuickshopModal
