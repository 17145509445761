// @flow
import { gql } from 'graphql-request'
import Formulary from 'src/apollo/_entities/formulary'

export const GetMarketplaceFormulariesQuery = {
	identifier: 'get_marketplace_formularies',
	gql: gql`
		query getMarketplaceFormularies($id: ID) {
			organization(id: $id) {
				id
				active_formularies {
					id
					formulary {
						id
						owner_id
						name
						product_count
					}
				}
			}
		}
	`,
	variables: ({ id }) => ({
		id,
	}),
	formatResults: (data) => {
		return (
			data?.organization?.active_formularies?.map((formulary) =>
				Formulary(formulary)
			) ?? []
		)
	},
}
