// @flow
import { useMutation } from 'src/api/index'
import { useQueryClient } from 'react-query'
import { UpsertPriceMutation } from 'src/api/prices/upsert-price-mutation'
import Price from 'src/apollo/_entities/price'

export const useUpsertPrice = (options = {}) => {
	const queryClient = useQueryClient()

	return useMutation({
		key: 'upsert_price',
		request: UpsertPriceMutation,
		options: {
			onSuccess: async (data) => {
				await UpsertPriceMutation?.onSuccess(
					'upsert_price',
					queryClient,
					data
				)

				if ('onSuccess' in options) {
					await options?.onSuccess(Price(data))
				}

				return queryClient.invalidateQueries(['product_list'])
			},
			onError: options?.onError ?? {},
		},
	})
}
