// @flow

export default {
	/* 10 and 20 are not real statuses on the backend.
	 * We are inflating these values to encapsulate all
	 * One Way matches and Two Way matches into one value
	 * each for filtering by multiple statuses.
	 * */
	NO_MATCH: 0,
	ONE_WAY_MATCH: 10,
	TWO_WAY_MATCH: 20,
	THREE_WAY_MATCH: 6,

	NO_MATCH_HUMAN_READABLE: 'No Match',
	ONE_WAY_MATCH_HUMAN_READABLE: '1-way',
	TWO_WAY_MATCH_HUMAN_READABLE: '2-way',
	THREE_WAY_MATCH_HUMAN_READABLE: '3-way',

	getHumanReadable: function (status: number): string {
		switch (status) {
			case this.ONE_WAY_MATCH:
				return this.ONE_WAY_MATCH_HUMAN_READABLE
			case this.TWO_WAY_MATCH:
				return this.TWO_WAY_MATCH_HUMAN_READABLE
			case this.THREE_WAY_MATCH:
				return this.THREE_WAY_MATCH_HUMAN_READABLE
			default:
				return this.NO_MATCH_HUMAN_READABLE
		}
	},

	getAllMatchStatuses: function () {
		return [
			this.NO_MATCH,
			this.ONE_WAY_MATCH,
			this.TWO_WAY_MATCH,
			this.THREE_WAY_MATCH,
		]
	},
}
