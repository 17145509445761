// @flow
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

export default function useListCanEdit() {
	const { authUser } = useGetAuthenticatedUser()

	const canEditList = (list) => {
		const owner = list?.ownable_type

		if (list?.is_editable) {
			return true
		}

		if (owner === 'organization') {
			return authUser?.isOrganizationAdmin()
		} else if (owner === 'location-group') {
			return (
				authUser?.isAdminOfLocationGroup(list?.ownable_id) ||
				authUser?.isOrganizationAdmin() ||
				authUser?.isCuremintAdmin()
			)
		} else if (owner === 'location') {
			return (
				authUser?.isAdminOfLocation(list?.ownable_id) ||
				authUser?.isAdminOfLocationThroughLocationGroup(
					list?.ownable_id
				) ||
				authUser?.isOrganizationAdmin() ||
				authUser?.isCuremintAdmin()
			)
		} else {
			return authUser?.id === list?.ownable_id
		}
	}

	const getEditableLists = (lists) => {
		let editableLists = []

		for (let i = 0; i < lists?.length; i++) {
			const list = lists[i]
			const canEdit = canEditList(list)

			if (canEdit) {
				editableLists.push(list)
			}
		}

		return editableLists
	}

	return {
		canEditList,
		getEditableLists,
	}
}
