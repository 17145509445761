// @flow
import React, { createContext, useContext, useState } from 'react'
import Form from 'src/components/_generic/forms/form'
import { useUpsertCartItem } from 'src/api/cart_items/useUpsertCartItem'
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'
import Validators from 'src/configs/ValidationModels/marketplace'

const AddedToCartContext = createContext()

export function useAddToCartForm() {
	const addedToCart = useContext(AddedToCartContext)

	return {
		addedToCart,
	}
}

export const withAddToCartForm =
	(Component) =>
	({ cart_item, product, closeModal, ...rest }) => {
		const [addedToCart, setAddedToCart] = useState(false)
		const timerRef = React.useRef()
		const { mutate: upsertCartItem, isLoading } = useUpsertCartItem()
		const { reportError } = useErrorHandler()

		const handleAddToCart = async (values) => {
			await upsertCartItem(
				{
					id: cart_item?.id ?? undefined,
					input: {
						quantity: values?.quantity,
						price_id:
							values?.price_selection?.price_id ?? undefined,
						formulary_id:
							values?.price_selection?.formulary_id ?? undefined,
						product_id: values?.product_id,
						unit_price:
							values?.loaded_price !== undefined
								? values?.loaded_price
								: undefined,
					},
				},
				{
					onSuccess: () => {
						setAddedToCart(true)
						timerRef.current = setTimeout(() => {
							setAddedToCart(false)
							if (closeModal) closeModal()
						}, 1200)
					},
					onError: (err) =>
						reportError(
							err,
							'There was a problem adding this to your cart!'
						),
				}
			)
		}

		return (
			<Form
				validator={Validators.marketplace.quickshop.addToCart}
				onSubmit={handleAddToCart}
				initialValues={{
					quantity: cart_item ? cart_item?.quantity : 1,
					price_id: undefined,
					product_id: product ? `${product?.id}` : undefined,
					loaded_price: undefined,
				}}
			>
				<AddedToCartContext.Provider value={addedToCart}>
					<Component
						product={product}
						addedToCart={addedToCart}
						isLoading={isLoading}
						{...rest}
					/>
				</AddedToCartContext.Provider>
			</Form>
		)
	}
