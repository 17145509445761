// @flow
import React, { useContext, useEffect, useState } from 'react'
import ConnectAccountingSoftwareStepLayout from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_components/ConnectAccountingSoftwareStepLayout'
import Validators from 'src/configs/ValidationModels/accounting_software'
import Input from 'src/components/_generic/forms/input'
import { Formik } from 'formik'
import useExternalFormSubmit from 'src/hooks/useExternalFormSubmit'
import { H3 } from 'src/pages/_styles/text.styles'
import Spacer from 'src/components/_generic/spacer'
import { FlexItem } from 'src/pages/_styles/container.styles'
import { ConnectAccountingSoftwareFlowContext } from 'src/pages/accounting_software/ConnectAccountingSoftwarePage/_contexts/ConnectAccountingSoftwareFlowContext'
import { useComponentDidMount } from 'src/hooks/index'
import { useMutation } from 'src/api/index'
import OrganizationEntitiesApi from 'src/pages/organization_entities/_apis/organization-entities-api'
import PageBanner from 'src/components/_generic/banners/PageBanner'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useIsMutating } from 'react-query'
import { useHistory } from 'react-router-dom'
import { AccountingSoftwareContext } from 'src/pages/entities/_contexts/AccountingSoftwareContext'

const SageIntacctReconnectContent = () => {
	const history = useHistory()
	const [error, setError] = useState(null)
	const {
		setSubmit,
		organizationEntityId,
		providerIdentifier,
		setDisableButtons,
	} = useContext(ConnectAccountingSoftwareFlowContext)
	const { formRef, submitForm } = useExternalFormSubmit()

	const { mutate: authAccountingSoftware } = useMutation({
		key: ['auth_accounting_software'],
		request: OrganizationEntitiesApi.authorizeAccountingSoftwareIntegration(
			[],
			''
		),
	})
	const isMutatingPosts = useIsMutating(['auth_accounting_software'])

	const handleSubmit = (values) => {
		authAccountingSoftware(
			{
				organization_entity_id: organizationEntityId,
				input: {
					company_id: values.company_id,
					user_id: values.user_id,
					password: values.password,
					provider_identifier: providerIdentifier,
				},
			},
			{
				onSuccess: (result) => {
					if (result) {
						setDisableButtons(false)
						history.push(
							`/admin/accounting_software/details/${organizationEntityId}`
						)
					} else {
						setError('login')
					}
				},
				onError: () => {
					setError('unknown')
				},
			}
		)
	}

	useComponentDidMount(() => {
		setSubmit(() => (values) => submitForm(values))
	})

	useEffect(() => {
		setDisableButtons(!!isMutatingPosts)
	}, [isMutatingPosts])

	return (
		<ConnectAccountingSoftwareStepLayout
			title={'Connect to Sage Intacct'}
			styles={Styles.container}
		>
			<p>
				Enter the credentials for the web services user created when
				setting up your integration. To change the Entity or Company ID,
				disconnect the integration and then reconnect with the new
				information.
			</p>
			<Spacer />
			<FlexItem alignSelf={'center'}>
				<AccountingSoftwareContext.Consumer>
					{({ settings }) => (
						<Formik
							validationSchema={
								Validators.accounting_software.sage_intacct
							}
							validateOnBlur
							onSubmit={handleSubmit}
							enableReinitialize
							initialValues={{
								company_id:
									settings?.accounting_software_settings
										?.company_id,
								user_id: null,
								password: null,
							}}
							innerRef={formRef}
						>
							{({ handleSubmit }) => (
								<form onSubmit={handleSubmit}>
									<div style={Styles.form}>
										<H3>Login</H3>
										<ConditionalRender
											condition={error === 'login'}
										>
											<Spacer />
											<PageBanner bannerType={'danger'}>
												<p>
													We were unable to connect
													with Sage Intacct with the
													login information. Please
													try again.
												</p>
											</PageBanner>
										</ConditionalRender>
										<ConditionalRender
											condition={error === 'unknown'}
										>
											<Spacer />
											<PageBanner bannerType={'danger'}>
												<p>
													We're having issues
													connecting with Sage
													Intacct. Please try again
													later.
												</p>
											</PageBanner>
										</ConditionalRender>
										<Spacer />
										<Input
											name={'company_id'}
											label={'Company ID'}
											required
											disabled
										/>
										<Spacer />
										<Input
											name={'user_id'}
											label={'User ID'}
											required
										/>
										<Spacer />
										<Input
											name={'password'}
											label={'Password'}
											type='password'
											required
										/>
									</div>
								</form>
							)}
						</Formik>
					)}
				</AccountingSoftwareContext.Consumer>
			</FlexItem>
		</ConnectAccountingSoftwareStepLayout>
	)
}

export default SageIntacctReconnectContent

const Styles = {
	form: {
		backgroundColor: '#F7F8F9',
		padding: '24px 46px',
		width: '520px',
		border: '1px solid #DADEE3',
		borderRadius: '8px',
	},
	container: {
		height: 'calc(100vh - 155px)',
	},
}
