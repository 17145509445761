export const LocationGroupUserDataFragment = `
    id
    location_group {
        id
        name
        locations_count
        users_count
        locations {
            id
            location {
                id
                name
            }
        }
        users {
            id
            user {
                id
                name
            }
        }
    }
    permission {
        id
        name
        display_name
    }
`
