// @flow

const s3Image = (filename) => {
	if (!!filename) {
		if (filename.includes(process.env.REACT_APP_AWS_S3_BUCKET)) {
			return filename
		}

		return `${
			process.env.REACT_APP_AWS_S3_BUCKET
		}/images/${filename.replace(/ /g, '%20')}`
	}

	return `${process.env.REACT_APP_AWS_S3_BUCKET}/images/logo_placeholder.jpg`
}

export default s3Image
