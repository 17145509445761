// @flow
import { useRef } from 'react'

export default function useExternalFormSubmit() {
	const formRef = useRef()

	const submitForm = () => {
		if (formRef.current) {
			formRef.current.handleSubmit()
		}
	}
	return { formRef, submitForm }
}
