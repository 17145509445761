import React, { createContext, useEffect, useReducer } from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import PriceLoadingSkeleton from 'src/components/Products/Prices/_components/Skeletons/price-loading-skeleton'
import isNullOrUndefined from 'src/library/isNullOrUndefined'
import usePriceOptions from 'src/components/Products/Prices/_hooks/usePriceOptions'
import useSortPriceOptions from 'src/components/Products/Prices/_hooks/useSortPriceOptions'
import useHidePriceOptions from 'src/components/Products/Prices/_hooks/useHidePriceOptions'
import type { PriceOptionType } from 'src/flowtypes/Objects/PriceOptionType'

export type PriceOptionContextProps = {
	options: PriceOptionType[],
	staticOption: PriceOptionType,
	computed?: Object,
}

export const PriceOptionContext = createContext()

export const PriceOptionProvider = ({ product, loadingView, children }) => {
	const [priceState, dispatch] = useReducer(PriceOptionReducer, Initial)
	const { options, isLoading } = usePriceOptions(product)
	const sorted = useSortPriceOptions(options)
	let filtered = useHidePriceOptions(sorted)

	useEffect(() => {
		dispatch({
			type: 'set-options',
			payload: {
				options: filtered,
			},
		})
	}, [JSON.stringify(filtered)])

	return (
		<PriceOptionContext.Provider value={priceState}>
			<ConditionalRender
				condition={!isLoading}
				failedRender={
					loadingView ? (
						loadingView
					) : (
						<PriceLoadingSkeleton isLoading={true} />
					)
				}
			>
				{children}
			</ConditionalRender>
		</PriceOptionContext.Provider>
	)
}

const Initial = {
	options: [],
	staticOption: null,
	computed: {
		has_price: true,
		has_only_static_option: true,
		static_option_has_no_price: true,
	},
}

const getStaticOption = (options) =>
	options.filter((option) => option?.is_static === true).pop()

const compute = (options) => {
	const static_option = getStaticOption(options)
	const has_price = options?.filter(
		(option) => !isNullOrUndefined(option?.price)
	)?.length

	const has_only_static_option = options?.length === 1 && static_option
	const static_option_has_no_price = static_option?.price_id === null

	return {
		has_price,
		has_only_static_option,
		static_option_has_no_price,
	}
}

const PriceOptionReducer = (state, action) => {
	switch (action.type) {
		case 'set-options':
			return {
				...state,
				options: action.payload.options,
				staticOption: getStaticOption(action.payload.options),
				computed: compute(action.payload.options),
			}
		default:
			return state
	}
}
