// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import { useGetCategoriesList } from 'src/api/categories/useGetCategoriesList'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderCategoryPills = ({ filters, setFilters }: Props) => {
	const { data: categories } = useGetCategoriesList()

	const handleFilterRemove = (filter) => {
		let activeCategoryFilters = filters?.categories?.filter(
			(category) => '' + category !== '' + filter
		)
		setFilters({ ...filters, categories: activeCategoryFilters })
	}

	let mapped = categories?.map((category) => {
		return [].concat.apply([category], category?.children)
	})

	let filtered = [].concat
		.apply([], mapped)
		.filter((category) => filters?.categories?.includes(category?.id))

	return filtered?.map((category) => {
		return (
			<FilterPill
				filterType={'CATEGORY'}
				filter={category?.name}
				onRemove={() => handleFilterRemove(category?.id)}
				key={`category-badge-${category?.id}`}
			/>
		)
	})
}

export default RenderCategoryPills
