// @flow
import React from 'react'
import FilterPill from 'src/components/_generic/filters/filter-pill'
import enums from 'src/configs/enums'

type Props = {
	filters: [],
	setFilters: () => any,
}

const RenderOrderRulesWhoTypePills = ({ filters, setFilters }: Props) => {
	const orderRuleWhoTypes = enums.ORDER_RULE_COMPONENT_TYPE.getWhoComponentTypes()

	const handleFilterRemove = (filter) => {
		let activeWhoTypeFilters = filters?.whos?.filter(
			(who) => '' + who !== '' + filter
		)
		setFilters({ ...filters, whos: activeWhoTypeFilters })
	}

	let whoPills = orderRuleWhoTypes?.filter((who) =>
		filters?.whos?.includes(who.toString())
	)

	return whoPills?.map((who) => {
		return (
			<FilterPill
				filter={enums.ORDER_RULE_COMPONENT_TYPE.getHumanReadableComponentType(
					who
				)}
				filterType={'WHO'}
				onRemove={() => handleFilterRemove(who)}
				key={`order-rules-who-badge-${who}`}
			/>
		)
	})
}

export default RenderOrderRulesWhoTypePills
