// @flow
import { useQuery } from 'src/api/index'
import { GetMyOrgVendorListQuery } from 'src/api/organization_vendors/get-my-org-vendor-list-query'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import filterNameSearch from 'src/api/organization_vendors/filters/filterNameSearch'
import OrganizationVendor from 'src/apollo/_entities/organizationVendor'

export const useGetMyOrgVendorList = () => {
	const { data: vendors, isLoading } = useQuery({
		key: ['my-org-vendor-lists'],
		request: GetMyOrgVendorListQuery,
		options: {
			staleTime: 1000 * 60 * 10,
		},
	})

	const org_vendors =
		vendors?.map((vendor) => OrganizationVendor(vendor)) ?? []

	const findByVendorId = (vendorId) => {
		return vendors?.find(
			({ vendor }) => String(vendor?.id) === String(vendorId)
		)
	}

	const searchVendors = ({ searchTarget }: QueryModifierType) => {
		return org_vendors?.filter(filterNameSearch(searchTarget))
	}

	return { org_vendors, findByVendorId, searchVendors, isLoading }
}
