import { gql } from 'graphql-request'
import { ManufacturerDataFragment } from 'src/api/manufacturers/fragments/_manufacturer_data_fragment'

export const GetManufacturersByPopularityQuery = {
	identifier: 'manufacturers_by_popularity',
	gql: gql`
		query manufacturersByPopularity($page: Int, $searchTarget: String) {
			manufacturers_by_popularity(page: $page, searchTarget: $searchTarget) {
				data {
					...on Manufacturer {
						${ManufacturerDataFragment}
					}
				}
				paginatorInfo {
					currentPage
					lastPage
					perPage
					total
				}
			}
		}
	`,
	formatResults: (data) => {
		const manufacturers = data?.manufacturers_by_popularity?.data ?? []
		let paginatorInfo = data?.manufacturers_by_popularity
			?.paginatorInfo ?? { lastPage: 1 }

		return { manufacturers, paginatorInfo }
	},
}
