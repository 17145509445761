// @flow
import React from 'react'
import { EmptyTD } from 'src/pages/_components/Tables/_styles/rows.styles'
import PaddedLayout from 'src/components/_generic/padded-layout'

type Props = {
	message?: string | Node,
}

const EmptyTableRow = ({ message, rest }: Props) => {
	return (
		<tr {...rest}>
			<EmptyTD>
				<PaddedLayout className={'text-center'}>
					{typeof message === 'string' ? <p>{message}</p> : message}
				</PaddedLayout>
			</EmptyTD>
		</tr>
	)
}

export default EmptyTableRow
