// @flow
import React from 'react'
import useGetPermissions from 'src/api/permissions/useGetPerimissions'
import EmptyFunc from 'src/library/emptyFunc'
import Select from 'src/components/_generic/forms/select'

type Props = {
	value?: Object,
	onValueChanged?: (Object) => any,
	filterFn?: (Object) => Array<Object>,
	label?: string,
	overridePortal?: boolean,
	name?: any,
	showDetailedDisplayName?: boolean,
	valueIsId?: boolean,
}

const PermissionsDropdown = (props: Props) => {
	const {
		onValueChanged = EmptyFunc,
		filterFn = EmptyFunc,
		label,
		overridePortal = false,
		name = 'permission',
		showDetailedDisplayName = false,
		valueIsId = false,
		...rest
	} = props

	const { permissions } = useGetPermissions()

	const options = permissions
		.filter(filterFn)
		.map((permission) => ({
			...permission,
			value: valueIsId ? permission?.id : permission?.name,
			label: showDetailedDisplayName
				? permission?.detailed_display_name
				: permission?.display_name,
		}))
		.filter((permission) => permission?.label !== 'Observer')

	const handleChange = (value) => {
		onValueChanged(value)
	}

	return (
		<>
			<Select
				label={label}
				menuPortalTarget={
					overridePortal
						? undefined
						: document.getElementById('react-select-portal')
				}
				styles={{
					singleValue: (styles) => ({
						...styles,
						...Styles.singleValue,
					}),
				}}
				onChange={handleChange}
				options={options}
				formInputClass
				placeholder='Access'
				name={name}
				required
				{...rest}
			/>
		</>
	)
}

export default PermissionsDropdown

const Styles = {
	singleValue: {
		color: '#333 !important',
	},
}
