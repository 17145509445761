// @flow
import type { Node } from 'react'
import React, { useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useId } from 'react-id-generator'
import useComponentDidMount from 'src/hooks/useComponentDidMount'

type Props = {
	tooltipContent: string | Node,
	placement?: string,
	children?: Node,
	className?: string,
	CustomElement?: string,
	style?: Object,
}

/*

    This component exists as a solution to the Github issue 773 on the reactstrap/reactstrap project.
    https://github.com/reactstrap/reactstrap/issues/773

    This component controls the render order of tooltip vs it's target to ensure the target is rendered
    first.  It does this by verifying a ref has been assigned to the target prior to rendering the tooltip.
 */
const TooltipWrapper = ({
	tooltipContent,
	placement = 'right',
	children,
	className = '',
	CustomElement = 'span',
	style = {},
}: Props) => {
	const [isReady, setIsReady] = useState(false)
	const [refId] = useId()

	useComponentDidMount(() => {
		if (document.getElementById(refId)) {
			setIsReady(true)
		}
	})

	return (
		<>
			<CustomElement id={refId} className={className} style={style}>
				{children}
			</CustomElement>
			<ConditionalRender condition={isReady}>
				<UncontrolledTooltip placement={placement} target={refId}>
					{tooltipContent}
				</UncontrolledTooltip>
			</ConditionalRender>
		</>
	)
}

export default TooltipWrapper
