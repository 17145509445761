// @flow
import Address from 'src/apollo/_entities/Addresses/address'
import Budget from 'src/apollo/_entities/Budgets/budget'
import AccountingMappable from 'src/apollo/_entities/accountingMappable'

const location_roles = [
	'location-admin',
	'location-purchaser',
	'location-observer',
]

const Location = (locationData) => {
	if (!locationData) return null

	// filter out any location role that is not admin, purchaser, or observer
	// we retain location level access to a user by using a hidden location role called unassigned
	// this needs to be filtered out so it is not displayed on the location or user page.
	const { users, shipping_address, budgets, ...rest } = locationData
	const userList = users?.filter((user) =>
		location_roles.includes(user.permission.name)
	)

	const getSearchString = (): string => {
		return `${locationData?.name ?? ''}${locationData?.location_code ?? ''}`
	}

	const search = (
		needle: string,
		includeShippingAddress: boolean = false
	): boolean => {
		let haystackString = getSearchString()

		if (includeShippingAddress) {
			haystackString =
				haystackString + Address(shipping_address)?.getSearchString()
		}

		return haystackString
			.toLowerCase()
			.includes(needle?.toLowerCase() ?? '')
	}

	return {
		...rest,
		value: locationData?.id,
		label: locationData?.name,
		code: locationData?.location_code,
		codeLabel: locationData?.name,
		accounting_mappables:
			locationData?.accounting_mappables?.map((mappable) =>
				AccountingMappable(mappable)
			) ?? [],
		link: `/admin/locations/${locationData?.id}`,
		users: userList,
		location_code: locationData?.location_code,
		shipping_address: Address(shipping_address),
		budgets: budgets?.map((budget) => Budget(budget)),
		getSearchString,
		search,
	}
}

export default Location
