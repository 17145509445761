// @flow
import type { ProductType } from 'src/flowtypes/Entities/ProductType'

const formatProduct = (product: ProductType) => ({
	label: `${product.sku} - ${product.name}`,
	value: product?.value || String(product?.id),
	...product,
})

export default formatProduct
