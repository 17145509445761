// @flow

export default {
	unlimited: {
		value: -1,
		name: 'Unlimited',
	},
	unavailable: {
		value: 0,
		name: 'Unavailable',
	},
}
