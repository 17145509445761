import { FeatureDataFragment } from 'src/api/features/_fragments/_feature_data_fragment'

export const OrganizationFeatureDataFragment = `
    id
    feature {
        ${FeatureDataFragment}
    }
    organization {
        id
    }
    is_active
    limit
    limit_type
    source
`
