import { gql } from 'graphql-request'
import { ManufacturerDataFragment } from 'src/api/manufacturers/fragments/_manufacturer_data_fragment'

export const GetManyManufacturersQuery = {
	identifier: 'many_manufacturers',
	gql: gql`
        query manyManufacturers($ids: [ID]!) {
            many_manufacturers(ids: $ids) {
                ${ManufacturerDataFragment}
            }
        }
    `,
	formatResults: (data) => {
		return data?.many_manufacturers ?? []
	},
}
