import { gql } from 'graphql-request'

export const GetAllCatalogNames = {
	identifier: 'get_all_catalog_names',
	gql: gql`
		query all_catalog_names($organization_id: ID!) {
			all_catalog_names(organization_id: $organization_id) {
				... on Catalog {
					id
					name
				}
			}
		}
	`,
	variables: ({ organization_id }) => ({ organization_id }),
	formatResults: (data) => {
		return data?.all_catalog_names
	},
}
