// @flow
import React from 'react'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import { useFetchStockStatus } from 'src/api/products/useFetchStockStatus'
import ProductStockStatus from 'src/components/Products/ProductStatus/ProductStockStatus'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { SkeletonText } from 'src/components/_generic/skeletons/index'

type Props = {
	product: ProductType,
	target: string,
	renderShorthandLabel?: boolean,
}

const FetchProductStockStatus = ({
	product,
	target,
	renderShorthandLabel = false,
}: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const { stockStatus, isLoading } = useFetchStockStatus(
		product?.id,
		authUser?.current_location_id
	)

	return (
		<ConditionalRender
			condition={!isLoading}
			failedRender={
				<SkeletonText isLoading={true} width={200} height={20} />
			}
		>
			<ProductStockStatus
				target={target}
				status={stockStatus}
				shorthandLabel={renderShorthandLabel}
			/>
		</ConditionalRender>
	)
}

export default FetchProductStockStatus
